'use strict';

/* Controllers */
//

clientControllers.controller('StudentDashboardCtrl', ['$scope', '$rootScope', '$routeParams', '$interval', '$window', 'ActiveExperience',
  'ExperienceGroupListFactory', 'ExperienceClassFactory', 'ActivityStateListFactory',
  'ExperienceStudentService', '$location', 'User', '$log', '$q',
  'ElementRealtimeService', 'ElementsRestService',
  'JSONStringUtility', 'PresenceService', 'ModalService',
  'ExperienceUpdateFactory', 'CourseFactory', 'userPermissions', 'PermissionConsts',
  'ActiveExperienceService', 'ReadingGroupService', 'ReadingGroupDefaultsService', 'VideoStreamingService',
  'StudentStreamingService', 'TeacherStreamingService',
  function ($scope, $rootScope, $routeParams, $interval, $window, ActiveExperience,
            ExperienceGroupListFactory, ExperienceClassFactory, ActivityStateListFactory,
            ExperienceStudentService, $location, User, $log, $q,
            ElementRealtimeService, ElementsRestService,
            JSONStringUtility, PresenceService, ModalService,
            ExperienceUpdateFactory, CourseFactory, userPermissions, PermissionConsts,
            ActiveExperienceService, ReadingGroupService, ReadingGroupDefaultsService, VideoStreamingService,
            StudentStreamingService, TeacherStreamingService) {

    $scope.activeDashboard = "students";

    // Extract the experience Id from the URL
    var experienceId = parseInt($routeParams.id, 10);
    $scope.xpError = {};
    $scope.studentsNotInClass = null;
    $scope.experience = null;
    $scope.students = [];
    $scope.groupSharing = false;
    $scope.studentGroups = [];
    $scope.resource_dashboard_suffix = '-blue';
    $scope.active_page = "dashboard";
    $scope.hasReadingGroups = false;
    $scope.readingGroups = [];

    $scope.packtype = $scope.userIsTeacher() ? "teacher" : "student";

    $scope.validationFailed = function () {
      // If error message holder has data
      if ($scope.xpError.details)
        return $scope.xpError.details.length > 0 ? true : false;
      return false;
    };

    $scope.isPastExperience = function () {
      return $scope.experience && $scope.experience.status == "INACTIVE";
    };

    $scope.$watch(function() {
      return ActiveExperience.currentExperience();
    },
    function(value) {
      if (value) {
        $scope.getExperience();
      }
    });

    // Function that loads the experience
    $scope.getExperience = function () {
      ActiveExperience.getExperience(experienceId).then( function (experience) {
        $scope.experience = experience;

        // Set the Experience Preview mode
        $scope.previewEnabled = experience.preview;

        // if there are any small groups in this experience then it is using group sharing
        $scope.groupSharing = experience.small_groups && experience.small_groups > 0;

        if (!experience.preview &&  experience.status === 'ACTIVE') {
          // Initialize streaming to current experience
          if ($scope.userIsStudent()) {
            StudentStreamingService.initStudentStreaming($scope.experience.id);
          } else {
            TeacherStreamingService.initTeacherStreaming($scope.experience.id);
          }

          VideoStreamingService.setCurrentScene($routeParams.sceneid);
          $scope.shareVideo = TeacherStreamingService.isTeacherStreamingVideo();
        }

        // Group sharing so get the groups for this experience
        if ($scope.groupSharing) {
          // reset the group information
          $scope.studentGroups = [];
          var smallGroupCount = experience.small_groups;

          // Create enough empty entries in the student group list.  The default uses # / 4.
          for (var count = 0; count < smallGroupCount; ++count)
            $scope.studentGroups.push([]);

          // Loop through all the students a put them into their correct groups
          experience.students.forEach(function (student) {
            if (student.small_group && student.small_group > 0)
              $scope.studentGroups[student.small_group - 1].push(student.id);
          });

          // The default small group for any student being added to this class is always just the first group
          $scope.defaultSmallGroup = 1;
        }

        // If there are reading groups in this experience
        $scope.hasReadingGroups = experience.reading_group_id && experience.reading_group_id > 0;

        // Initialize the reading groups based on data from master
        if ($scope.hasReadingGroups) {
          ReadingGroupService.get({}, {group_id: experience.reading_group_id}).$promise.then(function (rg) {
            $scope.readingGroupDef = rg;
            // Add a placeholder for each of the levels in this group.  These will be arrays of student ID's
            $scope.readingGroups = [];
            $scope.readingGroupDef.levels.forEach(function (level) {
              $scope.readingGroups.push({reading_level: level.reading_level, students: []});
              // if this is the default level then save it
              if (level.default_level && level.default_level === true) {
                $scope.defaultReadingLevel = level.reading_level;
              }
            })

            // Loop through all the students a put them into their correct groups
            experience.students.forEach(function (student) {
              if (student.reading_level && student.reading_level > 0) {
                var studentReadingGroup = $scope.readingGroups.find(function (readingGroup) {
                  return readingGroup.reading_level === student.reading_level;
                });
                if (studentReadingGroup) {
                  studentReadingGroup.students.push(student.id);
                }
              }
            });

            // Get the default reading groups for all class students and keep locally
            ReadingGroupDefaultsService.get({}, {
              class_id: experience.class_id,
              group_id: experience.reading_group_id
            }).$promise
              .then(function (defaultReadingGroups) {
                $scope.defaultStudentReadingGroups = defaultReadingGroups;
              });
          });
        }

      })
      .catch(function (err) {
        $log.error("error in get students:", err);
      });
    };

    // Load the experience on startup
    $scope.getExperience();

    // ============================
    // update the students
    // ============================
    $scope.updateStudents = function () {
      // Get the list of students in the entire class
      ActivityStateListFactory.get({}, {id: experienceId}, function (classActivity) {
        var studentList = [];
        var student = null;

        // Put all the correct students from the experience into the local list of students
        for (var allIndex = 0; allIndex < $scope.experience.students.length; ++allIndex) {
          // Grab this student from the list of students in the experience
          student = $scope.experience.students[allIndex];

          // if this is a student user then only copy it into the list
          if ($scope.userIsStudent() && User.getId() == student.user_id)
            studentList.push(student);
          else if (!$scope.userIsStudent() && User.getId() != student.user_id)
            studentList.push(student);

          // Get the activity for this student
          for (var activityIndex = 0; activityIndex < classActivity.length; ++activityIndex) {
            // if this is the current student then assign activity
            if (classActivity[activityIndex].user_id === student.user_id) {
              // Assign the progress
              student.progress = classActivity[activityIndex].assignment_progress;
              student.time_on_screen = classActivity[activityIndex].time_on_screen;
            }
          }
        }

        // Sort by group if using small groups, otherwise sort by name
        if ($scope.groupSharing)
          $scope.students = ActiveExperience.sortStudents(studentList, ActiveExperience.SORTBY.Group);
        else
          $scope.students = ActiveExperience.sortStudents(studentList, ActiveExperience.SORTBY.Name);

        // Fill the dropdown with students that are not already in this experience
        $scope.populateStudentDropDown();
      });
    };

    $scope.$watch('experience', function () {
      if ($scope.experience !== null)
        $scope.updateStudents();
    });

    $scope.changeDashboard = function (page) {
      ActiveExperienceService.setDashboard(page);
      $location.path('/experience/' + $scope.experience.id + '/dashboard/' + page);
    };

    // Handle element update notifications
    ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
      activityChangedNotificationHandler);

    $scope.$on('$destroy', function () {
      ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
        activityChangedNotificationHandler);
    });

    function activityChangedNotificationHandler(e) {
      var state = e.detail;
      $log.debug("Received activity update: " + JSON.stringify(state));
      // Just need to update the progress for the specified student
      if (state.record && state.record.progress) {
        var foundStudent = $scope.students.find(function(student){
          return student.user_id === state.record.user_id;
        });
        if (foundStudent) {
          foundStudent.progress = state.record.assignment_progress;
        }
      }
    }

    $scope.status = {
      isopen: false
    };

    $scope.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.status.isopen = !$scope.status.isopen;
    };

    function studentInGroupWithPosts(student) {
      var studentsWithPosts = $scope.students.filter(function(grpStudent){
        return student.small_group && (student.small_group === grpStudent.small_group) && grpStudent.progress;
      });

      // if found in the array then return true
      return studentsWithPosts.length > 0;
    }

    function studentHasProgress(student, progressMsg) {
      if ((student.progress && student.progress > 0) || (student.time_on_screen && student.time_on_screen > 0)) {
        // Make sure this student has no data
        ModalService.show({
          message: progressMsg,
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        });

        return true;
      }
      else if (studentInGroupWithPosts(student)) {
        // Make sure this student has no data
        ModalService.show({
          message: 'Students cannot be moved to or from a small group once the small group has submitted a group post.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        });

        return true;
      }
      else
        return false;
    }

    function groupHasProgress(small_gid) {
      var studentsWithProgress = $scope.students.filter(function(student){
        return student.small_group === small_gid && student.progress;
      });

      // if found in the array then return true
      if (studentsWithProgress.length > 0) {
        // Make sure this student has no data
        ModalService.show({
          message: 'Students cannot be moved to a small group once the small group has submitted a group post.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        });
        return true;
      }
      else {
        return false;
      }
    }

    function moveStudentInExperience(student, small_gid) {
      // Need to create an array and put the single student e-mail address into the array
      var studentList = [];
      studentList.push({
        'username': student.username,
        'small_gid': small_gid,
        'user_id': student.user_id
      });

      // convert array to string
      var studentListString = JSON.stringify(studentList);

      // Need to insert the students array as a string representation of a JSON object
      var students = {'students': studentListString};

      ExperienceStudentService.Move({
        'id': experienceId,
        'students': studentListString
      }, function (data) { //Success callback
        // Handle error object
        if (typeof data.error == 'object') {
          $scope.xpError.details = data.error.description;
        }
      }, function (error) { //Failure callback
        $scope.xpError.details = "Failed to move student in Experience";
        $log.debug("Failed to move student in experience: " + error);
      });
    }

    function removeStudentFromExperience(student) {
      // Need to create an array and put the single student e-mail address into the array
      var studentList = [];
      studentList.push({
        'username': student.username,
        'user_id': student.user_id
      });

      // convert array to string
      var studentListString = JSON.stringify(studentList);

      // Need to insert the students array as a string representation of a JSON object
      var students = {'students': studentListString};

      ExperienceStudentService.Remove({
        'id': experienceId,
        'students': studentListString
      }, function (data) { //Success callback
        // Handle error object
        if (typeof data.error == 'object') {
          $scope.xpError.details = data.error.description;
        }
      }, function (error) { //Failure callback
        $scope.xpError.details = "Failed to Remove student from Experience";
        $log.debug("Failed to remove student from experience: " + error);
      });
    }

    // ============================
    // Delete the selected student
    // ============================
    $scope.onDeleteStudent = function (student) {
      // See if the student has made any "progress" on this experience.  If so, then they cannot be removed
      if (!studentHasProgress(student, 'This student has already started the experience and cannot be removed.')) {
        // Remove this student from the experience
        removeStudentFromExperience(student);
      }

      // return false so href doesn't do anything
      return false;
    };

    function addStudentToExperience(newStudent, small_gid, reading_level) {
      // Need to create an array and put the single student e-mail address into the array with optional group information
      var student = {
        'username': newStudent.username,
        'user_id': newStudent.id
      };

      if (small_gid) {
        // Make sure there is no progress in this group
        // if (groupHasProgress(small_gid)) {
        //   return;
        // }
        student.small_gid = small_gid;
      }
      if (reading_level) {
        student.reading_level = reading_level;
      }
      var studentList = [student];

      // convert array to string
      var studentListString = JSON.stringify(studentList);

      // Need to insert the students array as a string representation of a JSON object
      var students = {'students': studentListString};

      // Remove this student from the list
      ExperienceStudentService.Add({
        'id': experienceId,
        'students': studentListString
      }, function (data) { //Success callback
        // Handle and error object
        if (typeof data.error == 'object') {
          // Store Error: 'Could not add student to experience : ' + data.error.type + '-' + data.error.description);
          $scope.xpError.details = data.error.description;
        }
      }, function (error) { //Failure callback
        $scope.xpError.details = "Failed to add student to Experience";
        $log.debug("Failed to add student to experience: " + error);
      });

    }

    // ============================
    // Add the selected student
    // ============================
    $scope.onAddStudent = function (student) {
      // Add this student to the experience
      addStudentToExperience(student);
    };

    // ============================
    // Get students list
    // ============================
    $scope.populateStudentDropDown = function () {
      if ($scope.experience.class_id) {
        CourseFactory.get($scope.experience.class_id)
          .then(function (course) {
            CourseFactory.getStudents(course.cid)
              .then(function (students) {
                if ($scope.students)
                  $scope.studentsNotInClass = arr_diff(students, $scope.students);
                // Set up list and assign to student list in the returned response
              })
              .catch(function (error) {
                $log.error(error);
              });
          });
      }
      else {
        ExperienceGroupListFactory.get({
          'id': User.getId(),
          'gid': null
        }, function (data) { //Success callback
          // Handle and error object
          if (typeof data.error == 'object') {
            // Store Error: 'StudentFactory Data Failed : ' + data.error.type + '-' + data.error.description);
            $scope.xpError.details = data.error.description;
          }
          else {
            if ($scope.students)
              $scope.studentsNotInClass = arr_diff(data.students, $scope.students);
            // Set up list and assign to student list in the returned response
          }
        }, function (error) { //Failure callback
          $scope.xpError.details = "Failed to get list of students in group";
          $log.debug("Failed to get list of students in group: " + error);
        });
      }
    };

//============================================================================================
// Function to remove already assigned students
// ============================================================================================
    function arr_diff(a, student) {
      var seen = [], diff = [];
      for (var i = 0; i < student.length; i++)
        seen[student[i].username] = true;
      for (i = 0; i < a.length; i++)
        if (!seen[a[i].username])
          diff.push(a[i]);
      return diff;
    }

    function isStudentInSmallGroup(studentId, groupIndex) {
      // Grab the current values
      var small_gid = $scope.studentGroups[groupIndex];

      // Check this group to see if the student exists in it
      return small_gid.indexOf(studentId) > -1;
    }

    function isAnyStudentInSmallGroup(groupIndex) {
      // Grab the current values
      var small_gid = $scope.studentGroups[groupIndex];

      // If there are any items in this group then it has students
      return small_gid.length > 0;
    }

    $scope.studentInSmallGroup = function (studentId, groupIndex) {
      return isStudentInSmallGroup(studentId, groupIndex);
    };

    $scope.getHiliteClass = function (studentId, groupIndex) {
      if (isStudentInSmallGroup(studentId, groupIndex))
        return "small-group-indicator-" + groupIndex;

      return "";
    };

    function isStudentInReadingGroup(studentId, reading_level) {
      // Grab the current values
      var group = $scope.readingGroups.find(function (readingGroup) {
        return readingGroup.reading_level === reading_level;
      });

      // Check this group to see if the student exists in it
      return group && group.students.indexOf(studentId) > -1;
    }

    $scope.studentInReadingGroup = function (studentId, reading_level) {
      return isStudentInReadingGroup(studentId, reading_level);
    };

    $scope.readingGroupIndex = function (index) {
      return 'ABCDEFGHIJ'[index];
    }

    $scope.getReadingGroupHiliteClass = function (studentId, reading_level) {
      if (isStudentInReadingGroup(studentId, reading_level))
        return "small-group-indicator-" + (reading_level - 1);

      return "";
    };

    function setSmallGroupCount(smallGroupCount, adding) {
      // Update the experience setting the small groups to the new value
      ExperienceUpdateFactory.update(
        {
          'id': experienceId,
          'name': $scope.experience.name,
          'section_name': $scope.experience.section_name,
          'starts_at': $scope.experience.starts_at,
          'ends_at': $scope.experience.ends_at,
          'description': $scope.experience.description,
          'notification': $scope.experience.notification,
          'small_groups': smallGroupCount
        },
        function (result) // Request #1
        {
          // Add or Remove the small group from the UI
          if (adding)
            $scope.studentGroups.push([]);
          else
            $scope.studentGroups.splice($scope.studentGroups.length - 1, 1);
        },
        function (error) { //Failure callback
          $log.debug("Failed to save the experience: " + error);
          $scope.xpError.details = "Failed to save the experience";
        });
    }

    $scope.onAddSmallGroup = function () {
      // If there are less than 10 groups then add another one
      if ($scope.studentGroups.length < 10) {
        // Tell the server to remove this group
        setSmallGroupCount($scope.studentGroups.length + 1, true);
      }
    };

    $scope.onRemoveSmallGroup = function () {
      // See if there are any students in this group.  Cannot delete the group if there are students in it
      if (isAnyStudentInSmallGroup($scope.studentGroups.length - 1)) {
        // Tell the user they cannot delete this group
        ModalService.show({
          message: 'All students in group ' + $scope.studentGroups.length + ' must be moved before it can be deleted.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        });
      }
      else {
        // If there are less than 10 groups then add another one
        if ($scope.studentGroups.length > 2) {
          // Tell the server to remove this group
          setSmallGroupCount($scope.studentGroups.length - 1, false);
        }
      }
    };

    $scope.setSmallGroup = function (student, index) {
      // Not allowed to change in past experience
      if (!$scope.isPastExperience()) {
        // If this student has made any progress in the current group then warn the teacher
        if (!studentHasProgress(student, 'This student has already started the experience and cannot be moved to a different group.')) {
          // Make sure the group moving too does not have progress
          if (!groupHasProgress(index + 1)) {
            // Move this user into a different small group in this experience
            moveStudentInExperience(student, index + 1);
          }
        }
      }
    };

    $scope.setReadingGroup = function (student, reading_level) {
      // Not allowed to change in past experience
      if (!$scope.isPastExperience()) {
        // Need to create an array and put the single student e-mail address into the array
        var studentList = [];
        studentList.push({
          'username': student.username,
          'reading_level': reading_level,
          'user_id': student.user_id
        });
        // convert array to string
        var studentListString = JSON.stringify(studentList);

        // Need to insert the students array as a string representation of a JSON object
        var students = {'students': studentListString};

        ExperienceStudentService.Move({
          'id': experienceId,
          'students': studentListString
        }, function (data) { //Success callback
          // Handle error object
          if (typeof data.error == 'object') {
            $scope.xpError.details = data.error.description;
          }
        }, function (error) { //Failure callback
          $scope.xpError.details = "Failed to move student in Experience";
          $log.debug("Failed to move student in experience: " + error);
        });
      }
    };

    $scope.onSaveStudentGroup = function (data) {
      // Add this student to the experience
      if (data) {
        addStudentToExperience(data.student, data.small_gid, data.reading_level);
      }
    };

  }])
;


