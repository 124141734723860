'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$q', 'ActiveMode', 'spireReportingService', 'reportToCSV', 'spireExportService', 'rosterService'];

function controller ($q, ActiveMode, spireReportingService, reportToCSV, spireExportService, rosterService) {
  var ctrl = this;

  var viewValues = {
      placement: 'placement',
      cmfd: 'cmfd',
      ca: 'ca',
      mid: 'mid',
      prepost: 'prepost'
    };

  ctrl.views = [
               {label: 'Placement Test', value: viewValues.placement},
               {label: 'Concept Mastery Fluency Drill', value: viewValues.cmfd},
               {label: 'Concept Assessment', value: viewValues.ca},
               {label: 'Pre/Post-Test', value: viewValues.prepost},
               {label: 'Mid-Level Test', value: viewValues.mid}
             ];

  ctrl.inited = false;
  ctrl.viewValues = viewValues;

  ctrl.hasDistrict = false;
  ctrl.hasStatus = false;

  ctrl.data = {};
  ctrl.data.view = ctrl.views[0];
  var viewState = {};
  ctrl.exportLevels = [];
  ctrl.exportClasses = null;
  ctrl.showlevels = false;
  ctrl.exportStatuses = null;
  ctrl.currentStatuses = null;

  // If this is either a subscription or district admin then assume this report is governing multiple schools
  var isMultipleSchools = ActiveMode.isSubscriptionMode();

  ActiveMode.getReportData({}).then(function(data) {
    parseReportData(data);
    ctrl.inited = true;
  });

  function updateReportData(statuses) {
    // If this report is being filtered by status then the data needs to be re-queried
    if (statuses && statuses.length > 0) {
      if (ctrl.currentStatuses && ctrl.currentStatuses.length == statuses.length && statuses.every(function(value, index) {return value.id === ctrl.currentStatuses[index].id; })) {
        return $q.when();
      }
      ctrl.currentStatuses = statuses;
      var statusIds = statuses.map(function(status) { return status.id; });
      var statusReq = {};
      ctrl.exportStatuses = null;
      if (statusIds[0] != "All") {
        statusReq.statuses = statusIds;
        ctrl.exportStatuses = statusIds;
      }
      return ActiveMode.getReportData(statusReq).then(function(data) {
        parseReportData(data);
        return;
      });
    } else {
      return $q.when();
    }
  }

  ctrl.onRefresh = function(view, schools, teachers, levels, statuses) {
    // Just set the current view in the controller
    if (view) {
      ctrl.data.view = view;
      if (view.value !== ctrl.views[0].value) {
        ctrl.showlevels = true;
      } else {
        ctrl.showlevels = false;
      }
    }

    updateReportData(statuses).then(function() {
      // Filter data by specified level
      filterByLevel(levels);

      // Filter data by school
      if (isMultipleSchools) {
        filterBySchool(schools);
      }

      // Filter data by the teachers
      if (!isMultipleSchools) {
        filterByTeacher(teachers);
      }
    });
  };

  ctrl.downloadCSV = function downloadCSV() {
    return ActiveMode.getReportData({export: true, statuses: ctrl.exportStatuses}).then(function(data) {
      ctrl.exportColumns = spireExportService.parseExportColumns(data, ctrl.exportLevels, isMultipleSchools, true, ctrl.data.view.value, viewValues, ctrl.hasDistrict, ctrl.hasStatus);
      ctrl.exportData = spireExportService.parseExportData(data, ctrl.exportLevels, ctrl.exportClasses, ctrl.data.view.value, viewValues, null, ctrl.hasDistrict, ctrl.hasStatus);
      return ctrl.exportCSV();
    });
  };

  ctrl.exportCSV = reportToCSV(
      function () {
        return ctrl.exportColumns;
      },
      function () {
        return ctrl.exportData;
      }, function () {
        return spireExportService.getTitle(ctrl.parent.title, ctrl.models[viewValues.ca].getLevels(), ctrl.data.view.label) + '.csv';
      },
      ','
    );

  function filterByLevel(levels) {
    // Grab the correct set of levels based on the selection
    var selLevels = ctrl.levels;
    if (levels && levels.length && levels[0].id != "All") {
      selLevels = [];
      levels.forEach(function(level) {
        selLevels.push(level.id);
      });
    }

    ctrl.exportLevels = selLevels;

    // Assign the new levels to the models
    if (ctrl.models) {
      ctrl.models[viewValues.ca].setLevels(selLevels);
      ctrl.models[viewValues.cmfd].setLevels(selLevels);
      ctrl.models[viewValues.prepost].setLevels(selLevels);
      ctrl.models[viewValues.mid].setLevels(selLevels);
    }
  }

  function filterBySchool(schools) {
    // Grab the correct schools based on the filter criteria
    var selClasses = ctrl.classes;
    ctrl.exportClasses = null;
    if (schools && schools.length && schools[0].id != "All" && ctrl.classes) {
      selClasses = [];
      schools.forEach(function(school) {
        ctrl.classes.forEach(function(clss) {
          if (school.id == clss.school_id)
            selClasses.push(clss);
        });
      });
      ctrl.exportClasses = selClasses.map(function(cl) { return cl.cid; });
    }

    // Assign the new classes to the models
    if (ctrl.models) {
      ctrl.models[viewValues.placement].setClasses(selClasses);
      ctrl.models[viewValues.ca].setClasses(selClasses);
      ctrl.models[viewValues.cmfd].setClasses(selClasses);
      ctrl.models[viewValues.prepost].setClasses(selClasses);
      ctrl.models[viewValues.mid].setClasses(selClasses);
    }
  }

  function filterByTeacher(teachers) {
    // Grab the correct classes based on the filter criteria
    var selClasses = ctrl.classes;
    ctrl.exportClasses = null;
    if (teachers && teachers.length && teachers[0].teacher_user_id != "All") {
      selClasses = [];
      teachers.forEach(function(teacher) {
        ctrl.classes.forEach(function(clss) {
          if (teacher.teacher_user_id == clss.teacher_user_id)
            selClasses.push(clss);
        });
      });
      ctrl.exportClasses = selClasses.map(function(cl) { return cl.cid; });
    }

    // Assign the new classes to the models
    if (ctrl.models) {
      ctrl.models[viewValues.placement].setClasses(selClasses);
      ctrl.models[viewValues.ca].setClasses(selClasses);
      ctrl.models[viewValues.cmfd].setClasses(selClasses);
      ctrl.models[viewValues.prepost].setClasses(selClasses);
      ctrl.models[viewValues.mid].setClasses(selClasses);
    }
  }

  function parseReportData(data) {
    var schools = spireReportingService.parseSchools(data.classes);
    var classes = spireReportingService.parseClasses(data.classes);
    var teachers = spireReportingService.parseTeachers(data.classes);
    var lessons = spireReportingService.parseToLevels(data.lessons, 'lesson');
    var concepts = spireReportingService.parseToLevels(data.concepts, 'concept');
    var levels = spireReportingService.getLevels(lessons);

    data.students.forEach(function(student) {
      ctrl.hasDistrict = ctrl.hasDistrict || student.lms_source;
      ctrl.hasStatus = ctrl.hasStatus || student.learning_status_id;
    });

    // Save the list of classes
    ctrl.classes = classes;

    // if this is a class level report then set flag indicating one school.  TODO: I'd like to have the server return the fact that this is a school level report
    if (data.report_kind == 'spire-school-level-progress') {
      isMultipleSchools = false;
    }

    ctrl.models = {};
    ctrl.models[viewValues.placement] = new CAClassPlacementTableModel(classes, data.students, data.levels, data.placements, isMultipleSchools);
    ctrl.models[viewValues.ca] = new CAClassDataTableModel(classes, lessons, levels, data.ca, isMultipleSchools);
    ctrl.models[viewValues.cmfd] = new CMFDClassDataTableModel(classes, concepts, levels, data.cmfd, isMultipleSchools);
    ctrl.models[viewValues.prepost] = new PrePostClassTableModel(classes, levels, data.pre, isMultipleSchools);
    ctrl.models[viewValues.mid] = new MidClassTableModel(classes, levels, lessons, data.mid, isMultipleSchools);

    // Initialize local levels reference.  Set default level to last (latest)
    ctrl.levels = levels;
    ctrl.exportLevels = levels;
    if (levels.length > 0) {
      ctrl.data.level = ctrl.levels[ctrl.levels.length - 1];
    }

    ctrl.exportClasses = null;

    // Create a list for filter display
    ctrl.filterLevels = [];
    levels.forEach(function(level) {
      var name = "Level " + level;
      ctrl.filterLevels.push({id: level, name: name });
    });

    // Initialize the list of schools for the filter
    if (isMultipleSchools) {
      ctrl.schools = schools;
    }

    // Initialize the list of teachers for the filter
    if (!isMultipleSchools) {
      ctrl.teachers = teachers;
    }

    rosterService.getLearningStatus(-6).then(function(statuses) {
      ctrl.statuses = statuses.map(function(status) {
        return {
          id: status.id,
          name: status.learning_status_name
        }
      });
    });
  }

  ctrl.reportHasData = function() {
    return ctrl.levels && ctrl.data.level && ctrl.levels.length > 0;
  };

  function CAClassPlacementTableModel(classes, students, levels, placements, isMultipleSchools) {
    function parsePlacementScores(placements) {
      var scores = {};
      placements.forEach(function(placement) {
        scores[placement.class_id] = scores[placement.class_id] || {};
        scores[placement.class_id][placement.student_id] = scores[placement.class_id][placement.student_id] || {};
        if (scores[placement.class_id][placement.student_id][placement.placement_id]) {
          scores[placement.class_id][placement.student_id][placement.placement_id].push(placement);
        } else {
          scores[placement.class_id][placement.student_id][placement.placement_id] = [placement];
        }
      });
      return scores;
    }

    function parseClassStudents(classes, students) {
      var clStudents = [];
      classes.forEach(function(cls) {
        students.forEach(function(student){
          if (cls.cid === student.cid) {
            clStudents.push({
              cid: cls.cid,
              className: cls.class_name,
              teacher: cls.first_name + " " + cls.last_name,
              id: student.id,
              student: student.first_name + " " + student.last_name
            });
          }
        });
      });
      return clStudents;
    }

    var scores = parsePlacementScores(placements);
    var classStudents = parseClassStudents(classes, students);

    this.getStudents = function() {
      return classStudents;
    };

    this.getLevels = function() {
      return levels;
    };

    this.hasMultipleScores = function(classId, studentId, levelId) {
      return scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId] &&
              scores[classId][studentId][levelId].length > 1;
    };

    this.getExperienceDate = function(classId, studentId, levelId, index) {
      if (scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId] &&
          scores[classId][studentId][levelId].length && index < scores[classId][studentId][levelId].length) {
        return window.moment(scores[classId][studentId][levelId][index].updated_at).format('M/DD/YY');
      }
    };

    this.getLastExperienceDate = function(classId, studentId, levelId) {
      if (scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId] &&
          scores[classId][studentId][levelId].length) {
        return window.moment(scores[classId][studentId][levelId][scores[classId][studentId][levelId].length - 1].updated_at).format('M/DD/YY');
      }
    };

    this.getErrorCount = function(classId, studentId, levelId, index) {
      if (scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId] && scores[classId][studentId][levelId].length &&
          index < scores[classId][studentId][levelId].length) {
        var errors = "";
        var errorCount = scores[classId][studentId][levelId][index].incorrect_word_count;
        if (errorCount >= 0) {
          errors =  "(" + errorCount;
          if (errorCount === 0 || errorCount > 1) {
            errors = errors + " errors)";
          } else {
            errors = errors + " error)";
          }
        }
        return errors;
      }
    };

    this.getExperienceId = function(classId, studentId, levelId, index) {
      if (scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId] && scores[classId][studentId][levelId].length &&
          index < scores[classId][studentId][levelId].length) {
        return scores[classId][studentId][levelId][index].experience_id;
      }
    };

    function getScores(classId, studentId, levelId) {
      if (scores[classId] && scores[classId][studentId] && scores[classId][studentId][levelId]) {
        return scores[classId][studentId][levelId];
      } else {
        return null;
      }
    }

    this.getCellDropdown = function(classId, studentId, levelId) {
      var scores = getScores(classId, studentId, levelId);
      var retval = {
        template: 'spireClassPlacementPopup.tpl',
        scores: scores
      };
      return retval;
    };

    this.setClasses = function(newClasses) {
      classStudents = parseClassStudents(newClasses, students);
    };
  }

  /************************************************************
   * This class handles Concept Assessment display data
  *************************************************************/
  function CAClassDataTableModel(classes, lessons, levels, scores, isMultipleSchools) {

    function parseConceptAssessmentScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      if (scores) {
        scores.forEach(function(score){
          score.score = Math.round(score.score);
          var classId = score.cid;
          var level = score.level;
          var lessonId = score.lesson;

          var levelScores = spireReportingService.getOrCreateHive(retval, level);
          var lessonScores = spireReportingService.getOrCreateHive(levelScores, lessonId);
          var classScores = spireReportingService.getOrCreateHive(lessonScores, classId);
          if (classScores.displayScore === null  || angular.isUndefined(classScores.displayScore)) {
            classScores.displayScore = score.score;
            classScores.scores = [];
          }

          if (classScores.displayScore < score.score) {
            classScores.displayScore = score.score;
          }

          classScores.scores.push(score);
        });
      }

      retval.overall = spireReportingService.calculateOverallScores(retval);
      return retval;
    }

    var data = parseConceptAssessmentScores(scores);

    this.isMultipleSchools = function() {
      return isMultipleSchools;
    }

    this.getClasses = function() {
      return classes;
    };

    this.setClasses = function(newClasses) {
      classes = newClasses;
    };

    this.getLevels = function() {
      return levels;
    };

    this.setLevels = function(newLevels) {
      levels = newLevels;
    };

    this.getColumns = function(level) {
      return lessons[level];
    };

    this.getOverallCellValue = function(level, lesson) {
      if (data.overall && data.overall[level])
        return data.overall[level][lesson.lesson];

      return undefined;
    };

    this.getCellValue = function(level, clss, lesson) {
      return getDisplayScore(level, clss, lesson);
    };

    this.formatScore = function (score) {
      if (!angular.isUndefined(score)){
        return score + '%';
      }

      return score;
    };

    this.getScoreClass = function(score) {
      if (angular.isUndefined(score) || score === null) {
        return 'undefined-score';
      }

      if (score >= 80) {
        return 'accomplished-score';
      }

      return 'needs-attention-score';
    };

    this.getCellDropdown = function(level, clss, lesson) {
      var scores = getScores(level, clss, lesson);
      var retval = {
        template: 'spireClassLevelCAPopup.tpl',
        scores: scores,
        lesson: lesson
      };
      return retval;
    };

    this.getLegend = function() {
      return 'spireClassLevelCALegend.tpl';
    };

    function getScore(level, clss, lesson) {
      if (data &&
        data[level] &&
        data[level][lesson.lesson] &&
        data[level][lesson.lesson][clss.cid] !== null &&
        !angular.isUndefined(data[level][lesson.lesson][clss.cid])) {
          return data[level][lesson.lesson][clss.cid];
        }
      return undefined;
    }

    function getDisplayScore(level, clss, lesson) {
      var score = getScore(level, clss, lesson);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.displayScore;
    }

    function getScores(level, clss, lesson) {
      var score = getScore(level, clss, lesson);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.scores;
    }
  }

  /************************************************************
   * This class handles Concept Mastery Fluency Drill display data
  *************************************************************/
  function CMFDClassDataTableModel(classes, concepts, levels, scores, isMultipleSchools) {

    function parseCMFDScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      if (scores) {
        scores.forEach(function(score){
          score.score = Math.round(score.score);
          score.last_word = Math.round(score.last_word);
          var classId = score.cid;
          var level = score.level;
          var conceptId = score.concept;

          var levelScores = spireReportingService.getOrCreateHive(retval, level);
          var conceptScores = spireReportingService.getOrCreateHive(levelScores, conceptId);
          var classScores = spireReportingService.getOrCreateHive(conceptScores, classId);

          if (classScores.scores === null  || angular.isUndefined(classScores.scores)) {
            classScores.scores = [];
          }

          classScores.scores.push(score);
          classScores.displayScore = score.score; // Display last score, it will be most recent because we sorted by date above
        });
      }

      retval.overall = spireReportingService.calculateOverallScores(retval);
      return retval;
    }

    var data = parseCMFDScores(scores);

    this.isMultipleSchools = function() {
      return isMultipleSchools;
    }

    this.getClasses = function() {
      return classes;
    };

    this.setClasses = function(newClasses) {
      classes = newClasses;
    };

    this.getLevels = function() {
      return levels;
    };

    this.setLevels = function(newLevels) {
      levels = newLevels;
    };

    this.getColumns = function(level) {
      return concepts[level];
    };

    this.getOverallCellValue = function(level, concept) {
      if (data.overall && data.overall[level])
        return data.overall[level][concept.concept];

      return undefined;
    };

    this.getCellValue = function(level, clss, concept) {
      return getDisplayScore(level, clss, concept);
    };

    this.formatScore = function (score) {
      if (!angular.isUndefined(score)) {
        return score + '&nbsp;';
      }

      return score;
    };

    this.getScoreClass = function(score) {
      if (angular.isUndefined(score) || score === null) {
        return 'undefined-score';
      }

      return 'accomplished-score';
    };

    this.getCellDropdown = function(level, clss, concept) {
      var scores = getScores(level, clss, concept);
      var retval = {
        template: 'spireClassLevelCMFDPopup.tpl',
        scores: scores
      };
      return retval;
    };

    this.getLegend = function() {
      return 'spireClassLevelCMFDLegend.tpl';
    };

    function getScore(level, clss, concept) {
      if (data &&
        data[level] &&
        data[level][concept.concept] &&
        data[level][concept.concept][clss.cid] !== null &&
        !angular.isUndefined(data[level][concept.concept][clss.cid])) {
        return data[level][concept.concept][clss.cid];
      }

      return undefined;
    }

    function getDisplayScore(level, clss, concept) {
      var score = getScore(level, clss, concept);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.displayScore;
    }

    function getScores(level, clss, concept) {
      var score = getScore(level, clss, concept);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.scores;
    }
  }

  function PrePostClassTableModel(classes, levels, scores, isMultipleSchools)
  {
    var columns = ['decodable_words_score', 'decodable_sentences_score', 'passage_correct', 'comprehension_score'];

    function parseScores(scores)
    {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      if (scores) {
        scores.forEach(function(score){
          score.decodable_words_score = Math.round(score.decodable_words_score);
          score.decodable_sentences_score = Math.round(score.decodable_sentences_score);
          score.passage_correct = Math.round(score.passage_correct);
          score.comprehension_score = Math.round(score.comprehension_score);
          score.prepost = score.prepost || 'pre';

          var classId = score.cid;
          var level = score.level;
          var prepost = score.prepost;

          var levelScores = spireReportingService.getOrCreateHive(retval, level);
          var prepostScores = spireReportingService.getOrCreateHive(levelScores, prepost);
          var classScores = spireReportingService.getOrCreateHive(prepostScores, classId);

          if (classScores.scores === null  || angular.isUndefined(classScores.scores))
          {
            classScores.scores = [];
          }

          classScores.scores.push(score);
          classScores.displayScore = score; // Display last score, it will be most recent because we sorted by date above
        });
      }

      return retval;
    }

    var data = parseScores(scores);

    this.isMultipleSchools = function() {
      return isMultipleSchools;
    }

    this.getClasses = function() {
      return classes;
    };

    this.setClasses = function(newClasses) {
      classes = newClasses;
    };

    this.getLevels = function() {
      return levels;
    };

    this.setLevels = function(newLevels) {
      levels = newLevels;
    };

    this.getValue = function(level, clss, prepost, column) {
      try
      {
        if (data &&
          data[level] &&
          data[level][prepost] &&
          data[level][prepost][clss.cid] &&
          data[level][prepost][clss.cid].displayScore &&
          data[level][prepost][clss.cid].displayScore[column] !== null &&
          !angular.isUndefined(data[level][prepost][clss.cid].displayScore[column]))
        {
          return data[level][prepost][clss.cid].displayScore[column];
        }
      }
      catch(e)
      {
        // ignore exception.
      }
      return undefined;
    };

    this.getOverallValue = function(level, prepost, column) {
      try
      {
        if (data.overall &&
          data.overall[level] &&
          data.overall[level][prepost] &&
          data.overall[level][prepost].score &&
          data.overall[level][prepost].score[column])
        {
          return data.overall[level][prepost].score[column];
        }
      }
      catch(e)
      {
        // ignore exception.
      }
      return undefined;
    };
  }

  function MidClassTableModel(classes, levels, lessons, scores, isMultipleSchools)
  {
    function parseScores(scores)
    {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      var experiences = {};
      if (scores) {
        scores.forEach(function(score){
          score.score = Math.round(score.score);
          var classId = score.cid;
          var level = score.level;
          var lessonId = score.lesson;

          var levelScores = spireReportingService.getOrCreateHive(retval, level);
          var lessonScores = spireReportingService.getOrCreateHive(levelScores, lessonId);
          var classScores = spireReportingService.getOrCreateHive(lessonScores, classId);
          classScores.displayScore = score.score;

          var levelExperiences = spireReportingService.getOrCreateHive(experiences, level);
          var classExperiences = spireReportingService.getOrCreateHive(levelExperiences, classId);
          classExperiences.experienceId = score.experience_id;
          classExperiences.date = score.date;
        });
      }

      retval.overall = spireReportingService.calculateOverallScores(retval);
      retval.experiences = experiences;
      return retval;
    }

    var data = parseScores(scores);

    this.isMultipleSchools = function() {
      return isMultipleSchools;
    }

    this.getClasses = function() {
      return classes;
    };

    this.setClasses = function(newClasses) {
      classes = newClasses;
    };

    this.getLevels = function() {
      return levels;
    };

    this.setLevels = function(newLevels) {
      levels = newLevels;
    };

    this.getColumns = function(level) {
      return lessons[level].slice(0, level == 3 ? 4 : 5); // Level three only uses the first four lessons all others use the first five.
    };

    this.getOverallCellValue = function(level, lesson) {
      if (data.overall && data.overall[level])
        return data.overall[level][lesson.lesson];

      return undefined;
    };

    this.getCellValue = function(level, clss, lesson) {
      return getDisplayScore(level, clss, lesson);
    };

    this.formatScore = function (score)
    {
      if (!angular.isUndefined(score))
      {
        return score + '%';
      }

      return score;
    };

    this.getScoreClass = function(score)
    {
      if (angular.isUndefined(score) || score === null)
      {
        return 'undefined-score';
      }

      if (score >= 80)
      {
        return 'accomplished-score';
      }

      return 'needs-attention-score';
    };

    this.getClassValue = function(level, clss, key)
    {
      try
      {
        if (data &&
          data.experiences &&
          data.experiences[level] &&
          data.experiences[level][clss.cid] &&
          data.experiences[level][clss.cid][key] !== null &&
          !angular.isUndefined(data.experiences[level][clss.cid][key]))
        {
          return data.experiences[level][clss.cid][key];
        }
      }
      catch(e)
      {
        // ignore exception.
      }
      return undefined;
    };

    this.getLegend = function()
    {
      return 'spireClassLevelCALegend.tpl';
    };

    function getScore(level, clss, lesson)
    {
      try
      {
        if (data &&
          data[level] &&
          data[level][lesson.lesson] &&
          data[level][lesson.lesson][clss.cid] !== null &&
          !angular.isUndefined(data[level][lesson.lesson][clss.cid]))
        {
          return data[level][lesson.lesson][clss.cid];
        }
      }
      catch(e)
      {
        // ignore exception.
      }
      return undefined;
    }

    function getDisplayScore(level, clss, lesson)
    {
      var score = getScore(level, clss, lesson);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore))
      {
        return undefined;
      }

      return score.displayScore;
    }
  }
}

module.component('xpSpireAdminLevelProgress', {
  require: {parent: '^^xpReport'},
  template: require('./spireAdminLevelProgress.jade'),
  controller: controller,
});

})();
