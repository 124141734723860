'use strict';
(function () {

  let wallModule = angular.module('xp-element-wall',
    ['angularWidget', 'client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'uuid', 'angular-inview', 'client.directives']);

  let UPDATE_WALL_DATA_OPTIONS = Object.freeze({
    INITIALIZE_LAST_SEEN: 'INITIALIZE_LAST_SEEN',
    REVEAL_NEW_POSTS: 'REVEAL_NEW_POSTS'
  });

  let imageMaxWidth = 300;
  let imageMaxHeight = 300;

  wallModule.directive('xpWallTopLevelPostForm', ['$parse', function ($parse) {

    return {
      restrict: 'E',
      template: require('./topLevelPostForm.jade'),
      replace: false,
      scope: true,
      link: function (scope, element, attributes) {
        let postId = scope.$eval(attributes.xpPostId);
        scope.xpPostId = postId;
      }
    };
  }]);

  wallModule.directive('xpAttachImage', ['$parse', '$timeout', '$window', function ($parse, $timeout, $window) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, elem, attr) {
        let clicked = false;
        let fn = $parse(attr.xpAttachImage);
        let fileElem = angular.element('<input type="file" id="xpFilePicker" accept="image/*">');
        fileElem.css("display", "none");
        elem.append(fileElem);

        fileElem.bind('change', function (evt) {
          let files = [], fileList, i;
          fileList = evt.__files_ || evt.target.files;
          if (fileList !== null) {
            for (i = 0; i < fileList.length; i++) {
              files.push(fileList.item(i));
            }
          }
          $timeout(function () {
            fn(scope, {
              $files: files,
              $event: evt
            });
          });
        });

        function selectFile($event) {
          $event.stopPropagation();
          if (!clicked) {
            clicked = true;
            $timeout(function () {
              $('#xpFilePicker').click();
            });
          }
        }

        elem.on('click', selectFile);
      }
    };
  }]);


  function XPWallPost(postId, replyTo, userId, groupId, timestamp, modified, modifiedByUserId,
                      text, link, attachments, driveDocs, isDeleted, shareMode) {
    this.postId = postId;
    this.replyTo = replyTo;
    this.userId = userId;
    this.groupId = groupId;
    this.timestamp = timestamp;
    this.modified = modified;
    this.modifiedByUserId = modifiedByUserId;
    this.text = text;
    this.link = link;
    this.attachments = attachments;
    this.driveDocs = driveDocs;
    this.isNew = false;
    this.isDeleted = isDeleted;
    this.shareMode = shareMode;

    this.lastModificationTimestamp = function ()// returns self.modified or self.timestamp
    {
      if (modified) {
        return modified;
      }
      return timestamp;
    };

    this.copyWithText = function (text, shareMode, deleted, link, attachments, driveDocs, timestamp, modifiedByUserId) {
      let newPost = new XPWallPost(this.postId, this.replyTo, this.userId, this.groupId,
        timestamp, new Date(), modifiedByUserId, text, link, attachments, driveDocs, deleted,
        shareMode ? shareMode : null);

      return newPost;
    };

    this.copyWithTextMin = function (text, link, attachments, driveDocs, timestamp, modifiedByUserId) {
      return this.copyWithText(text, this.shareMode, this.isDeleted, link, attachments, driveDocs, timestamp, modifiedByUserId);
    };
  }

  function XPWallUserState() {
    this.initFromXPElementState = function (elemState, posts) {
      return this.initWithId(elemState.id, elemState.experience_id, elemState.element_id, elemState.user_id, elemState.small_gid,
        new Date(elemState.timestamp), elemState.user_data, posts);
    };

    this.initWithId = function (id, experienceId, elementId, userId, groupId, timestamp, userDataJSON, posts) {
      this.id = id;
      this.experienceId = experienceId;
      this.elementId = elementId;
      this.userId = userId;
      this.groupId = groupId;
      this.timestamp = timestamp;
      this.userDataJSON = userDataJSON;
      this.posts = posts;

      return this;
    };
  }

  wallModule.factory('XPWallData',
    ['SHARE_MODE', 'GATE_MODE', 'rfc4122', function (SHARE_MODE, GATE_MODE, rfc4122) {
      let XPWallData =
        function XPWallData(userId, groupId, userIsTeacher, teacherId, readOnly, gated, lastSeen, getDataIsFilteredFunc, getDataForCurrentUserFunc) {
          this.currentUserId = userId;
          this.currentGroupId = groupId;
          this.userIsTeacher = userIsTeacher;
          this.teacherId = teacherId;
          this.readonly = readOnly;
          this._isGated = gated;
          this.lastSeen = angular.copy(lastSeen);
          this._previousLastSeen = null;
          this._allPosts = [];
          this.topLevelPosts = [];
          this.nonDeletedPosts = [];
          this._stateData = {};
          this.myPostsCount = 0;
          this.unseenPostsCount = 0;
          this.allPostsCount = 0;
          this._share = null;
          this.respondentsCount = 0;

          this.getDataIsFiltered = getDataIsFilteredFunc;
          this.getDataForCurrentUser = getDataForCurrentUserFunc;

          this.setShare = function (shareMode) {
            this._share = shareMode;
          };

          this.previousLastSeenTime = function () {
            if (!this._previousLastSeen) {
              if (this.lastSeen) {
                return this.lastSeen;
              } else {
                return new Date(0);
              }
            }
            return this._previousLastSeen;
          };

          this.myPosts = function () {
            let self = this;
            let posts = [];

            this._allPosts.forEach(function (post) {
              if ((post.userId == self.currentUserId) ||
                (post.groupId && post.groupId > 0 && (post.groupId == self.currentGroupId))) {
                posts.push(post);
              }
            });

            return posts;
          };

          this.userHasPosted = function (userId, groupId) {
            let stateId = (groupId && groupId > 0) ? groupId : userId;
            let userState = this._stateData[stateId];
            return userState && userState.posts && userState.posts.length > 0;   // NOTE: true even if posts are deleted or not visible to current user.
          };

          this.isUsingSmallGroups = function () {
            return SHARE_MODE.isUsingSmallGroups(this._share);
          };

          this.postsCount = function (userId, groupId) {
            let self = this;
            let count = 0;

            // count only top level posts that have not been removed
            this._allPosts.forEach(function (post) {
              if (((groupId && groupId > 0 && post.groupId == groupId) || post.userId == userId) && // post belongs to me
                !(post.replyTo && post.replyTo.length > 0) && // not a reply
                self.isPostVisibleToCurrentUser(post)
              ) {
                ++count;
              }
            });

            return count;
          };

          this.isPostEditable = function (post) {
            if (!post) {
              return false;
            } // failsafe

            if (this.userIsTeacher) {
              return true;
            }

            if (post.userId == this.currentUserId) {
              return true;
            }

            // Check to see if this was submitted by the user's same group
            if (this.isUsingSmallGroups()) {
              return post.groupId == this.currentGroupId;
            }

            return false;
          };

          this.setLastSeen = function (lastSeenTimestamp) {
            this.lastSeen = lastSeenTimestamp;
            if (!this._previousLastSeen) {
              this._previousLastSeen = lastSeenTimestamp;
            }
          };

          this.savePreviousLastSeen = function () {
            if (this.lastSeen) {
              this._previousLastSeen = this.lastSeen;
            }
          };

          this.revealUnseenPosts = function (newLastSeenTimestamp) {
            this.savePreviousLastSeen();
            this.setLastSeen(newLastSeenTimestamp);
            this.setPosts(this._allPosts);  // recategorize previously unseen posts as seen, based on lastSeen timestamp
          };

          this.setHasSeen = function (newLastSeenTimestamp) {
            this.savePreviousLastSeen();
            this.setLastSeen(newLastSeenTimestamp);
          };

          this.getTimestampForUserId = function (userId, groupId) {
            if (userId <= 0) {
              return null;
            }
            let stateId = groupId && groupId > 0 ? groupId : userId;
            let stateData = this._stateData[stateId];
            return stateData ? stateData.timestamp : undefined;
          };

          this.updateState = function (stateUpdateData, initLastSeen, revealNewPosts) {
            let newWallData = new XPWallData(this.currentUserId, this.currentGroupId, this.userIsTeacher, this.teacherId, this.readonly, this._isGated,
              this.lastSeen, this.getDataIsFiltered, this.getDataForCurrentUser);

            newWallData.setShare(this._share);

            let copiedStateData = angular.copy(this._stateData); // ipad uses shallow copy of this._stateData

            angular.extend(copiedStateData, stateUpdateData);
            newWallData.setStateData(copiedStateData, initLastSeen, null, revealNewPosts);

            return newWallData;
          };

          this.setStateData = function (newStateData, initLastSeen, defaultForLastSeen, revealNewPosts) {
            //    BOOL bCurrentUserHasAtLeastOnePost = [self userHasPosted:self.currentUserId];
            let keyForCurrentUser = (this.currentGroupId && this.currentGroupId > 0) ? this.currentGroupId : this.currentUserId;
            let userWhoEditedMyPosts = -1;
            let mostRecentModTimestamp = new Date(0);
            let lastSeenFromServer = null;
            let self = this;
            //let context = $scope.options.context;

            Object.keys(newStateData).forEach(function (userKey) {
              let userState = newStateData[userKey];
              let modTimestamp = self.getModificationTime(userState.posts, userState.userId);  //most recent mod to any post

              mostRecentModTimestamp = mostRecentModTimestamp.getTime() > modTimestamp.getTime() ?
                mostRecentModTimestamp : modTimestamp;  // find most recent across all users

              if (userKey == keyForCurrentUser) {
                lastSeenFromServer = userState.timestamp;
                if (userState.posts !== null && userState.posts.length > 0) {
                  if (modTimestamp.getTime() > userState.timestamp.getTime()) {
                    userWhoEditedMyPosts = userKey;
                  }
                }
              }
              self._stateData[userKey] = userState; // set or replace the state data for given user
            });

            if (initLastSeen) { // if we've just received data from the server for the first time
              // need to initialize the lastSeen & previousLastSeen machinery
              // get the timestamp from it
              if (!lastSeenFromServer && defaultForLastSeen) {
                lastSeenFromServer = defaultForLastSeen;
                // all posts will be considered new and unseen
              }
              if (lastSeenFromServer &&
                (userWhoEditedMyPosts == this.currentUserId || userWhoEditedMyPosts === -1) &&
                (!this.lastSeen || lastSeenFromServer.getTime() >= this.lastSeen.getTime())
              ) {
                this.savePreviousLastSeen();
                this.setLastSeen(lastSeenFromServer);  // start things off using our timestamp from the server, if there was one.
              }
            }
            // now that we've collected all the relevant information, update lastSeen and previousLastSeen as appropriate:
            if (revealNewPosts && (!this._isGated || this.userIsTeacher || this.userHasPosted(this.currentUserId, this.currentGroupId))) {
              this.savePreviousLastSeen();
              this.setLastSeen(mostRecentModTimestamp);
            } // else: our lastSeen doesn't change, and thus all new and modified posts queue up as unseen

            // collect all posts into one array, for setPosts
            let posts = [];
            Object.keys(this._stateData).forEach(function (userKey) {
              let wallStateData = self._stateData[userKey];
              if (self.getDataIsFiltered(wallStateData.userId) === false && self.getDataForCurrentUser(wallStateData.userId)) {
                posts.push.apply(posts, wallStateData.posts);
              }
            });
            this.setPosts(posts);
          };

          this.setPosts = function (posts) {
            let self = this;

            // (We can't completely filter out deleted posts here because would result in the posts being removed from the persistent repository when
            //  the state of this element gets saved).
            this._allPosts = posts.concat().sort(function (a, b) {
              return a.timestamp.getTime() - b.timestamp.getTime();
            });

            this.nonDeletedPosts = [];
            this._allPosts.forEach(function (post) {
              if (!self.postIsCompletelyHidden(post)) {
                self.nonDeletedPosts.push(post);
              }
            });

            let respondents = {};

            this.nonDeletedPosts.forEach(function (post) {
              if (self.postIsNotYetSeen(post, self.lastSeen)) {
                post.isNew = true;
              }
              //NOTE: don't clear this here, it gets cleared if/when the post gets loaded into a UITableView cell

              // Fill the map with unique non-teacher respondents who have a non deleted top level post.
              if (!post.replyTo || 0 === post.replyTo.length) {
                let respondentId;

                if (SHARE_MODE.isUsingSmallGroups(self.share)) {
                  if (post.groupId === 0) {
                    respondentId = post.userId;
                  } else {
                    respondentId = post.groupId;
                  }
                } else {
                  respondentId = post.userId;
                }

                if (respondentId != self.teacherId) {
                  respondents[respondentId] = true;
                }
              }
            });

            this.respondentsCount = Object.keys(respondents).length;

            let seenPosts = [];
            this.unseenPostsCount = 0;
            if (this.readonly) {
              seenPosts = this.nonDeletedPosts; // show everything.
            } else {
              let tempArray = [];
              this.nonDeletedPosts.forEach(function (post) {
                tempArray.push(post);
                if (self.postIsNotYetSeen(post, self.lastSeen)) {
                  self.unseenPostsCount++;
                }
              });
              seenPosts = tempArray;
            }
            this.topLevelPosts = [];
            seenPosts.forEach(function (post) {
              if (!post.replyTo || 0 === post.replyTo.length) {
                self.topLevelPosts.push(post);
              }
            });

            let allReplies = [];
            seenPosts.forEach(function (post) {
              if (post.replyTo && post.replyTo.length > 0) {
                allReplies.push(post);
              }
            });

            this._replies = {};

            this.topLevelPosts.forEach(function (post) {
              let postReplies = [];
              allReplies.forEach(function (reply) {
                if (reply.replyTo == post.postId &&
                  self.isPostVisibleToCurrentUser(reply) &&
                  !self.postIsCompletelyHidden(reply)) {
                  postReplies.push(reply);
                }
              });
              self._replies[post.postId] = postReplies;
              post.replies = postReplies;
            });
            this.allPostsCount = this.nonDeletedPosts.length;
            let allStudentTopLevelPosts = [];

            this.nonDeletedPosts.forEach(function (post) {
              if ((post.userId != self.teacherId) &&
                (!post.replyTo || 0 === post.replyTo.length) && // not a reply
                (!post.isDeleted) // not a temporary placeholder
              ) {
                allStudentTopLevelPosts.push(post);
              }
            });

            this.allStudentPostsCount = allStudentTopLevelPosts.length;
            this.myPostsCount = this.postsCount(this.currentUserId, this.currentGroupId);
          };

          this.postIsCompletelyHidden = function (post) // posts that have been deleted or filtered out, placeholder has already been seen or never will be.
          {
            if (post.replyTo && post.replyTo.length) {
              if (post.isDeleted) {
                return true;
              }  // replies that are explicitly deleted disappear completely and immediately!
              //check visibility of parent
              let parent = this.findPostWithId(post.replyTo);
              if (!parent || this.postIsCompletelyHidden(parent)) {
                return true;
              } // when parent is gone, all of its children are gone too
            }
            if (this.isPostVisibleToCurrentUser(post)) {
              return false;
            }
            // if user is viewing new posts during this session, compare to the lastSeen time as it was before opening the new posts
            // (because we want the newly opened posts to display placeholders if they have been removed)
            if (post.timestamp.getTime() > this.lastSeen.getTime()) // timestamp after lastSeen; never seen but subsequently deleted/hidden
            {
              return true;  // user has never seen it, therefore doesn't need to be notified that it has changed.
            }
            if (post.lastModificationTimestamp().getTime() <= this.previousLastSeenTime().getTime()) // deleted/hidden before previouslastSeen;
            {
              return true; // user has already seen the placeholder = already been notified of deletion, therefore doesn't need to be notified again
            }
            if (post.timestamp.getTime() > this.previousLastSeenTime().getTime() &&
              post.lastModificationTimestamp().getTime() < this.lastSeen.getTime()) {
              return true; // post created and then hidden
            }
            // If share mode is to teacher and this is not a teacher, self or small group member, the post is completely hidden
            if (this.isPostSharedWithTeacherOnly(post)) {
              return post.userId != this.teacherId &&
                post.userId != this.currentUserId &&
                (post.groupId != this.currentGroupId || post.groupId === 0);
            }

            return false;  // still need to notify the user that this post is no longer visible to him.
          };

          this.isPostSharedWithTeacherOnly = function (post) {
            if (post.replyTo && post.replyTo.length) {  // parent post completely determines sharing
              let parentPost = this.findPostWithId(post.replyTo);

              if (parentPost) {
                return this.isPostSharedWithTeacherOnly(parentPost);
              }   // for a reply, the share mode should be determined by the parent post.
              return false; // this is an error condition, not expected to ever occur!
            }

            if (post.shareMode && post.shareMode.length === 0) {
              return SHARE_MODE.isShareToTeacher(this.share);
            }

            return SHARE_MODE.isShareToTeacher(post.shareMode);
          };

          this.postOrParentIsDeleted = function (post) {
            if (post.isDeleted) {
              return true;
            }
            let parentPost = this.getParentPostForPost(post);
            if (!parentPost) {
              return false;
            }  // not a reply, not self.isDeleted
            return this.postOrParentIsDeleted(parentPost);  // recursive check of parent (currently only 1 level, so = parentPost.isDeleted)
          };

          this.isPostVisibleToCurrentUser = function (post) {
            if (!post) {
              return false;
            } // failsafe, hopefully unecessary

            if (this.postOrParentIsDeleted(post)) {
              return false;
            } // deleted = cannot be seen by anyone. (the deleted-placeholder logic works independently of this check)
            let parentPost = this.getParentPostForPost(post);   // null if post is not a reply
            if (
              !this.isPostSharedWithTeacherOnly(post) ||  // share mode is public = all users can see it
              (this.userIsTeacher &&        // share mode=private: teachers can see all non-deleted posts
                !(                    // excepting replies from studentY to a private-to-teacher post by studentX
                  parentPost &&
                  (parentPost.userId != this.currentUserId) &&
                  (parentPost.userId != post.userId)
                )
              )) {
              return true;
            }


            if (!parentPost || // if this is not a reply
              this.isPostVisibleToCurrentUser(parentPost) // or if the parent post is visible to me
            ) {
              return (
                (post.userId == this.currentUserId) || // I can see my own posts and replies.
                (post.userId == this.teacherId) || // and I can see posts and replies from the teacher
                (post.groupId == this.currentGroupId && post.groupId !== 0) // can see the small group posts
              );
            }
            return false;  // if it is a reply and parent is not visible to me, then it's not visible to me.
          };

          // if the post is a reply, get the post to which it replies
          // (if shared privately, visibility of the parent post determines whether a user can see the replies)
          this.getParentPostForPost = function (post) {
            if (post.replyTo && post.replyTo.length) {
              let parentPost = this.findPostWithId(post.replyTo);
              if (parentPost) {
                return parentPost;
              }
              return null; // this is an error condition, not expected to ever occur!
            }
            return null;  // if this post is not a reply: return null
          };

          this.postHasBeenSeenByCurrentUser = function (post) {
            return !this.postIsNotYetSeen(post, this.lastSeen);
          };

          this.postIsNotYetSeen = function (post, lastSeenDate) {
            return (!lastSeenDate || post.lastModificationTimestamp().getTime() > lastSeenDate.getTime()) &&
              ((post.userId != 0 && post.userId != this.currentUserId) ||
                (post.groupId != 0 && post.groupId != this.currentGroupId));
          };

          this.findPostWithId = function (postId) {
            return this.findPostWithIdInArray(postId, this._allPosts);
          };

          this.findRepliesToPostId = function (postId) {
            return this._replies[postId];
          };

          this.updatePost = function (originalPost, text, link, attachments, driveDocs) {
            let updatedPost = originalPost.copyWithTextMin(text, link, attachments, driveDocs, originalPost.timestamp, this.currentUserId);

            return this.prepareToSaveUpdatedPost(updatedPost, originalPost);
          };

          this.deleteOrUndeletePost = function (originalPost, del) {
            let updatedPost = originalPost.copyWithText(originalPost.text,
              originalPost.shareMode, del, originalPost.link,
              originalPost.attachments, originalPost.driveDocs, originalPost.timestamp, this.currentUserId);

            return this.prepareToSaveUpdatedPost(updatedPost, originalPost);
          };

          this.changeShareModeForPost = function (originalPost, newShareMode) {
            let updatedPost = originalPost.copyWithText(originalPost.text, newShareMode,
              originalPost.isDeleted, originalPost.link, originalPost.attachments, originalPost.driveDocs,
              originalPost.timestamp, this.currentUserId);

            return this.prepareToSaveUpdatedPost(updatedPost, originalPost);
          };

          this.addNewPostWithText = function (text, link, attachments, driveDocs, userIdForSave) {
            let postsToSave = this.postsForUserId(userIdForSave, this.currentGroupId).concat();
            // creating a new post
            let postId = rfc4122.v4(); // generate uuid
            let post = new XPWallPost(postId, null, this.currentUserId, this.currentGroupId, new Date(),
              null, -1, text, link, attachments, driveDocs, false, this.shareModeForNewPosts());

            postsToSave.push(post);

            return postsToSave;
          };

          this.addNewReplyToPostId = function (postId, replyText) {
            let replyId = rfc4122.v4(); // generate uuid

            let reply = new XPWallPost(replyId, postId, this.currentUserId, this.currentGroupId, new Date(), null, -1,
              replyText, null, null, null, false, null);  // shareMode is meaningless for replies (= determined by parent).

            let postsToSave = this.postsForUserId(this.currentUserId, this.currentGroupId).concat();
            postsToSave.push(reply);
            return postsToSave;
          };

          this.prepareToSaveUpdatedPost = function (updatedPost, originalPost) {
            let postsToSave = [];
            let updatingPostId = originalPost.postId;
            let userIdForSave = originalPost.userId;
            let groupIdForSave = originalPost.groupId;

            if ((originalPost.userId != this.currentUserId) && // teacher is editing another user's post
              !(originalPost.groupId && originalPost.groupId == this.currentGroupId)) // small group
            {
              if (!this.userIsTeacher) {
                throw "XPWallData.prepareToSaveUpdatedPost tried to edit another's post without being teacher";  // failsafe...should never execute this!
              }
            }

            postsToSave = this.postsForUserId(userIdForSave, groupIdForSave).concat();
            let postsToSaveIndex = this.indexOfPost(updatingPostId, postsToSave);

            if (-1 == postsToSaveIndex) {
              return postsToSave;
            }

            postsToSave[postsToSaveIndex] = updatedPost;

            return postsToSave;
          };

          this.getModificationTime = function (posts, userId) {
            let modTime = new Date(0);

            if (posts !== null) {
              posts.forEach(function (post) {
                modTime = modTime.getTime() > post.lastModificationTimestamp().getTime() ? modTime : post.lastModificationTimestamp();
              });
            }
            return modTime;
          };


          this.shareModeForNewPosts = function () {
            if (this._share === null || this._share.length === 0) {
              return SHARE_MODE.GROUP;
            }

            return this._share;
          };


          this.postsForUserId = function (userId, groupId) {
            let retval = [];
            this._allPosts.forEach(function (post) {
              if ((groupId && groupId > 0 && post.groupId == groupId) || post.userId == userId) {
                retval.push(post);
              }
            });
            return retval;
          };

          this.indexOfPost = function (postId, postArray) {
            let index = 0;
            let foundIndex = -1;
            postArray.forEach(function (post) {
              if (post.postId == postId) {
                foundIndex = index;
              }
              ++index;
            });
            return foundIndex;
          };

          this.findPostWithIdInArray = function (postId, postArray) {
            let foundPost = null;
            postArray.forEach(function (post) {
              if (post.postId == postId) {
                foundPost = post;
              }
            });
            return foundPost;
          };

          this.shouldShowUnseen = function () {
            if (this.userIsTeacher || !this._isGated) {
              return this.unseenPostsCount > 0;
            } else {
              return this.unseenPostsCount > 0 && this.userHasPosted(this.currentUserId, this.currentGroupId);
            }
          };

          this.shouldShowWall = function () {
            if (this.userIsTeacher || !this._isGated) {
              // this check should include unseen posts
              return this.allPostsCount > 0;
            } else // gated wall, show posts only if user has posted something
            {
              return this.userHasPosted(this.currentUserId, this.currentGroupId);
            }
          };
        };

      return XPWallData;
    }]);

  wallModule.factory('WallElementService', ['ElementsRestService', 'JSONStringUtility', '$log', 'SHARE_MODE',
    function (ElementsRestService, JSONStringUtility, $log, SHARE_MODE) {

      function encodeAttachments(attachments, experienceId, elementId) {
        let json = [];

        if (attachments) {
          attachments.forEach(function (attachment) {
            json.push(encodeAttachment(attachment, experienceId, elementId));
          });
        }

        return json;
      }

      function encodeAttachment(attachment, experienceId, elementId) {
        if (!attachment) {
          return null;
        }

        let json = {
          xid: attachment.xid,
          type: attachment.type,
          url: 'elements/' + elementId + '/experience/' + experienceId + '/attachment/' + attachment.xid
        };

        return json;
      }

      function decodeAttachment(attachment, experienceId, elementId) {
        if (!attachment) {
          return null;
        }

        let xid = attachment.xid;

        //TODO: FIXME - url is apparently supposed to be supplied by the server,
        // but StateChangNotifications weren't including it, causing a crash
        if (!attachment.url) { // temporary strategy for backwards compatibility (can be removed if server always supplies this value)
          attachment.url = 'elements/' + elementId + '/experience/' + experienceId + '/attachment/' + xid;
        }

        return attachment;
      }

      function decodeAttachments(attachmentsJson, experienceId, elementId) {
        if (!attachmentsJson) {
          return null;
        }

        attachmentsJson.forEach(function (attachment) {
          decodeAttachment(attachment, experienceId, elementId);
        });

        return attachmentsJson;
      }

      function decodePost(userId, groupId, experienceId, elementId, json) {
        let attachmentsJson = json.attachments;
        let attachments = decodeAttachments(attachmentsJson, experienceId, elementId);

        return new XPWallPost(json.postId, json.replyToPostId, userId, groupId, new Date(json.timestamp), 'modified' in json ? new Date(json.modified) : null,
          'modifiedByUser' in json ? json.modifiedByUser : -1, json.text, json.link, attachments, json.driveDocs,
          'isDeleted' in json ? json.isDeleted == 'true' : false,
          json.shareMode);
      }

      function decodePosts(elemState) {
        let userData = elemState.user_data;
        if (!userData) {
          return new XPWallUserState().initFromXPElementState(elemState, null);
        }

        // backward compatibility: userData consisted of an array of posts
        // but new versions could embed this array in a json dictionary, using the key @"posts".

        let userDataIsArray = userData.indexOf('[') === 0;

        let wallData;
        let postsJson = null;

        if (userDataIsArray) {  // deal with legacy data (supplying defaults for new values)
          postsJson = JSONStringUtility.parse(userData);
          wallData = {posts: postsJson};
          // backward compatibility: supply defaults for additional values
        } else {
          wallData = JSONStringUtility.parse(userData);

          // See if this is an array or an object
          if (!Array.isArray(wallData) && wallData.posts) {
            postsJson = JSONStringUtility.parse(wallData.posts);
            wallData = {posts: postsJson};
          } else {
            postsJson = wallData.posts;
          }
        }

        // parse the json string for each individual post.
        let arrayOfPosts = [];
        postsJson.forEach(function (postJson) {
          arrayOfPosts.push(decodePost(elemState.user_id, elemState.small_gid,
            elemState.experience_id, elemState.element_id, postJson));
        });

        let userState = new XPWallUserState().initFromXPElementState(elemState, arrayOfPosts);
        return userState;
      }

      function wallStateFromElemState(elemState) {
        return decodePosts(elemState);
      }

      function encodePost(post, experienceId, elementId) {
        if (!post) {
          return null;
        }

        let attachmentsJson = encodeAttachments(post.attachments, experienceId, elementId);

        let dictionary =
          {
            postId: post.postId,
            replyToPostId: post.replyTo,
            timestamp: post.timestamp.toISOString(),
            text: post.text,
            link: post.link,
            attachments: attachmentsJson,
            driveDocs: post.driveDocs,
            isDeleted: (post.isDeleted ? 'true' : 'false'),
            shareMode: post.shareMode,
            small_gid: post.groupId
          };

        if (post.modified) {
          dictionary.modified = post.modified.toISOString();
          dictionary.modifiedByUser = post.modifiedByUserId;
        }

        return dictionary;
      }

      function encodePosts(posts, experienceId, elementId) {
        if (!posts) {
          return null;
        }

        let postsJson = [];

        posts.forEach(function (post) {
          postsJson.push(encodePost(post, experienceId, elementId));
        });

        return JSONStringUtility.stringify(postsJson);
      }

      return {
        getAllPosts: function (experienceId, elementId, groupName, isInactive, success, error) {
          ElementsRestService.getSharedState(experienceId, elementId, groupName, isInactive,
            function (result) {
              let allStates = {};
              try {
                result.forEach(function (elemState) {
                  let userState = wallStateFromElemState(elemState);
                  let stateId = userState.groupId && userState.groupId > 0 ? userState.groupId : userState.userId;
                  allStates[stateId] = userState;
                });
                success(allStates);
              }
              catch (err) {
                $log.error("error in parsing obtained shared state for wall in all posts:", err);
                error(err);
              }
            },
            error);
        },
        getCustomShare: function (experienceId, elementId, groupName, isInactive, success, error) {
          ElementsRestService.getSharedState(experienceId, elementId, groupName, isInactive,
            function (result) {
              let customShare = null;
              try {
                result.forEach(function (elemState) {
                  // See if this element state contains a custom share
                  if (elemState.user_data) {
                    // Parse this object and look for custom share attribute
                    let userData = JSONStringUtility.parse(elemState.user_data);
                    if (userData && userData.custom_share) {
                      customShare = userData.custom_share;
                    }
                  }
                });
                success(customShare);
              }
              catch (err) {
                $log.error("error in parsing obtained shared state for wall in all posts:", err);
                error(err);
              }
            },
            error);
        },
        savePosts: function (experienceId, elementId, userId, groupId, customShare, timestamp, posts, success, error) {
          try {
            let uploads = {};
            posts.forEach(function (post) {
              if (post.attachments) {
                post.attachments.forEach(function (attachment) {
                  if (attachment.xid && attachment.file instanceof Blob) {
                    uploads[attachment.xid] = attachment.file;
                  }
                });
              }
            });

            let userData;
            if (customShare) {
              userData = {};
              userData.custom_share = customShare;
              userData.posts = encodePosts(posts, experienceId, elementId);
            } else {
              userData = encodePosts(posts, experienceId, elementId);
            }
            ElementsRestService.saveUserState(experienceId, elementId, userId, groupId, userData,
              function (data) {
                let userState = decodePosts(data);
                success(userState);
              },
              function (err) {
                $log.error("error in saving state for wall:", err);
                error(err);
              }, uploads);
          }
          catch (err) {
            $log.error("error in parsing state for wall:", err);
            error(err);
          }
        },
        updateWallData: function (wallData, userState, options) {
          userState.user_id = parseInt(userState.user_id, 10);
          userState.small_gid = parseInt(userState.small_gid, 10);
          let wallUserState = decodePosts(userState);
          let updatedStateData = {};
          let stateId = userState.small_gid && userState.small_gid > 0 ? userState.small_gid : userState.user_id;
          updatedStateData[stateId] = wallUserState;
          let initializeLastSeen = false;
          let revealNewPosts = false;
          if (options) {
            if (UPDATE_WALL_DATA_OPTIONS.INITIALIZE_LAST_SEEN in options) {
              initializeLastSeen = options[UPDATE_WALL_DATA_OPTIONS.INITIALIZE_LAST_SEEN];
            }
            if (UPDATE_WALL_DATA_OPTIONS.REVEAL_NEW_POSTS in options) {
              revealNewPosts = options[UPDATE_WALL_DATA_OPTIONS.REVEAL_NEW_POSTS];
            }
          }
          return wallData.updateState(updatedStateData, initializeLastSeen, revealNewPosts);
        }

      };
    }]);

  wallModule.controller('clientWallElementCtrl', ['$scope', 'widgetConfig', 'WallElementService', 'ElementsErrorService', 'JSONStringUtility', 'ElementUtilities',
    'rfc4122', '$filter', '$log', '$element', '$window', 'ModalService', '$modal', '$timeout', 'CameraService', 'SHARE_MODE', 'GATE_MODE', 'XPWallData', '$sce',
    'ActiveExperience', 'PermissionConsts', 'loadImageWithOrientation', 'GoogleDrive', 'User',
    function ($scope, widgetConfig, WallElementService, ElementsErrorService, JSONStringUtility, ElementUtilities,
              rfc4122, $filter, $log, $element, $window, ModalService, $modal, $timeout, CameraService, SHARE_MODE, GATE_MODE, XPWallData, $sce,
              ActiveExperience, PermissionConsts, loadImageWithOrientation, GoogleDrive, User) {
      let TOP_LEVEL_POST = 'TOP_LEVEL_POST';
      let TOP_LEVEL_POST_TEXTAREA_PREFIX = 'wall-top-post-textarea-';
      let MAX_POST_LENGTH = 2500;

//  widgetConfig.exportProperties({title: 'Element'});
      $scope.options = widgetConfig.getOptions($scope);
      $scope.instructions = {};
      $scope.forms = {};
      $scope.gateMode = GATE_MODE.XPGateModeGated;
      $scope.maxEntriesPerUser = 0;
      $scope.maxEntriesPerGroup = 0;
      $scope.postEditView =
        {
          editable: false
        };
      $scope.editingResponseId = null;
      $scope.TOP_LEVEL_POST = TOP_LEVEL_POST;
      $scope.TOP_LEVEL_POST_TEXTAREA_PREFIX = TOP_LEVEL_POST_TEXTAREA_PREFIX;
      $scope.topLevelPost = {};
      $scope.hasCamera = CameraService.hasUserMedia;
      $scope.isActive = false;
      $scope.isTeacher = false;
      $scope.portionResponded = 0;
      $scope.share = null;
      $scope.initialShare = null;
      $scope.groupEditingId = 0;
      $scope.focusPostId = null;
      $scope.replyPostId = null;
      $scope.postsForDisplay = [];
      $scope.unseenPostsCount = 0;
      $scope.myPostsCount = 0;
      $scope.shouldShowWall = false;

      $scope.imageMaxWidth = imageMaxWidth;
      $scope.imageMaxHeight = imageMaxHeight;

      let wallData = null;
      let shareFromGroupId = 0;

      let postBeingEditedId;
      let elementRectIsVisible = false;
      let context;
      let delayedWallData = null;

      $scope.toolbar = ['Bold', 'Italic', 'Underline', '|', 'numberedList', 'bulletedList', '|', 'Link'];
      $scope.ckEditor = false;

      $scope.$watch('options', function () {
        let options = $scope.options;
        if (!options.element) {
          return;
        }

        let service = options.elementRealtimeService;
        let EVENTS = service.EVENTS;
        context = options.context;
        $scope.isActive = context.status == 'ACTIVE';

        parseElement();

        wallData = new XPWallData(context.userId, shareFromGroupId, context.userIsTeacher(),
          context.clazz.teacher.uid, context.getViewingInactiveExperience(), true, null, getDataIsFiltered, $scope.getDataForCurrentUser);

        wallData.setShare($scope.share);

        teacherEditable();

        $scope.isTeacher = context.userIsTeacher();

        loadPosts(true);
      }, true);

      function parseElement() {
        let element = $scope.options.element;

        element.config.attributes.forEach(function (attribute) {
          let name = attribute.name;
          let value = attribute.value;

          switch (name) {
            case "instructions" :
              $scope.instructions.question = $sce.trustAsHtml(value);
              break;
            case "instructions_image_url" :
              $scope.instructions.imageUrl = ElementUtilities.getElementURL(element, $scope.options.context.experienceId, value);
              break;
            case "gate_mode" :
              $scope.gateMode = value;
              break;
            case "max_entries_per_user" :
              $scope.maxEntriesPerUser = value;
              break;
            case "max_entries_per_group" :
              $scope.maxEntriesPerGroup = value;
              break;
            case "share" :
              $scope.share = value;
              break;
            case "small_gid" :
              shareFromGroupId = value;
              break;
          }
        });

        // Save the initial share setting so we know if it was switched by the teacher
        $scope.initialShare = $scope.share;

        // configure the group id based on the share setting
        if ($scope.isUsingSmallGroups()) {
          shareFromGroupId = context.groupId;
        }
      }

      function teacherEditable() {
        // TODO: Look at this code more closely
        if (SHARE_MODE.isShareToTeacher($scope.share) && $scope.options.context.userIsTeacher()) {
          $scope.postEditView.editable = false; // dont allow teacher announcements for now.
        } else {
          $scope.postEditView.editable = $scope.isActive;
        }
      }

      $scope.hasResponses = function () {
        return $scope.myPostsCount > 0;
      };

      $scope.isGoogleUser = function () {
        return User.isGoogleUser();
      };

      $scope.hasGoogleDrivePermission = function () {
        return ActiveExperience.hasPermission($scope.options.context.experienceId, PermissionConsts.integration_curriculum_google_drive) &&
          $scope.isGoogleUser();
      };

      $scope.getDataForCurrentUser = function (userId) {
        return $scope.options.studentId === undefined || $scope.options.studentId === userId;
      };

      function getDataIsFiltered(userId) {
        return $scope.filteredStudent !== null && !angular.isUndefined($scope.filteredStudent) &&
          $scope.filteredStudent.uid != userId;
      }

      $scope.onChangeText = function () {
        if ($scope.changed && $scope.topLevelPost.text && $scope.topLevelPost.text.length && $scope.postId) {
          $scope.changed({
            elementId: $scope.options.element.id,
            selection: {text: $scope.topLevelPost.text, postId: $scope.postId}
          });
        }
      };

      $scope.onChangeTopLevelText = function () {
        if ($scope.changed && $scope.topLevelPost.topLevelText && $scope.topLevelPost.topLevelText.length && $scope.postId) {
          $scope.changed({
            elementId: $scope.options.element.id,
            selection: {text: $scope.topLevelPost.topLevelText, postId: $scope.postId}
          });
        }
      };

      $scope.onChangeReplyText = function (postId) {
        if ($scope.changed && $scope.forms[postId] && $scope.forms[postId].length) {
          $scope.changed({
            elementId: $scope.options.element.id,
            selection: {text: $scope.forms[postId], postId: postId, isReply: true}
          });
        }
      };

      $scope.canEditForRespondent = function(respondentId) {
        return false;
      };

      function loadPosts(firstTime) {
        let options = $scope.options;
        if (!options.element || !options.element.id) {
          return;
        }

        let isInactive = $scope.options.context.getViewingInactiveExperience();

        WallElementService.getAllPosts($scope.options.context.experienceId, $scope.options.element.id, context.groupName, isInactive,
          function (result) {
            // to make this thread safe (since we're reloading all the state data anyway) we create a new instance
            wallData = new XPWallData(context.userId, shareFromGroupId, context.userIsTeacher(),
              context.clazz.teacher.uid, context.getViewingInactiveExperience(), $scope.gateMode == GATE_MODE.XPGateModeGated,
              (firstTime ? null : wallData.lastSeen), getDataIsFiltered, $scope.getDataForCurrentUser);

            // Get any custom share state
            WallElementService.getCustomShare($scope.options.context.experienceId, $scope.options.element.id, context.groupName, isInactive,
              function (customShare) {
                if (customShare) {
                  $scope.share = customShare;
                  teacherEditable();
                }

                // SEt the share for the entire wall
                wallData.setShare($scope.share);

                // when first loading data, hide all recently edited posts as unseen
                wallData.setStateData(result, firstTime, new Date(0), (!firstTime && isWallElementVisible()));
                updateDisplay();

                if ($scope.cached) {
                  let cachedValue = $scope.cached({elementId: $scope.options.element.id});
                  if (cachedValue) {
                    if (cachedValue.postId === TOP_LEVEL_POST) {
                      $scope.textAreaFocusHandler(TOP_LEVEL_POST);
                      $scope.topLevelPost.topLevelText = cachedValue.text;
                      if (/<[a-z][\s\S]*>/i.test(cachedValue.text)) {
                        $scope.ckEditor = true;
                      }
                    } else if (cachedValue.isReply) {
                      $scope.responseTextAreaFocusHandler(cachedValue.postId);
                      $scope.forms[cachedValue.postId] = cachedValue.text;
                    } else {
                      $scope.requestEditCached(cachedValue.postId, cachedValue.text);
                      $scope.topLevelPost.text = cachedValue.text;
                    }
                  }
                }

                if (firstTime) {
                  let service = options.elementRealtimeService;
                  let EVENTS = service.EVENTS;

                  service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                  $scope.$on('$destroy', function () {
                    service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                  });

                  $scope.$on('$destroy', viewWillDisappear);

                  // Notify the widget that were are done loading the data
                  widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
                }

                // Set existing posts as seen
                wallData.setHasSeen(new Date());
              },
              function (error) {
                ElementsErrorService.error(error);
              });
          },
          function (error) {
            ElementsErrorService.error(error);
          });
      }

      function updateWallDataWithUserState(userState) {
        // Convert numbers since xmpp message passes strings
        userState.element_id = parseInt(userState.element_id, 10);
        userState.experience_id = parseInt(userState.experience_id, 10);
        userState.id = parseInt(userState.id, 10);
        userState.small_gid = parseInt(userState.small_gid, 10);
        userState.user_id = parseInt(userState.user_id, 10);

        // See if a new custom state is set
        let userData = JSONStringUtility.parse(userState.user_data);
        let custom_share = userData.custom_share || null;
        if (custom_share) {
          $scope.share = custom_share;
          wallData.setShare($scope.share);
          teacherEditable();
        }

        let shouldRevealNewPosts = isWallElementVisible() &&
          ($scope.focusPostId === undefined || $scope.focusPostId === null); //don't display new posts if user is composing a reply

        // If the user state info was created by the current user then all unseen posts should first be shown
        let stateId = userState.small_gid && userState.small_gid > 0 ? parseInt(userState.small_gid, 10) : parseInt(userState.user_id, 10);
        let currentStateId = shareFromGroupId && shareFromGroupId > 0 ? shareFromGroupId : context.userId;
        if (shouldRevealNewPosts && stateId === currentStateId) {
          showUnseenPostsAfterSubmit();
        }

        let options = {};
        options[UPDATE_WALL_DATA_OPTIONS.REVEAL_NEW_POSTS] = shouldRevealNewPosts;

        let updatedWallData = WallElementService.updateWallData(wallData, userState, options);

        if (shouldRevealNewPosts) {
          wallData = updatedWallData;
        } else {
          // Update the count number
          wallData.unseenPostsCount = updatedWallData.unseenPostsCount;
          // Save the updated wall data so we can display it when the indicator is clicked
          delayedWallData = updatedWallData;
        }

        updateDisplay();

        $scope.$digest();
      }

      function updateDisplay() {
        $scope.unseenPostsCount = wallData.unseenPostsCount;
        $scope.myPostsCount = wallData.myPostsCount;
        $scope.shouldShowWall = wallData.shouldShowWall();
        updatePostsForDisplay();
        updatePortionResponded();
      }

      function updatePostsForDisplay() {
        let newCells = [];

        wallData.topLevelPosts.forEach(function (post) {
          newCells.push(cellForPost(post));
        });

        $scope.postsForDisplay = newCells;
      }

      function stateChangedNotificationHandler(e) {
        let result = e.detail;
        let state = result.record;

        if (state.element_id != $scope.options.element.id) {
          return;
        }

        $log.debug("Received wall state update: " + JSON.stringify(result));

        let timestampDate = new Date(state.timestamp);

        updateWallDataWithUserState(state);
      }

      $scope.getUserDisplayName = function (userId) {
        return context.getUserDisplayName(userId);
      };

      $scope.getUserEmail = function (userId) {
        return context.getUserEmail(userId);
      };

      $scope.getUserGroup = function (userId) {
        return context && context.getUserGroup(userId);
      };

      $scope.getUserIsTeacher = function (userId) {
        return context && context.getUserIsTeacher(userId);
      };

      $scope.userIsTeacher = function () {
        return context && context.userIsTeacher();
      };

      function isToday(date) {
        let today = new Date();

        return today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear();
      }

      $scope.formatTimestamp = function (timestamp) {
        if (!timestamp) {
          return '';
        }

        return $filter('date')(timestamp, isToday(timestamp) ? 'hh:mm a' : 'MM/dd/yyyy hh:mm a');
      };

      // {"client-wall-attachment-noedit": (postId !== xpPostId)}
      $scope.responseTextAreaFocusHandler = function (postId) // user started composing a reply
      {
        $scope.postId = null;
        $scope.editingResponseId = postId;
        $scope.topLevelPost = {};
        updateGroupEditing();

        $scope.focusPostId = null;
        $scope.replyPostId = null;
        $timeout(function () {
          $scope.focusPostId = postId;
        });
      };

      $scope.textAreaFocusHandler = function (postId) // user started composing a top level post
      {
        if ($scope.postId !== TOP_LEVEL_POST && $scope.editingResponseId !== TOP_LEVEL_POST) {
          $scope.postId = postId;
          $scope.editingResponseId = undefined;
          $scope.topLevelPost = {};

          updateGroupEditing();

          $scope.focusPostId = null;
          $timeout(function () {
            $scope.focusPostId = postId;
          });
        }
      };

      function updateGroupEditing() {
        if ($scope.postId === null || $scope.postId === TOP_LEVEL_POST) {
          $scope.groupEditingId = wallData.currentGroupId;
        } else {
          let post = wallData.findPostWithId($scope.postId);
          if (!post) {
            $scope.groupEditingId = 0;
          } else {
            $scope.groupEditingId = post.groupId;
          }
        }
      }

      function isReplyEditable(reply) {
        return $scope.isActive && wallData.isPostEditable(reply);
      }


      function cellForReply(reply) {
        let cell = {
          postId: reply.postId,
          userId: reply.userId,
          groupId: reply.groupId,
          userIsTeacher: $scope.getUserIsTeacher(reply.userId),
          isGroup: reply.groupId !== 0,
          isUser: reply.groupId === 0,
          userDisplayName: getPostRespondentDisplayName(reply),
          userEmail: $scope.getUserEmail(reply.userId),
          timestamp: reply.timestamp,
          text: reply.text,
          isEditable: isReplyEditable(reply),
          menuItems: getEditMenuItemsForReply(reply),
          isNew: reply.isNew
        };

        reply.isNew = false;

        return cell;
      }

      function getEditMenuItemsForReply(reply) {
        let menuOptions =
          [
            {
              text: '<div class="xp-element-menu-edit">Edit</div>',
              click: 'requestEditReply("' + reply.postId + '")'
            },
            {
              divider: true
            },
            {
              text: '<div class="xp-element-menu-delete">Delete</div>',
              click: 'requestDeleteReply("' + reply.postId + '")'
            }
          ];

        return menuOptions;
      }

      $scope.requestEditReply = function (postId) {
        replyTableCellDidRequestEditPost(postId);
      };

      $scope.requestDeleteReply = function (postId) {
        // same flow as for top-level posts
        requestDeleteOrUndeletePost(postId, false);
      };

      function replyTableCellDidRequestEditPost(postId) {
        let post = wallData.findPostWithId(postId);
        if (!post) {
          return;
        }

        $scope.postId = null;
        $scope.replyPostId = postId;
        $scope.editingResponseId = post.replyTo;
        updateGroupEditing();

        $scope.focusPostId = null;
        $scope.forms[post.replyTo] = post.text;
        $timeout(function () {
          $scope.focusPostId = postId;
        });
      }

      function updateEditedReply(originalPost, text) {
        let userIdForSave = originalPost.userId;
        let groupIdForSave = originalPost.groupId;
        let postsToSave = wallData.updatePost(originalPost, text, null, null, null);

        saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave);
      }

      $scope.cancelReply = function (postId) {
        $scope.focusPostId = null;
        $scope.editingResponseId = null;
        $scope.forms[postId] = undefined;
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }
      };

      function saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave) {
        let timestampForLastSeen;
        if (userIdForSave == context.userId) {
          // if we are saving an edit to our own posts, then the changes should be displayed immediately (but highlighted as new)
          // NOTE that we don't have to check for wall not in view, nor gated (since this post would ungate it anyway).
          timestampForLastSeen = wallData.getModificationTime(postsToSave, userIdForSave);
        } else {
          // if we are (a teacher) saving changes to another user's posts,
          // then we shouldn't update the lastseen timestamp here (only the student's app can
          // know whether the wall is in view and thus whether the changes will get displayed. The code uses
          // the fact that this timestamp is less than the modification timestamp of the post to figure out that
          // a user other than self has posted an edit)
          timestampForLastSeen = wallData.getTimestampForUserId(userIdForSave, groupIdForSave); // leave the timestamp unchanged
        }

        // Get the group.  Save post with the group if the sharing is NOT set to group or teacher
        let groupId = $scope.getUserGroup(userIdForSave);
        if ($scope.share == SHARE_MODE.GROUP || $scope.share == SHARE_MODE.TEACHER) {
          groupId = 0;
        }

        // If the share setting was adjusted by the teacher then this needs to be saved as part of the teachers post
        let customShare = null;
        if ($scope.initialShare != $scope.share && $scope.isTeacher) {
          customShare = $scope.share;
        }

        WallElementService.savePosts($scope.options.context.experienceId, $scope.options.element.id, userIdForSave, groupId, customShare, timestampForLastSeen, postsToSave,
          function (result) {
            if ($scope.changed) {
              $scope.changed({elementId: $scope.options.element.id, selection: null});
            }
          },
          function (error) {
            ElementsErrorService.error(error);
          });
      }

      $scope.replyToPost = function (postId) {
        if ($scope.editingResponseId != postId) {
          $log.debug('Trying to save response to post that is not being edited.');
          return; // We have an error, only post that is being edited should get saved.
        }

        if (!$scope.forms[postId] || $scope.forms[postId].length === 0) {
          $log.debug('Trying to save response with no text.');
          return;
        }

        // Check to make sure the text doesn't exceed maximum post length
        if (!postTextExceedsMaximum($scope.forms[postId])) {
          if (!$scope.replyPostId) {
            let postsToSave = wallData.addNewReplyToPostId(postId, $scope.forms[postId]);
            saveModifiedPosts(postsToSave, context.userId, shareFromGroupId);
          } else {
            let post = wallData.findPostWithId($scope.replyPostId);
            updateEditedReply(post, $scope.forms[postId]);
          }
          $scope.cancelReply(postId);
        }
      };

      function showUnseenPostsAfterSubmit() {
        if (delayedWallData !== null) {
          wallData = delayedWallData;
          delayedWallData = null;
        }

        wallData.revealUnseenPosts(new Date()); // updates lastSeen timestamp to Now
      }

      $scope.shouldShowUnseen = function () {
        return $scope.isActive && wallData.shouldShowUnseen();
      };

      function viewWillDisappear() {
        //update last seen, it needed, but no need to wait for response
        touchRemote();
      }

      //This is a ugly hack to update the saved timestamp for this element.
      //Simply "saving" our posts
      function touchRemote() {
        if (!context || context.getViewingInactiveExperience()) {
          return;
        } // don't change the data for a read-only experience

        let lastSeenTimestamp = wallData.lastSeen;
        let previouslySavedLastSeen = wallData.getTimestampForUserId(context.userId, shareFromGroupId);

        // if state has changed
        if (lastSeenTimestamp && lastSeenTimestamp.getTime() > 0 &&
          (!previouslySavedLastSeen || previouslySavedLastSeen.getTime() < lastSeenTimestamp.getTime()) &&
          ((wallData.myPosts() && wallData.myPosts().length > 0) || $scope.isTeacher)) {
          // If the share setting was adjusted by the teacher then this needs to be saved as part of the teachers post
          let customShare = null;
          if ($scope.initialShare != $scope.share && $scope.isTeacher) {
            customShare = $scope.share;
          }
          // Save the "touched" posts to as user_data
          WallElementService.savePosts($scope.options.context.experienceId, $scope.options.element.id, context.userId, shareFromGroupId, customShare, lastSeenTimestamp, wallData.myPosts(),
            function (result) {
            },
            function (error) {
              ElementsErrorService.error(error);
            });
        }
      }

      function hasVisibleStyle(element) {
        if (!(element instanceof Element)) {
          return true;
        }

        let styles = $window.getComputedStyle(element);
        if (styles.getPropertyValue('visibility') == 'hidden' || styles.getPropertyValue('display') == 'none') {
          return false;
        }

        if (element.parentNode) {
          return hasVisibleStyle(element.parentNode);
        }

        return true;
      }

      function isWallElementVisible() {
        return elementRectIsVisible && hasVisibleStyle($element[0]);
      }

      $scope.inViewHandler = function (inView) {
        elementRectIsVisible = inView;

        if (!isWallElementVisible()) {
          viewWillDisappear();
        }
      };

      function postTextExceedsMaximum(text) {
        if (text && (text.length > MAX_POST_LENGTH)) {
          ModalService.show(
            {
              title: 'Invalid Post',
              message: 'Post is too long. Please limit posts to under 2,500 characters (approximately 400 words).',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide()'
                }
              ]
            }
          );
          return true;
        } else {
          return false;
        }
      }

      function shareDriveDocs(driveDocs) {
        // if there are any google drive documents then they need to be shared based on wall settings
        if (driveDocs) {
          let fileIds = driveDocs.map(function (doc) {
            return doc.id;
          });
          let users = [];
          if (!context.userIsTeacher()) {
            users.push({email: context.getUserEmail(context.getTeacher()), role: 'writer'});
          }
          // if using small groups then this doc needs to be shared with write access to everyone in users small group
          let userSmallGroup = context.getUserGroup(context.userId);
          if ($scope.isUsingSmallGroups()) {
            let smallGroupEmails = context.getSelectedStudents().filter(function (student) {
              return student.google_user && student.uid !== context.userId && student.small_group === userSmallGroup;
            })
              .map(function (student) {
                return {email: student.email, role: 'writer'};
              });

            // Combine small group users with teacher
            users = smallGroupEmails.concat(users);
          }
          // Build up the list of users based on the share settings for this wall
          if (SHARE_MODE.isShareToGroup($scope.share)) {
            // this means the document needs to be shared to the entire class.  Filter out self and all non-google users
            let studentEmails = context.getSelectedStudents().filter(function (student) {
              return student.google_user && student.uid !== context.userId && (!$scope.isUsingSmallGroups() || userSmallGroup !== student.small_group);
            })
              .map(function (student) {
                return {email: student.email, role: 'reader'};
              });

            // Combination of all users who need permissions to this doc
            users = studentEmails.concat(users);
          }

          if (users && users.length) {
            GoogleDrive.share(fileIds, users)
              .then(function (results) {
                $log.info("Granted permissions to drive documents: " + JSON.stringify(results));
                // We need to determine how we should handle success/failure (talk to Itamar)
                // Should we only save the post if all the docs share correctly?  Should we warn the user?
              })
              .catch(function (error) {
                $log.error("Failed to grant google doc permission: " + JSON.stringify(error));
              });
          }
        }
      }

      $scope.didSubmit = function () {
        let postId = $scope.postId;
        let userIdForSave;
        let groupIdForSave;
        let postsToSave;
        let text = postId == TOP_LEVEL_POST ? $scope.topLevelPost.topLevelText : $scope.topLevelPost.text;
        let link = $scope.topLevelPost.link;
        let attachments = $scope.topLevelPost.attachments;
        let driveDocs = $scope.topLevelPost.drive;

        // Check to make sure the text doesn't exceed maximum post length
        if (!postTextExceedsMaximum(text)) {
          // Call google API to share selected documents correctly
          shareDriveDocs(driveDocs);

          if (!postId || !postId.length || postId == TOP_LEVEL_POST) {
            userIdForSave = context.userId;
            groupIdForSave = shareFromGroupId;

            if (delayedWallData) {
              postsToSave = delayedWallData.addNewPostWithText(text, link, attachments, driveDocs, userIdForSave);
            } else {
              postsToSave = wallData.addNewPostWithText(text, link, attachments, driveDocs, userIdForSave);
            }
          } else {
            let originalPost = wallData.findPostWithId(postId);
            if (originalPost === null) {
              $log.error("postId " + postId + " for edit menu request not found! This is an error condition!");
              return;  // an error condition that should never occur! (UI will not react to "Update" button).
            }

            userIdForSave = originalPost.userId;
            groupIdForSave = originalPost.groupId;

            if (delayedWallData) {
              postsToSave = delayedWallData.updatePost(originalPost, text, link, attachments, driveDocs);
            } else {
              postsToSave = wallData.updatePost(originalPost, text, link, attachments, driveDocs);
            }
          }

          $scope.cancelPost();
          saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave);
        }
      };

      $scope.cancelPost = function () {
        $scope.focusPostId = null;
        $scope.editingResponseId = null;
        $scope.forms[TOP_LEVEL_POST] = undefined;
        $scope.topLevelPost = {};
        $scope.postId = undefined;
        $scope.postEditView.editingLink = false;
        $scope.ckEditor = false;

        teacherEditable();
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }
      };

      function cellForPost(post) {
        let cell =
          {
            postId: post.postId,
            showUndeleteButton: shouldAllowUndeleteForPost(post),
            //isDeleted = post.isDeleted; // note: (isDeleted might be unused), isDeletedPlaceholder needs to be set before cell.userId
            isPrivate: wallData.isPostSharedWithTeacherOnly(post), // note: this needs to be set before cell.userId gets set.
            userId: post.userId,
            groupId: post.groupId,
            isGroup: post.groupId !== 0,
            isUser: post.groupId === 0,
            timestamp: post.lastModificationTimestamp(),
            userCanEdit: $scope.isActive && wallData.isPostEditable(post),
            isNew: post.isNew,
            displayUserName: getTextForUserDisplayName(post),
            userIsTeacher: $scope.getUserIsTeacher(post.userId),
            canReply: $scope.isActive,
            shareMode: post.shareMode
          };

        post.isNew = false;

        if (post.isDeleted || !wallData.isPostVisibleToCurrentUser(post)) {
          cell.isDeletedPlaceholder = true;  // display placeholder image in place of avatar thumbnail
          cell.text = "This post was removed or the share settings changed.";
          cell.link = null;
          cell.attachments = null;
          cell.replies = null;
          cell.driveDocs = null;
        } else {
          cell.isDeletedPlaceholder = false;  // display normally
          cell.text = post.text;
          cell.link = post.link;
          cell.attachments = post.attachments;
          cell.driveDocs = post.driveDocs;

          let replies = wallData.findRepliesToPostId(post.postId);
          let replyCells = [];

          if (replies) {
            replies.forEach(function (reply) {
              replyCells.push(cellForReply(reply));
            });
          }

          cell.replies = replyCells;
        }

        return cell;
      }

      function shouldAllowUndeleteForPost(post) {
        if (false /*GVS_UNDELETE*/) {
          let showUndoButton = wallData.isPostEditable(post) && post.isDeleted &&
            (context.userIsTeacher() || context.userId === post.modifiedByUserId);
          return showUndoButton;
        } else {
          return false;
        }
      }


      $scope.displayDeletedPlaceholder = function (post) {
        return post.isDeleted || !wallData.isPostVisibleToCurrentUser(post);
      };

      function getTextForUserDisplayName(post) {
        if ($scope.displayDeletedPlaceholder(post)) {
          return "updated";
        }

        let respondentName = getPostRespondentDisplayName(post);

        if (wallData.isPostSharedWithTeacherOnly(post)) {
          return respondentName + " to Teacher";
        }

        return respondentName;
      }

      function getPostRespondentDisplayName(post) {
        let respondentName = "";
        if (post.groupId && post.groupId > 0) {
          respondentName = "Small Group " + post.groupId;
        } else {
          respondentName = $scope.getUserDisplayName(post.userId);
        }

        return respondentName;
      }

      $scope.getEditMenuItemsForPost = function (post) {
        let menuOptions =
          [
            {
              text: '<div class="xp-element-menu-edit">Edit</div>',
              click: 'requestEdit("' + post.postId + '")'
            },
            {
              divider: true
            },
            {
              text: '<div class="xp-element-menu-delete">Delete</div>',
              click: 'requestDelete("' + post.postId + '")'
            }
          ];

        // this need to be fixed for small groups! #GROUPS
        if (context.userIsTeacher() &&
          post.userId != context.userId) // top-level post authored by teacher, students will always be able to see it
        {
          if (wallData.isPostSharedWithTeacherOnly(post)) {
            menuOptions.splice(1, 0,
              {
                text: '<div class="xp-element-menu-edit">Share <u>this post</u> with group</div>',
                click: 'postTableCellDidRequestToggleShareModeForPost("' + post.postId + '")'
              });
          } else {
            menuOptions.splice(1, 0,
              {
                text: '<div class="xp-element-menu-edit">Share with teacher only</div>',
                click: 'postTableCellDidRequestToggleShareModeForPost("' + post.postId + '")'
              });
          }
        }

        return menuOptions;
      };

      $scope.getEditMenuItemsForSharing = function () {
        let shareToGroup =
          [{
            text: '<div class="xp-element-menu-edit">Share <u>all</u> posts with group</div>',
            click: 'toggleShare()'
          }];
        let shareToTeacher =
          [{
            text: '<div class="xp-element-menu-edit">Share <u>all</u> posts to teacher only</div>',
            click: 'toggleShare()'
          }];

        if (SHARE_MODE.isShareToTeacher($scope.share)) {
          return shareToGroup;
        } else {
          return shareToTeacher;
        }
      };

      $scope.toggleShare = function () {
        let dlgMessage = "All posts from this wall will be hidden from the group and shared with the teacher only. Do you want to make this switch?";
        if (SHARE_MODE.isShareToTeacher($scope.share)) {
          dlgMessage = "All posts from this wall will be shared to group. Do you want to make this switch?";
        }

        ModalService.show({
          title: 'Change Share Setting',
          message: dlgMessage,
          buttons:
            [
              {
                title: 'Cancel',
                click: '$hide()'
              },
              {
                title: 'Switch',
                click: 'modifyShareSetting(); $hide();'
              }
            ],
          modifyShareSetting: function () {
            toggleShareModeForElement();
          }
        });
      };

      function toggleShareModeForElement() {
        // Correctly adjust the share setting based on the existing setting
        if ($scope.share === SHARE_MODE.GROUP) {
          $scope.share = SHARE_MODE.TEACHER;
        } else if ($scope.share === SHARE_MODE.TEACHER) {
          $scope.share = SHARE_MODE.GROUP;
        } else if ($scope.share === SHARE_MODE.SMALL_GROUP_TEACHER) {
          $scope.share = SHARE_MODE.SMALL_GROUP_GROUP;
        } else if ($scope.share === SHARE_MODE.SMALL_GROUP_GROUP) {
          $scope.share = SHARE_MODE.SMALL_GROUP_TEACHER;
        }

        // Adjust all the wall posts to
        toggleShareModeForAllPosts();
      }

      function toggleShareModeForAllPosts() {
        // Now save new share mode to teachers share.  Can default group to 0 (no group) since a teacher is never part of a group
        let postsForTeacher = wallData.postsForUserId(context.userId, 0).concat();
        saveModifiedPosts(postsForTeacher, context.userId, 0);

        // Ajust and save the share mode for every post in this wall except for teacher since that was already done.
        wallData.topLevelPosts.forEach(function (post) {
          if (post.userId !== context.userId) {
            let postsToSave = wallData.changeShareModeForPost(post, $scope.share);
            saveModifiedPosts(postsToSave, post.userId, post.groupId);
          }
        });

        teacherEditable();
      }

      $scope.requestEdit = function (postId) {
        let post = wallData.findPostWithId(postId);
        if (!post) {
          return;
        }

        $scope.postId = postId;

        // TODO: Look at this code more closely
        if (SHARE_MODE.isShareToTeacher($scope.share) && context.userIsTeacher() && post.userId == context.userId) {
          $scope.postEditView.editable = false; // dont allow teacher announcements for now.
        } else {
          $scope.postEditView.editable = $scope.isActive;
        }
        updateGroupEditing();
        $scope.focusPostId = null;

        $timeout(function () {
          $scope.topLevelPost =
            {
              text: post.text,
              link: post.link,
              attachments: post.attachments,
              drive: post.driveDocs
            };

          $scope.forms[$scope.editingResponseId] = undefined;
          $scope.editingResponseId = TOP_LEVEL_POST;

          $scope.postEditView.editingLink = (post.link && post.link.length > 0);
          $scope.focusPostId = postId;

          if (/<[a-z][\s\S]*>/i.test(post.text)) {
            $scope.ckEditor = true;
          }
        });
      };

      $scope.requestEditCached = function (postId, text) {
        let post = wallData.findPostWithId(postId);
        if (!post) {
          return;
        }

        $scope.postId = postId;

        // TODO: Look at this code more closely
        if (SHARE_MODE.isShareToTeacher($scope.share) && context.userIsTeacher() && post.userId == context.userId) {
          $scope.postEditView.editable = false; // dont allow teacher announcements for now.
        } else {
          $scope.postEditView.editable = $scope.isActive;
        }

        updateGroupEditing();
        $scope.focusPostId = null;

        $timeout(function () {
          $scope.topLevelPost =
            {
              text: text,
              link: post.link,
              attachments: post.attachments,
              drive: post.driveDocs
            };

          $scope.forms[$scope.editingResponseId] = undefined;
          $scope.editingResponseId = TOP_LEVEL_POST;

          $scope.postEditView.editingLink = (post.link && post.link.length > 0);
          $scope.focusPostId = postId;

          if (/<[a-z][\s\S]*>/i.test(text)) {
            $scope.ckEditor = true;
          }
        });
      };

      $scope.requestDelete = function (postId) {
        requestDeleteOrUndeletePost(postId, false);
      };

      $scope.postTableCellDidRequestToggleShareModeForPost = function (postId) {
        let originalPost = wallData.findPostWithId(postId);
        if (originalPost === null) {
          $log.error("postId " + postId + " for toggleShareMode request not found! This is an unexpected error condition!");
          return;  // an error condition that should never occur!
        }

        if (!wallData.isPostSharedWithTeacherOnly(originalPost)) // if we're about to make it private
        {   //newShareMode = @"teacher";
          // check to see if any replies will become hidden
          let replies = wallData.findRepliesToPostId(postId);

          if (replies.length) {
            let numReplies = 0;
            replies.forEach(function (reply) {
              if (reply.userId != context.userId && reply.userId != originalPost.userId) {
                ++numReplies;
              }
            });

            if (numReplies) {
              ModalService.show({
                title: 'Unshare post?',
                message: '' + numReplies + ' replies to this post will be hidden from all users.',
                buttons:
                  [
                    {
                      title: 'Make Private',
                      click: 'makePrivate(); $hide();'
                    },
                    {
                      title: 'Cancel',
                      click: '$hide()'
                    }
                  ],
                makePrivate: function () {
                  toggleShareModeForPost(originalPost);
                }
              });

              return;
            }
          }
        }
        toggleShareModeForPost(originalPost);
      };

      function toggleShareModeForPost(originalPost) {
        let newShareMode;
        if (originalPost.shareMode === SHARE_MODE.TEACHER) {
          newShareMode = SHARE_MODE.GROUP;
        } else if (originalPost.shareMode === SHARE_MODE.GROUP) {
          newShareMode = SHARE_MODE.TEACHER;
        } else if (originalPost.shareMode === SHARE_MODE.SMALL_GROUP_TEACHER) {
          newShareMode = SHARE_MODE.SMALL_GROUP_GROUP;
        } else if (originalPost.shareMode === SHARE_MODE.SMALL_GROUP_GROUP) {
          newShareMode = SHARE_MODE.SMALL_GROUP_TEACHER;
        }

        // if we have a new, valid share mode then change for this post
        if (newShareMode) {
          let postsToSave = wallData.changeShareModeForPost(originalPost, newShareMode);
          saveModifiedPosts(postsToSave, originalPost.userId, originalPost.groupId);
        }
      }

      function requestDeleteOrUndeletePost(postId, undelete) {
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }

        let post = wallData.findPostWithId(postId);
        if (undelete) {
          if (post.isDeleted && wallData.isPostEditable(post)) {
            deleteOrUndeletePost(post, false);
          }
          return;
        }
        let message;
        let replies = wallData.findRepliesToPostId(post.postId);
        if (replies) {
          let numReplies = replies.length;
          if (numReplies) {
            message = '' + numReplies + ' replies to this post will also be deleted.';
          }
        }

        ModalService.show(
          {
            title: 'Delete post?',
            message: message,
            buttons:
              [
                {
                  title: 'Delete',
                  click: 'deletePost(); $hide();'
                },
                {
                  title: 'Cancel',
                  click: '$hide()'
                }
              ],
            deletePost: function () {
              deleteOrUndeletePost(post, true);
            }
          }
        );
      }

      function deleteOrUndeletePost(originalPost, del) {
        if (del) {
          if (originalPost.isDeleted) {
            return;
          }
        } else {
          if (!originalPost.isDeleted) {
            return;
          }
        }
        let userIdForSave = originalPost.userId;
        let groupIdForSave = originalPost.groupId;

        let postsToSave = wallData.deleteOrUndeletePost(originalPost, del);
        saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave);
      }

      $scope.browseButtonClick = function () {
        let url = ($scope.topLevelPost.link && $scope.topLevelPost.link.length) ? $scope.topLevelPost.link : "about:home";
        $window.open(url, '_blank');
      };

      $scope.imageUploaded = function ($event) {
        $event.stopPropagation();
      };

      $scope.onPostAttachmentSelect = function (files) {
        let attachments = [];

        if (files) {
          files.forEach(function (file) {
            let xid = rfc4122.v4(); // generate uuid
            let attachment =
              {
                xid: xid,
                type: 'image',
                file: file
              };
            attachments.push(attachment);

            let reader = new FileReader();
            reader.onload = onLoadFile;
            reader.readAsDataURL(file);

            function onLoadFile(event) {
              $scope.$apply(function () {
                attachment.url = event.target.result;
              });
            }
          });
        }

        // This isn't very intuitive but necessary.  This forces the dropdown to close.  In order for the ng-file-select to work we need
        // to prevent the propogation of the click event which was leaving the menu open.  This closes the menu after the file selection is complete
        $timeout(function () {
          angular.element($window.document.body).triggerHandler('click');
        });

        $scope.topLevelPost.attachments = attachments;
      };

      let cameraModal;

      function closeModal() {
        if (cameraModal) {
          cameraModal.hide();
          cameraModal = undefined;
        }
      }

      $scope.onFormattedText = function () {
        $scope.ckEditor = !$scope.ckEditor;
      };

      $scope.onPicked = function (docs) {
        if (!$scope.topLevelPost.drive) {
          $scope.topLevelPost.drive = [];
        }
        docs.forEach(function (file, index) {
          $scope.topLevelPost.drive.push(file);
        });
      };

      $scope.onSelectInternal = function (files) {
        if (files.length > 0) {
          let file = files[0];
          loadImageWithOrientation(file, {
            maxWidth: imageMaxWidth,
            maxHeight: imageMaxHeight,
            contain: true
          }).then(function (file) {
            $scope.onPostAttachmentSelect([file]);
          });
        }
      };

      $scope.newGoogleDocButtonClick = function () {
        GoogleDrive.newGoogleDoc();
      };

      $scope.newGoogleSheetButtonClick = function () {
        GoogleDrive.newGoogleSheet();
      };

      $scope.newGooglePresButtonClick = function () {
        GoogleDrive.newGooglePresentation();
      };

      $scope.postViewDidClickCamera = function () {
        cameraModal = $modal(
          {
            backdrop: 'static',
            show: true,
            contentTemplate: 'clientWallPhotoModal.html',
            scope: $scope,
            prefixEvent: 'cameraModal'
          });
      };

      $scope.onCancelPhoto = function () {
        closeModal();
      };

      $scope.onSavePhoto = function (data) {
        let binary = atob(data.split(',')[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }

        let myblob = new Blob([new Uint8Array(array)], {type: 'image/png'});

        let attachments = [];

        let xid = rfc4122.v4(); // generate uuid
        let attachment =
          {
            xid: xid,
            type: 'image',
            file: myblob,
            url: data
          };
        attachments.push(attachment);

        $scope.topLevelPost.attachments = attachments;

        closeModal();
      };

      $scope.shouldAllowNewPosts = function () {
        if ($scope.userIsTeacher()) {
          return true;
        } // teacher can add unlimited posts
        return (
          (!$scope.maxEntriesPerUser || (wallData.myPostsCount < $scope.maxEntriesPerUser)) &&
          (!$scope.maxEntriesPerGroup || (wallData.allStudentPostsCount < $scope.maxEntriesPerGroup))
        );

//    return !$scope.maxEntriesPerUser || wallData.myPostsCount < $scope.maxEntriesPerUser;
      };

      $scope.shouldShowPostEditView = function (directivePostId) {
        return (!$scope.options.context.getViewingInactiveExperience() && ($scope.shouldAllowNewPosts() ||
          ($scope.postId && $scope.postId !== TOP_LEVEL_POST && directivePostId !== TOP_LEVEL_POST))); // postId is set while editing a top-level-post
      };

      function updatePortionResponded() {
        if ($scope.isUsingSmallGroups()) {
          $scope.portionResponded = wallData.respondentsCount / context.clazz.smallGroups;
        } else if (context.clazz.students.length) {
          $scope.portionResponded = wallData.respondentsCount / context.clazz.students.length;
        } else {
          $scope.portionResponded = 0;
        }
      }

      $scope.isUsingSmallGroups = function () {
        return SHARE_MODE.isUsingSmallGroups($scope.share);
      };
    }]);

})();
