'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$location', '$log', '$q', '$rootScope', 'User', 'UserDefaultInfo', 'PermissionConsts', 'userPermissions',
                        'CourseModalFactory', 'CourseFactory', 'ModalService', 'googleService', 'canvasService', 'schoologyService',
                        'UserCodeFactory', 'UserCourses', 'tourService'];

  function controller($scope, $location, $log, $q, $rootScope, User, UserDefaultInfo, PermissionConsts, userPermissions,
                      CourseModalFactory, CourseFactory, ModalService, googleService, canvasService, schoologyService,
                      UserCodeFactory, UserCourses, tourService) {

    var ctrl = this;
    ctrl.permissions = PermissionConsts;
    ctrl.addingClass = false;

    ctrl.canAddCourses = function() {
      return User.getSystemRole() == User.ROLE.TEACHER &&
              !userPermissions.hasPermission(ctrl.permissions.ui_disable_rostering);
    };

    function getGoogleClassroomCourses(user) {
      return googleService.getAvailableCourses(user);
    }

    function getCanvasCourses(user) {
      return canvasService.getAvailableCourses(user);
    }

    function getSchoologyCourses(user) {
      return schoologyService.getAvailableCourses(user);
    }

    function userIsTeacher() {
      return User.getSystemRole() == User.ROLE.TEACHER;
    }

    function userIsAdmin() {
      return userPermissions.hasPermission(PermissionConsts.subscription_admin) ||
        userPermissions.hasPermission(PermissionConsts.school_admin) ||
        userPermissions.hasPermission(PermissionConsts.district_admin);
    }

    ctrl.checkAddCourse = function () {
      if (userIsTeacher() && !userIsAdmin()) {
        UserCourses.getCourses().then(function (courses) {
          if (courses.length === 0) {
            UserDefaultInfo.getUserInfo().then(function (userInfo) {
              if (!userInfo.gg4l_user && userInfo.active_tour_topic_id === 0) {
                return ctrl.addCourse(true);
              }
            });
          }
        });
      }
    };

    ctrl.addCourse = function (isNewTeacher) {
      if (ctrl.addingClass) {
        return false;
      }

      $rootScope.$emit('new-class');

      ctrl.addingClass = true;
      return UserDefaultInfo.getUserInfo()
      .then(function (userInfo) {
        return $q.all([getGoogleClassroomCourses(userInfo), getCanvasCourses(userInfo), getSchoologyCourses(userInfo)])
        .then(function (classes) {
          ctrl.addingClass = false;

          if (!classes.length || classes.length !== 3) {
            return false;
          }

          var googleClasses = classes[0] || null;
          var canvasClasses = classes[1] || null;
          var schoologyClasses = classes[2] || null;

          // Possible the user logged in using a google or account but is not a google classroom user.
          userInfo.canvas_user = canvasClasses ? canvasClasses.isCanvasClassroomUser : false;
          userInfo.schoology_user = schoologyClasses ? schoologyClasses.isSchoologyClassroomUser : false;

          // When a canvas user has no classes take them directly to the add canvas class screen
          if (userInfo.canvas_user && isNewTeacher) {
            return addFromCanvas(canvasClasses);
          }

          tourService.gotoNextTourStep();

          // Call UI to add a new class
          CourseModalFactory.add(userInfo, googleClasses, canvasClasses, schoologyClasses).then(function (response) {
            var newClassType = response.classType;

            if (newClassType === 'google') {
              return addFromGoogleClassroom(googleClasses);
            } else if (newClassType === 'canvas') {
              return addFromCanvas(canvasClasses);
            } else if (newClassType === 'schoology') {
              return addFromSchoology(schoologyClasses);
            } else {
              return addExplorosCourse(response.name);
            }
          });
        });
      });
    };

    function addExplorosCourse(name) {
      var d = $q.defer();

      CourseModalFactory.courseDetails().then(function (courseDetailsData) {
        var params = {
          'teacher_user_id': User.getId(),
          'class_name': name,
          'students': [],
          'grade': courseDetailsData.grade,
          'subject': courseDetailsData.subject,
          'licenses': courseDetailsData.licenses
        };

        return CourseFactory.add(params);
      })
      .catch(function (params) {
        $log.error("failed to create class:", name);

        ModalService.show({
          message: params.data.error.message,
          backdrop: 'static',
          buttons: [
            {
              title: 'Ok',
              click: '$hide();'
            }
          ]
        });

        return d.reject();
      });

      return d.promise;
    }

    function getClassDetails(course) {
      return CourseModalFactory.courseDetails().then(function (courseDetailsData) {
        course.grade = courseDetailsData.grade;
        course.subject = courseDetailsData.subject;
        course.licenses = courseDetailsData.licenses;
        return course;
      });
    }

    function getUserCode() {
      return UserCodeFactory.get({}, {'id': User.getId()})
        .$promise.then(function (response) {
        return response.code;
      });
    }

    function importGoogleClassroomCourse(googleCourse) {
      var d = $q.defer();

      googleCourse.googlePostAssignments = true;

      var settings = {
        google: {
          classroom: googleCourse
        }
      };

      // do not copy additional files
      if (googleCourse && googleCourse.teacherFolder) {
        delete googleCourse.teacherFolder;
      }
      if (googleCourse && googleCourse.courseMaterialSets) {
        delete googleCourse.courseMaterialSets;
      }

      var section = googleCourse.section || '';
      if (section) {
        section = " | " + section;
      }

      var googleCourseId = googleCourse.id;
      var params = {
        lms_id: -1,
        teacherId: User.getId(),
        group_name: googleCourse.name + section,
        settings: angular.toJson(settings),
        grade: googleCourse.grade,
        subject: googleCourse.subject,
        licenses: googleCourse.licenses
      };

      getUserCode()
        .then(function (code) {
          params.teacherCode = code;
        })
        .then(function () {
          googleService.importCourse(googleCourseId, params)
            .$promise.then(function (response) {
            d.resolve(response);
          }, function (error) {
            d.reject(error);
          });
        })
        .catch(function (error) {
          return d.reject(error);
        });

      return d.promise;
    }

    function classAdded(course) {
      var remove;
      // if this returned an error
      if (course && course.error && course.error.type) {
        if (course.error.type === "class_size") {
          CourseFactory.maxStudentsMsg().then(function (msg) {
            var maxStudentAlert = xpAlert.error(msg, true);
            remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
              remove();
              maxStudentAlert();
            });
          });
        } else {
          var closeAlert = xpAlert.error("An error occurred adding this class: " + course.message, true);
          remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
            remove();
            closeAlert();
          });
        }
      } else {
        // Need to switch to the new class and display the success message.  After that, any switching should hide the message
        remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
          if (current &&
            current.$$route &&
            current.$$route.originalPath == '/class/:classId' &&
            current.pathParams &&
            current.pathParams.classId == course.cid) {
            remove();
            var postFix = course.addedStudents == 1 ? " student!" : " students!";
            if (course.failedStudents) {
              postFix = postFix + " " + course.failedStudents + " students couldn't be added.";
            }
            if (course.failedDomainStudents) {
              postFix = postFix + " " + course.failedDomainStudents + " students couldn't be added due to invalid e-mail domains.";
            }
            if (!course.sufficientSeats) {
              postFix = postFix + " Note that you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
            }
            var close = xpAlert.success("You successfully imported a Google Class with " + course.addedStudents + postFix, true);

            remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
              remove();
              close();
            });
          }
        });
      }
    }

    function handleAddClassroomError(response) {
      $log.error("error in handling classroom:", response);
      var d = $q.defer();

      var params = {
        content: ''
      };

      var errors = [
        {
          error: 'NotGoogleAppsUser',
          content: 'Unable to access Google Classroom. Are you part of a Google Apps for Education domain?'
        },
        {
          error: 'ClassroomDisabled',
          message: 'Unable to access Google Classroom. Are you a Google Classroom user?'
        },
        {
          error: 'ClassroomApiDisabled',
          message: 'Unable to access Google Classroom. It appears the Google Classroom API is not configured properly on the server.'
        },
        {
          error: 'NoRefreshToken',
          message: 'You need additional authorization for Google Classroom. Click here to proceed.'
        }
      ];

      if (response && response.data && response.data.error && response.data.error.errors) {
        var errorResponse = response.data.error;
        errorResponse.errors.forEach(function (err) {
          errors.forEach(function (error) {
            if (~err.message.indexOf(error.error)) {
              params.content += error.message + '<br>';
            }
          });
        });
      }

      if (response.data && response.data.redirect) {
        params.callback = function () {
          $window.location.href = response.data.redirect;
        };
      }

      if (params.content.length > 0) {
        showErrorAlert(params);
      }

      return d.promise;
    }

    function addFromGoogleClassroom(googleClasses) {
      return CourseModalFactory.addFromGoogleClassroom(googleClasses)
      .then(getClassDetails)
      .then(importGoogleClassroomCourse)
      .then(function (course) {
        return classAdded(course);
      })
      .catch(handleAddClassroomError);
    }

    function getCanvasCourseWrapped(canvasCourse) {
      var course = {
        course: canvasCourse
      };

      return $q.when(course);
    }


    function importCanvasCourse(canvasCourse) {
      canvasCourse.course.canvasPostAssignments = true;

      var settings = {
        canvas: {
          classroom: canvasCourse.course
        }
      };

      var params = {
        lms_id: canvasCourse.course.lms_id,
        section_id: canvasCourse.course.section_id,
        teacherId: User.getId(),
        group_name: canvasCourse.course.name,
        students: angular.toJson(canvasCourse.students),
        settings: angular.toJson(settings),
        grade: canvasCourse.grade,
        subject: canvasCourse.subject,
        licenses: canvasCourse.licenses
      };

      return canvasService.importCourse(canvasCourse.course.course_id, params);
    }

    function addFromCanvas(canvasClasses) {
      return CourseModalFactory.addFromCanvas(canvasClasses)
        .then(getCanvasCourseWrapped)
        .then(getClassDetails)
        .then(importCanvasCourse)
        .then(function (course) {
          return classAdded(course);
        })
        .catch(handleAddClassroomError);
    }

    function getSchoologyWrapped(schoologyCourse) {
      var course = {
        course: schoologyCourse
      };

      return $q.when(course);
    }

    function importSchoologyCourse(schoologyCourse) {
      schoologyCourse.course.schoologyPostAssignments = true;

      var settings = {
        schoology: {
          classroom: schoologyCourse.course
        }
      };

      var params = {
        lms_id: schoologyCourse.course.lms_id,
        section_id: schoologyCourse.course.section_id,
        teacherId: User.getId(),
        group_name: schoologyCourse.course.name,
        students: angular.toJson(schoologyCourse.students),
        settings: angular.toJson(settings),
        grade: schoologyCourse.grade,
        subject: schoologyCourse.subject,
        licenses: schoologyCourse.licenses
      };

      return schoologyService.importCourse(schoologyCourse.course.course_id, params);
    }

    function addFromSchoology(schoologyClasses) {
      return CourseModalFactory.addFromSchoology(schoologyClasses)
      .then(getSchoologyWrapped)
      .then(getClassDetails)
      .then(importSchoologyCourse)
      .then(function (course) {
        return classAdded(course);
      })
      .catch(handleAddClassroomError);
    }
  }

  module.component('newClass', {
    template: require('./newClass.jade'),
    controller: controller
  });

  module.component('noClass', {
    template: require('./noClass.jade'),
    controller: controller
  });

})();
