'use strict';

angular.module('client.services').factory('ExperienceFactory', function ($resource, ModalService, $timeout, loginService) {
   var url = '/experiences/:id/:dest';
   return $resource(url, {id: '@id'}, {
     edit: {method: 'GET', params: {id: '@id', dest: 'edit'}},
     get: {method: 'GET', params: {id: '@id'}, interceptor: {responseError: resourceErrorHandler}},
     students: {method: 'GET', params: {id: '@id', dest: 'class'}, interceptor: {responseError: resourceErrorHandler}},
     activity: {method: 'GET', params: {id: '@id', dest: 'activity'}, interceptor: {responseError: resourceErrorHandler}},
     class_activity_pack: {method: 'GET', params: {id: '@id', dest: 'class_activity_pack'}},
     pack: {method: 'GET', cache: true, params: {id: '@id', dest: 'pack'}},
     style: {method: 'GET', cache: true, params: {id: '@id', dest: 'style'}},
     responses: {method: 'GET', isArray: true, params: {id: '@id', dest: 'scores'}},
     allResponses: {method: 'GET', isArray: true, params: {id: '@id', dest: 'scores/all'}},
     setStartDate: {method: 'PUT', params: {id: '@id', starts_at: '@starts_at', dest: 'update_start_date'}},
     setEndDate: {method: 'PUT', params: {id: '@id', ends_at: '@ends_at', dest: 'update_end_date'}}
   });

   function resourceErrorHandler(response) {
     ModalService.show({
       message: 'Please log in with proper credentials to see this Ended Experience',
       buttons: [{
         title: 'Ok',
         click: 'refreshPage(); $hide();'
       }
       ],
       refreshPage: function () {
         $timeout(function () {
           return loginService.logout();
         });
       }
     });
   }
 });

angular.module('client.services').factory('StudentFeedbackFactory', function ($resource) {
  var url = '/experiences/:id/student_facing_feedback';
  return $resource(url, {id: '@id'}, {
    setStudentFacingFeedback: {method: 'POST', params: {id: '@id', enabled: '@enabled'}}
  });
});


angular.module('client.services').factory('ActiveExperience', function($q, $log, $route, $routeParams, ExperienceFactory,
                                                                        StudentFeedbackFactory, ExperienceRealtimeService,
                                                                        JSONStringUtility, userExperiencePermissions,
                                                                        standardsService, UserPresence, PresenceService,
                                                                        User){

  var experience = null;
  var experienceAssessmentStatus = [];
  var listenerConfigured = false;

  function getExperience(id, reload) {
    if (id && (!experience || id !== experience.id || reload)) {
      return ExperienceFactory.class_activity_pack({'id': id}).$promise
      .then(function (data) {
        experience = data;
        return UserPresence.present({id: User.getId(), experience_id: id})
        .$promise.then(function(presentStudents){
          presentStudents.forEach(function(presentStudent){
            var expStudent = experience.students.find(function(student){
              return presentStudent.user_id === student.user_id;
            });
            if (expStudent) {
              expStudent.isPresent = true;
            }
          });
          return userExperiencePermissions.setPermissions(id).then(function(expPermissions){
            registerNotificationListeners();
            return experience;
          });
        })
      })
      .catch(function (err) {
        $log.error("error in getting experience and presence: " + err);
        return experience;
      });
    }
    else {
      return $q.when(experience);
    }
  }

  function setEndDate(id, endDate) {
    return ExperienceFactory.setEndDate({'id': id, 'ends_at': endDate}).$promise
    .then(function(result){
      if (!result.error) {
        experience.ends_at = endDate;
      }
      return result;
    })
    .catch(function (err) {
      $log.error("error in set end date: " + err);
      return err;
    });
  }

  function setStartDate(id, startDate) {
    return ExperienceFactory.setStartDate({'id': id, 'starts_at': startDate}).$promise
    .then(function(result){
      if (!result.error) {
        experience.starts_at = startDate;
      }
      return result;
    })
    .catch(function (err) {
      $log.error("error in set start date: " + err);
      return err;
    });
  }

  function endExperience(id) {
    return setEndDate(id, new Date().toString());
  }

  function registerNotificationListeners() {
    if (!listenerConfigured) {
      // Create a handler for experience updates
      ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPUpdateExperienceNotification,
        updateExperienceNotificationHandler);

      // Handle presence notifications
      PresenceService.on(PresenceService.EVENTS.XPPresenceReceivedNotification,
        presenceChangedNotificationHandler);

      listenerConfigured = true;
    }

    function updateExperienceNotificationHandler (e) {
      var message = e.detail;
      // Check for matching experience notification
      var updatedExperience = message && message.record || {};
      if (!experience || !updatedExperience || parseInt('' + updatedExperience.id, 0) !== experience.id) {
        return;
      }

      // Force a reload from server for any experience change
      getExperience(updatedExperience.id, true);
    }

    function presenceChangedNotificationHandler (e) {
      var state = e.detail.record;
      var from = e.detail.from;

      $log.debug('Received presence update: ' + JSON.stringify(state));

      experience.students.forEach(function (student) {
        if ((parseInt(student.user_id, 10)) === (parseInt(from, 10))) {
          $log.debug('Updating present state of student #' + student.user_id + ' to ' + state.status);
          student.isPresent = (state.status === 'online');
        }
      });
    }
  }

  function getStyle(experienceId) {
    return ExperienceFactory.style({'id': experienceId}).$promise
    .then(function (style) {
      return style;
    })
    .catch(function (err) {
      $log.error("getStyle:", err);
      return null;
    });
  }

  function hasPermission(experienceId, permission) {
    return userExperiencePermissions.hasPermission(permission, experienceId);
  }

  function getResponses(experienceId) {
    return ExperienceFactory.responses({id: experienceId}).$promise
    .then(function (results) {
      return results;
    });
  }

  function getAllResponses(experienceId) {
    return ExperienceFactory.allResponses({id: experienceId}).$promise
    .then(function (results) {
      return results;
    });
  }

  // Gets all the standards and breakouts for the current experience template.
  function getStandardsByScene(experienceId) {
    return getExperience(experienceId, false)
    .then(function(experience){
      if (experience.standards) {
        return standardsService.sceneExpectations(experience.template_id, experience.standard_set_id).$promise
        .then(function (sceneStandards) {
          return sceneStandards.templateExpectations;
        });
      }
    });
  }

  // This code looks at the various assessment elements to determine if any values are actually assessed.
  function elementIsAssessed(element) {
    var isAssessed = false;
    var config;

    if (element.type === "choice" || element.type === "poll" || element.type === "quiz_choice") {
      config = JSONStringUtility.parse(element.config);
      config.attributes.forEach(function (attribute) {
        if (attribute.name === "options" && attribute.value && attribute.value.length) {
          attribute.value.forEach(function (choice) {
            if (choice.value.correct === "true" || choice.value.correct === true) {
              isAssessed = true;
            }
          });
        }
      });
    }
    else if (element.type === "drag_drop_image") {
      config = JSONStringUtility.parse(element.config);
      config.attributes.forEach(function (attribute) {
        if (attribute.name === "labels" && attribute.value && attribute.value.length) {
          attribute.value.forEach(function (choice) {
            if (choice.value.assessed === "true" || choice.value.assessed === true) {
              isAssessed = true;
            }
          });
        }
      });
    }
    else if (element.type === "drag_drop_text") {
      config = JSONStringUtility.parse(element.config);
      config.attributes.forEach(function (attribute) {
        if (attribute.name === "questions" && attribute.value && attribute.value.length) {
          attribute.value.forEach(function (choice) {
            // See if this choice has at least one assessed item
            var source = choice.value.text;
            var index = source.indexOf("___");
            while (index >= 0 && !isAssessed) {
              // Strip the characters off the front of the string
              source = source.substr(index + 3);

              // Find the next block
              index = source.indexOf("___");

              // See if the next set of characters is *** which indicates non-assessed
              if (index > 0) {
                var inlineAnswer = source.slice(0, index);
                if (inlineAnswer !== "***") {
                  isAssessed = true;
                }
                // Strip the characters off the front of the string
                source = source.substr(index + 3);
              }

              // Find the next block
              index = source.indexOf("___");
            }
          });
        }
      });
    }
    else if (element.type === "fill_in_the_blank" || element.type === "inline_choice" ||
            element.type === "hot_spot" || element.type === "hot_text" || element.type === "multi_part") {
      config = JSONStringUtility.parse(element.config);
      config.attributes.forEach(function (attribute) {
        if (attribute.name === "assessed" && (attribute.value === "true" || attribute.value === true)) {
          isAssessed = true;
        }
      });
    }

    // if this is not one of the assessement elements then return false;
    return isAssessed;
  }

  function checkForAssessmentElements(experienceId) {
    // See if this experience has any assessment elements (THIS SHOULD BE TURNED INTO A PROMISE AND SHOULD BE RETURNED DIRECTLY )
    return getExperience(experienceId).then( function (experience) {
      var hasAssessmentElements = false;
      // Loop through all the scenes and see if any assessment elements exist
      experience.activity.content.scenes.forEach(function (scene) {
        scene.clusters.forEach(function (cluster) {
          cluster.elements.forEach(function (element) {
            hasAssessmentElements = hasAssessmentElements || elementIsAssessed(element);
          })
        });
      });
      return hasAssessmentElements;
    })
    .catch(function (err) {
      $log.error("error in get activity:", err);
      return false;
    });
  }

  function getHasAssessedElements(experienceId) {
    var hasAssessmentElement = experienceAssessmentStatus[experienceId];
    if (!hasAssessmentElement) {
      return checkForAssessmentElements(experienceId).then(function(hasAssessmentElements) {
        experienceAssessmentStatus[experienceId] = {assessed: hasAssessmentElements};
        return hasAssessmentElements;
      });
    }
    else {
      return $q.when(hasAssessmentElement.assessed);
    }
  }

  function setStudentFacingFeedback(experienceId, enabled) {
    return StudentFeedbackFactory.setStudentFacingFeedback({
      id: experienceId,
      enabled: enabled ? 1 : 0
    })
    .$promise.then(function (result) {
      experience.student_facing_feedback = result.student_facing_feedback;
      return result;
    });
  }


  function compareByFirstName(student1, student2) {
    if (student1.first_name.toLowerCase() < student2.first_name.toLowerCase())
      return -1;
    if (student1.first_name.toLowerCase() > student2.first_name.toLowerCase())
      return 1;
    return 0;
  }

  function compareByName(student1, student2) {
    if (student1.last_name.toLowerCase() < student2.last_name.toLowerCase())
      return -1;
    if (student1.last_name.toLowerCase() > student2.last_name.toLowerCase())
      return 1;
    return compareByFirstName(student1, student2);
  }

  function sortStudentsByName(students) {
    students.sort(compareByName);
    return students;
  }

  function compareByGroup(student1, student2) {
    if (student1.small_group === student2.small_group)
      return compareByName(student1, student2);
    if (student1.small_group < student2.small_group)
      return -1;
    if (student1.small_group > student2.small_group)
      return 1;
    return 0;
  }

  function sortStudentsByGroup(students) {
    students.sort(compareByGroup);
    return students;
  }

  function compareByProgress(student1, student2) {
    if (student1.assignment_progress === student2.assignment_progress)
      return compareByName(student1, student2);
    if (student1.assignment_progress < student2.assignment_progress)
      return -1;
    if (student1.assignment_progress > student2.assignment_progress)
      return 1;
    return 0;
  }

  function sortStudentsByProgress(students) {
    students.sort(compareByProgress);
    return students;
  }

  var SORTBY = Object.freeze({
    "Name":1,
    "Group":2,
    "Progress":3
  })

  function sortStudents(students, sortBy) {
    if (sortBy == SORTBY.Name) {
      return sortStudentsByName(students);
    }
    else if (sortBy == SORTBY.Group) {
      return sortStudentsByGroup(students);
    }
    else if (sortBy == SORTBY.Progress) {
      return sortStudentsByProgress(students);
    }
    else {
      return students;
    }
  }

  return {
    getExperience: getExperience,
    currentExperience: function() { return experience; },
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    endExperience: endExperience,
    getStyle: getStyle,
    hasPermission: hasPermission,
    getResponses: getResponses,
    getAllResponses: getAllResponses,
    getStandardsByScene: getStandardsByScene,
    getHasAssessedElements: getHasAssessedElements,
    setStudentFacingFeedback: setStudentFacingFeedback,
    sortStudents: sortStudents,
    SORTBY: SORTBY
  };

});
