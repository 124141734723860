'use strict';

angular.module('client.services').factory('ExperienceList', function ($q, $resource, User, ActiveMode, $log) {

  var allExperiencesUrl = '/experiences/all/:status';
  var classUrl = '/experiences/classes/:class_id/:status';
  var subscriptionAdminUrl = '/admin/subscriptions/:subscription_id/experiences/:status';

  function getURL() {
    // Calculate the URL based on the active mode
    if (ActiveMode.isSubscriptionMode() || ActiveMode.isDistrictAdminMode()) {
      return subscriptionAdminUrl;
    }
    else if (ActiveMode.isClassMode()) {
      return classUrl;
    }
    else {
      return allExperiencesUrl;
    }
  }

  function getActive() {
    var url = getURL();
    return $resource(url,
      {
        class_id: '@class_id',
        subscription_id: '@subscription_id',
        school_id: '@school_id',
        status: 'active',
        limit: '@limit',
      });
  }

  function getActiveByFilter(filter) {
    var url = getURL();
    return $resource(url,
      {
        class_id: '@class_id',
        subscription_id: '@subscription_id',
        school_id: '@school_id',
        status: 'active'
      },
      {'post': {method: 'POST', isArray: true}}
      );
  }

  function getPending() {
    var url = getURL();
    return $resource(url,
      {
        class_id: '@class_id',
        subscription_id: '@subscription_id',
        school_id: '@school_id',
        status: 'pending'
      });
  }

  function getPast() {
    var url = getURL();
    return $resource(url,
      {
        class_id: '@class_id',
        subscription_id: '@subscription_id',
        school_id: '@school_id',
        status: 'past',
        limit: '@limit',
      });
  }

  function getPastByFilter() {
    var url = getURL();
    return $resource(url,
      {
        class_id: '@class_id',
        subscription_id: '@subscription_id',
        school_id: '@school_id',
        status: 'past',
        limit: '@limit',
      },
      {'post': {method: 'POST', isArray: true}}
    );
  }

  function getActiveAndPending(limit) {
    // create query for active experiences
    var activeExperiences = getActive().query(
      {
        'class_id': ActiveMode.currentClassId(),
        'subscription_id': ActiveMode.currentSubscriptionId(),
        'school_id': ActiveMode.currentSchoolId(),
        'limit': limit,
    });

    // create a query for pending experiences
    var pendingExperiences = getPending().query({
      'class_id': ActiveMode.currentClassId(),
      'subscription_id': ActiveMode.currentSubscriptionId(),
      'school_id': ActiveMode.currentSchoolId(),
    });

    // Wait until all resources have resolved their promises, then resolve this promise
    return $q.all([activeExperiences.$promise, pendingExperiences.$promise]);
  }

  function getActiveAndPendingByFilter(limit, filter) {
    // create query for active experiences
    var activeExperiences = getActiveByFilter().post(
      {
        'class_id': ActiveMode.currentClassId(),
        'subscription_id': ActiveMode.currentSubscriptionId(),
        'school_id': ActiveMode.currentSchoolId(),
        'filter': filter,
        'limit': limit,
      });

    // create a query for pending experiences
    var pendingExperiences = getPending().query({
      'class_id': ActiveMode.currentClassId(),
      'subscription_id': ActiveMode.currentSubscriptionId(),
      'school_id': ActiveMode.currentSchoolId(),
    });

    // Wait until all resources have resolved their promises, then resolve this promise
    return $q.all([activeExperiences.$promise, pendingExperiences.$promise]);
  }

  function getEnded(limit) {
    // create query for ended experiences
    var endedExperiences = getPast().query(
      {
        'class_id': ActiveMode.currentClassId(),
        'subscription_id': ActiveMode.currentSubscriptionId(),
        'school_id': ActiveMode.currentSchoolId(),
        'limit': limit,
      });

    // Wait for this resource call to resolve
    return $q.when(endedExperiences.$promise)
  }

  function getEndedByFilter(limit, filter) {
    // create query for ended experiences
    var endedExperiences = getPastByFilter().post(
      {
        'class_id': ActiveMode.currentClassId(),
        'subscription_id': ActiveMode.currentSubscriptionId(),
        'school_id': ActiveMode.currentSchoolId(),
        'limit': limit,
        'filter': filter
      });

    // Wait for this resource call to resolve
    return $q.when(endedExperiences.$promise)
  }

  return {
    getActiveAndPending: getActiveAndPending,
    getActiveAndPendingByFilter: getActiveAndPendingByFilter,
    getEnded: getEnded,
    getEndedByFilter: getEndedByFilter
  };
});
