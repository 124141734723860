'use strict';

/* Directives */

var module = angular.module('client.directives', []);

module.directive('xpGroup', function () {
  return {
    restrict: 'AE',
    scope: {
      groupId: '=',
      size: '=',
      hilite: '='
    },
// 		template: "<div class='xp-small-group-directive' style='width: {{size}}px; height: {{size}}px' > {{groupId}} </div>",
    link: function (scope, element, attrs) {
      var groupId;
      var groupClass;
      scope.$watch('groupId', function (value) {
        groupId = value;
        if (Number.isInteger(value)) {
          groupClass = 'small-group-indicator-' + (value - 1);
        } else {
          groupClass = 'small-group-indicator-9';
        }
        updateElement();
      });

      var size;
      scope.$watch('size', function (value) {
        size = value;
        updateElement();
      });

      var hilite = false;
      scope.$watch('hilite', function (value) {
        hilite = value;
        updateElement();
      });

      function updateElement() {
        if (size !== undefined && groupId !== undefined) {
          var text = "<div class='small-group-directive " + groupClass +
            (hilite === true ?
              " small-group-hilite" : "") + "' style='width: " + size + "px; height: " + size + "px; line-height: " + size + "px' > " + groupId + " </div>";
          element.html(text);
        }
      }
    }
  };
});

module.directive('xpUserIconOverlay', ['ThemeService', function (ThemeService) {
  var template =
    '<span ng-if="overlays" class="user-icon-overlay">' +
    '<icon ng-repeat="overlay in overlays track by $index" name="{{overlay.icon}}" icon-class="{{overlay.className}}" xp-dynamic/>' +
    '</span>';

  return {
    restrict: 'EA',
    scope: {
      overlays: '='
    },
    template: template
  };
}]);

module.directive('xpBindHtmlCompile', ['$compile', function ($compile) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      scope.$watch(function () {
        return scope.$eval(attrs.xpBindHtmlCompile);
      }, function (value) {
        // Incase value is a TrustedValueHolderType, sometimes it
        // needs to be explicitly called into a string in order to
        // get the HTML string.
        element.html(value && value.toString());
        // If scope is provided use it, otherwise use parent scope
        var compileScope = scope;
        if (attrs.bindHtmlScope) {
          compileScope = scope.$eval(attrs.xpBindHtmlCompile);
        }
        $compile(element.contents())(compileScope);
      });
    }
  };
}]);

module.directive("ngTouchstart", function () {
  return {
    controller: function ($scope, $element, $attrs) {
      $element.bind('touchstart', onTouchStart);

      function onTouchStart(event) {
      }
    }
  };
});

module.directive('scrollToTop', function () {
  return {
    restrict: 'A',
    link: function postLink(scope, elem, attrs) {
      scope.$watch(attrs.scrollToTop, function () {
        elem[0].scrollTop = 0;
      });
    }
  };
});

module.directive('scrollToTemplate', function () {
  return {
    restrict: 'A',
    link: function postLink(scope, elem, attrs) {
      attrs.$observe('scrollToTemplate', function (newValue) {
        var elmnt = document.getElementById(newValue);
        if (elmnt) {
          elmnt.scrollIntoView();
        }
      });
    }
  };
});

module.directive('camera', function (CameraService, $log) {
  var videoElement;
  var canvas;

  return {
    restrict: 'EA',
    replace: true,
    transclude: true,
    scope: {
      'xpImageMaxWidth': '=',
      'xpImageMaxHeight': '='
    },
    template: '<div class="camera-xn"><div class="camera-container"><video ng-hide="loading" class="camera" autoplay="" /></div><div ng-show="loading" class="client-camera-loading-container"><img src="resources/loading.gif" class="client-camera-loading-img"/></div><div ng-transclude></div></div>',
    link: function (scope, ele, attrs) {
      var w = scope.xpImageMaxWidth || 320,
        h = scope.xpImageMaxHeight || 320;

      scope.loading = true;

      if (!CameraService.hasUserMedia) {
        return;
      }
      videoElement = ele.find('video')[0];
      canvas = ele.find('canvas')[0];

      // We'll be placing our interaction inside of here
      // Inside the link function above
      // ==================================
      // If the stream works
      // ==================================
      var onSuccess = function (stream) {
        scope.loading = false;
        videoElement.srcObject = stream;

        ele.on('$destroy', function () {
          if (stream.getTracks && stream.getTracks()[0]) {
            stream.getTracks()[0].stop();
          } else {
            stream.stop();
          }
        });

        // Just to make sure it autoplays
        videoElement.play();

        scope.$digest();
      };

      // ==================================
      // If there is an error
      // ==================================
      var onFailure = function (err) {
        $log.error(err);
      };

      // ==================================
      // Make the request for the media
      // ==================================
      navigator.mediaDevices.getUserMedia({
        video: {
          mandatory: {
            maxHeight: h,
            maxWidth: w
          }
        },
        audio: false
      })
        .then(onSuccess)
        .catch(onFailure);

      scope.w = w;
      scope.h = h;
    },
    // Extend so we get a camera
    controller: function ($scope, $q, $timeout) {
      this.takeSnapshot = function () {
        var ctx = canvas.getContext('2d');
        var d = $q.defer();

        // Use the video's actual size unless it is larger than the max in either dimension.
        var targetWidth = videoElement.videoWidth || $scope.w;
        var targetHeight = videoElement.videoHeight || $scope.h;

        var scale = Math.min(
          $scope.w / targetWidth,
          $scope.h / targetHeight
        );

        if (scale < 1) {
          targetWidth = targetWidth * scale;
          targetHeight = targetHeight * scale;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        $timeout(function () {
          ctx.fillRect(0, 0, targetWidth, targetHeight);
          // This actually draws the video capture.
          ctx.drawImage(videoElement, 0, 0, targetWidth, targetHeight);
          d.resolve(canvas.toDataURL());
        }, 0);
        return d.promise;
      };
    }
  };
});

// Extend the above
module.directive('cameraControlSnapshot', function () {
  return {
    restrict: 'EA',
    require: '^camera', // Reference Above directive
    scope: true,
    template: '<a class="btn btn-primary btn-md" ng-click="takeSnapshot()">Take snapshot</a><div><canvas ng-show=false></canvas></div>',
    link: function (scope, ele, attrs, cameraCtrl) {
      scope.takeSnapshot = function () {
        cameraCtrl.takeSnapshot()
          .then(function (image) {
            if (scope.setImage) {
              scope.setImage(image);
            }
          });
      };
    }
  };
});

module.directive('xpCaptureImage', ['$parse', function ($parse) {

  return {
    restrict: 'E',
    template: require("../../views/partials/cameraUI.jade"),
    scope: {
      'xpImageMaxWidth': '=',
      'xpImageMaxHeight': '='
    },
    link: function (scope, element, attributes) {
      scope.showSave = !!attributes.save;
      scope.showCancel = !!attributes.cancel;
      scope.pictureTaken = false;

      scope.snapShotImage = !!attributes.snapShotImage ? attributes.snapShotImage : 'resources/profile-icon.png';

      // User wants to save
      scope.onSave = function () {
        var canvas = element.find('canvas')[0];
        var canvasData = canvas.toDataURL("image/png");
        $parse(attributes.save)(scope.$parent, {data: canvasData});
      };

      scope.onCancel = function () {
        $parse(attributes.cancel)(scope.$parent, {});
        return void (0);
      };

      scope.setImage = function (image) {
        var preview = element.find('img');
        preview.attr('src', image);
        scope.pictureTaken = true;
      };
    }
  };
}]);

module.directive('syncFocusWith', function ($timeout, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      focusValue: "=syncFocusWith"
    },
    link: function ($scope, $element, attrs) {
      $scope.$watch("focusValue", function (currentValue, previousValue) {
        if (currentValue === true && !previousValue) {
          $timeout(function () {
            $element[0].focus();
          }, 10);
        } else if (currentValue === false && previousValue) {
          //$element[0].blur();
        }
      });
    }
  };
});

module.directive('focusOn', function ($timeout) {
  return {
    restrict: 'A',
    link: function ($scope, $element, $attr) {
      var is_safari_on_ipad = /iPad.*AppleWebKit/i.test(navigator.userAgent);
      $scope.$watch($attr.focusOn, function (_focusVal) {
        $timeout(function () {
          if (!is_safari_on_ipad && _focusVal) {
            $element[0].focus();
            $element[0].select();
          }
        });
      });
    }
  };
});

module.directive('xpFileThumb', ['$window', function ($window) {
  var helper = {
    support: !!($window.FileReader && $window.CanvasRenderingContext2D),
    isFile: function (item) {
      return angular.isObject(item) && item instanceof $window.Blob;
    },
    isImage: function (file) {
      var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    }
  };

  return {
    restrict: 'A',
    template: '<canvas/>',
    scope: {
      file: '=',
      width: '=',
      height: '='
    },
    link: function (scope, element, attributes) {
      if (!helper.support) {
        return;
      }

      function updateImage() {
        if (!helper.isFile(scope.file)) {
          return;
        }
        if (!helper.isImage(scope.file)) {
          return;
        }

        var canvas = element.find('canvas');
        var reader = new FileReader();

        reader.onload = onLoadFile;
        reader.readAsDataURL(scope.file);

        function onLoadFile(event) {
          var img = new Image();
          img.onload = onLoadImage;
          img.src = event.target.result;
        }

        function onLoadImage() {
          var width = scope.width || this.width / this.height * scope.height;
          var height = scope.height || this.height / this.width * scope.width;
          canvas.attr({width: width, height: height});
          canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
        }
      }

      updateImage();
      scope.$watch('file', updateImage);
    }
  };
}]);

module.directive('xpPictureUploadButton', ['$parse', 'loadImageWithOrientation', 'ThemeService', function ($parse, loadImageWithOrientation, ThemeService) {

  return {
    restrict: 'E',
    template: '<svg class="icon icon-{{name}}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ng-style="iconStyle">' +
      ' <use xlink:href="" data-ng-href="{{iconpath}}#{{name}}" ng-cloak></use>' +
      '</svg>' +
      '<input class="picture-upload-file-browse" type="file" onclick="this.value=null" ng-file-select="onSelectInternal($files)" accept="image/*"/>',
    scope: {
      xpLoadImageOptions: '@'
    },
    compile: function (element, attrs) {
      if (!attrs.xpLoadImageOptions) {
        attrs.xpLoadImageOptions = "{}";
      }
      return link;
    }
  };

  function link(scope, element, attributes) {
    scope.iconpath = ThemeService.getIconPath();

    // The default file browser on safari/iPad enables selecting a photo or taking a picture with type of image.
    // Per request of Exploros, we are checking if this is running on the iPad and if so show the camera image instead of the normal upload button
    var is_safari_on_ipad = /iPad.*AppleWebKit/i.test(navigator.userAgent);
    if (is_safari_on_ipad) {
      scope.name = 'camera';
    } else {
      scope.name = 'ex-image';
    }

    element.addClass('picture-upload-button-wrapper');

    if (attributes['class']) {
      element.addClass(attributes['class']);
    }
    scope.onSelectInternal = function (files) {
      if (files.length > 0) {
        var file = files[0];
        var options = scope.$eval(attributes.xpLoadImageOptions);
        loadImageWithOrientation(file, options).then(function (file) {
          $parse(attributes.onSelect)(scope.$parent, {$files: [file]});
        });
      }
    };
  }
}]);


//
// Video Loader used in Packs
//
module.directive('videoLoader', function ($log) {
  return function (scope, element, attrs) {
    $log.debug(scope.url);
    scope.$watch("url", function (newValue, oldValue) { //watching the scope url value
      element[0].children[0].attributes[3].value = newValue; //set the URL on the src attribute
      element[0].load();
      element[0].play();
    }, true);
    scope.$watch("showFlag", function (newValue, oldValue) {
      if (!newValue) // if the showFlag is false, stop playing the video (modal was closed)
      {
        element[0].pause();
      }
    }, true);
  };
});


//
//Calendar Icon Directive
//
module.directive('triggerFocusOn', function ($timeout, $log) {
  return {
    link: function (scope, element, attrs) {
      element.bind('click', function () {
        $timeout(function () {
          var otherElement = document.querySelector('#' + attrs.triggerFocusOn);

          if (otherElement) {
            otherElement.focus();
          } else {
            $log.warn("Can't find element: " + attrs.triggerFocusOn);
          }
        });
      });
    }
  };
});

//
// Audio Play Directive
//
module.directive('triggerPlayAudio', function ($timeout, $log) {
  return {
    link: function (scope, element, attrs) {
      element.bind('click', function () {
        $timeout(function () {
          var audionElement = document.querySelector('#' + attrs.triggerPlayAudio);

          if (audionElement) {
            audionElement.play();
          } else {
            $log.warn("Can't find element: " + attrs.triggerFocusOn);
          }
        });
      });
    }
  };
});
//
//Popup for add student and group to experience
//
module.directive('xpAddStudentAndGroupToExperience', ['$parse', function ($parse) {

  return {
    restrict: 'E',
    template: require("../../views/partials/experienceAddStudent.jade"),
    scope: {
      students: '=',
      hasSmallGroups: '=',
      smallGroups: '=',
      defaultSmallGroup: '=',
      hasReadingGroups: '=',
      readingGroups: '=',
      defaultReadingLevel: '=',
      onSave: '&'
    },
    link: function (scope, element, attributes) {

      // Default the data based on values passed in
      scope.data = {
        reading_level: scope.defaultReadingLevel,
        small_gid: scope.defaultSmallGroup
      };

      scope.readingGroupIndex = function (index) {
        return 'ABCDEFGHIJ'[index];
      };

      scope.studentName = function (student) {
        return student.first_name + " " + student.last_name;
      };

      // User wants to save
      scope.onAddStudent = function () {
        if (scope.onSave) {
          scope.onSave({data: scope.data});
        }
        element.scope().$hide();
      };

      scope.onCancel = function (hide) {
        element.scope().$hide();
        return void (0);
      };
    }
  };
}]);

module.directive('autoGrow', ['$timeout', function ($timeout) {
  return function (scope, element, attr) {
    var update = function (event) {
      element.css('height', '1px');
      element.css('height', element[0].scrollHeight + 'px');
      if (element[0].scrollHeight === 0 && element[0].value.length) {
        element.css('height', '40px');
      }
    };

    scope.$watch(attr.autoGrow, function (_val) {
      $timeout(update);
    });

    $timeout(update);
  };
}]);

//Simple table display for responses
module.directive('responseTable', function () {
  return {
    restrict: 'E',
    template: require("../../views/partials/responseTable.jade"),
    scope: {
      tableData: '='
    },
    link: function (scope, element, attributes) {
      var tableData;
      if (angular.isDefined(attributes.tableData)) {
        tableData = attributes.tableData;
      }

      scope.getHiliteClass = function (groupIndex) {
        return "small-group-indicator-" + groupIndex;
      };
    }
  };

});

module.directive('icon', ['ThemeService', '$templateCache', function (ThemeService, $templateCache) {
  function getTemplate(dynamic) {
    var once = dynamic ? '' : '::';
    var template =
      '<svg class="icon icon-{{' + once + 'name}} {{' + once + 'iconClass}}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ng-style="' + once + 'iconStyle">' +
      ' <use xlink:href=""  data-ng-href="{{' + once + 'iconPath}}{{' + once + 'name}}" ng-cloak></use>' +
      '</svg>';

    return template;
  }

  $templateCache.put('iconDirective.tpl', getTemplate(false));
  $templateCache.put('iconDirectiveDynamic.tpl', getTemplate(true));

  return {
    restrict: 'EA',
    replace: true,
    scope: {
      padding: '@',
      height: '@',
      width: '@',
      name: '@',
      iconClass: '@'
    },
    templateUrl: function (element, attrs) {
      return 'xpDynamic' in attrs ? 'iconDirectiveDynamic.tpl' : 'iconDirective.tpl';
    },
    link: function (scope, element, attrs) {
      // Set the style for the icon depending on what scope variables were set
      var height = scope.height;
      var width = scope.width;
      var padding = scope.padding || 0;

      scope.iconStyle = {};

      if (padding) {
        scope.iconStyle.padding = padding + 'px';
      }
      if (height) {
        scope.iconStyle.height = height + 'px';
        // element.removeAttr('height');
      }
      if (width) {
        scope.iconStyle.width = width + 'px';
        // element.removeAttr('width');
      }

      // fix for iOS8 svg
      if (width && height) {
        var viewBox = '0 0 ' + (width - padding) + ' ' + (height - padding);
        element.attr('viewBox', viewBox);
      }

      scope.iconPath = ThemeService.getIconPath() + '#';
    }
  };
}]);

module.directive('xpExperienceLayout', function () {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    template: require("../../views/partials/experienceLayout.jade"),
    link: function (scope, element, attributes) {
    }
  };
});

module.directive('xpExperience', function () {
  return {
    restrict: 'EA',
    controller: ['$scope', '$rootScope', '$window', '$log', '$location', '$route', '$routeParams',
      'User', 'CourseFactory', 'ModalService', 'TeacherControlledEvent',
      'TeacherControlledRealtimeService', 'ExperienceNavigator', 'ActiveMode',
      '$q', 'JSONStringUtility', 'userPermissions', 'PermissionConsts', 'ActiveExperienceService', 'ActiveExperience',
      'TeacherStreamingService', 'StudentStreamingService', 'tourService',
      function ($scope, $rootScope, $window, $log, $location, $route, $routeParams,
                User, CourseFactory, ModalService, TeacherControlledEvent,
                TeacherControlledRealtimeService, ExperienceNavigator, ActiveMode,
                $q, JSONStringUtility, userPermissions, PermissionConsts, ActiveExperienceService, ActiveExperience,
                TeacherStreamingService, StudentStreamingService, tourService) {

        var onUnloadMessage;

        var messages = {
          guided: {
            leaving: 'You control the navigation of this experience. When you leave, your students will too.'
          }
        };

        // default the student facing feedback flag and has assessment flags
        if ($scope.experience && $scope.experience.id) {
          $scope.studentFacingFeedback = $scope.experience.student_facing_feedback;
        }

        $scope.hasAssessmentElements = false;

        // ============================================================================================
        // Guided Nav
        // ============================================================================================

        function forceCloseStudentGuidedExperiences() {
          // Send teacher control message to close the experiences for all students
          if (!($scope.experience || !$scope.experience.id)) {
            return;
          }

          TeacherControlledEvent.post({}, {
            'id': $scope.experience.id,
            'sceneId': 0,
            'teacher_user_id': User.getId(),
            'event': 'exit'
          });

          $scope.leaveActiveExperience(true);
        }

        function confirmLeaveGuidedExperience() {
          onUnloadMessage = messages.guided.leaving;
          ModalService.show({
            message: onUnloadMessage,
            backdrop: 'static',
            callback: forceCloseStudentGuidedExperiences,
            buttons: [
              {
                title: 'Stay',
                click: '$hide();'
              },
              {
                title: 'Leave',
                click: 'scope.callback(true); $hide();',
                class: 'btn btn-default'
              }
            ]
          });
        }

        function endExperienceNow(experienceId) {
          ActiveExperience.endExperience();
        }

        function updateTeacherNotificationHandler(e) {
          var msg = e.detail;
          var data = msg.record;
          var event = data.event;

          if (!$scope.experience || !$scope.experience.id || data.id != $scope.experience.id) {
            return;
          }

          switch (event) {
            case 'change':
              $scope.$emit('TeacherControlledEvent', data);
              break;
            case 'exit':
              $scope.leaveActiveExperience();
              break;
          }
        }

        $scope.hasGuidedNav = function () {
          return (($scope.guidedNavEnabled || ($scope.experience && $scope.experience.is_guided)) && !($scope.experience && $scope.experience.status == "INACTIVE"));
        };

        $scope.showStudentFacingFeedback = function () {
          return $scope.userIsTeacher() && (($scope.experienceHasAssessmentItems() && !userPermissions.hasPermission(PermissionConsts.ui_curriculum_hide_student_facing_feedback)) ||
            (userPermissions.hasPermission(PermissionConsts.ui_show_toggle_teacher_notes)));
        };

        $scope.showShareVideo = function () {
          return $scope.userIsTeacher() &&
            $scope.experience &&
            !$scope.isPastExperience() &&
            ActiveExperience.hasPermission($scope.experience.id, PermissionConsts.ui_streaming_video);
        };

        $scope.showResponsesDashboard = function () {
          return $scope.userIsTeacher();
        };

        $scope.experienceHasAssessmentItems = function () {
          return $scope.hasAssessmentElements;
        };

        $scope.toggleStudentFacingFeedback = function (enabled) {
          if (ActiveExperience.hasPermission($scope.experience.id, PermissionConsts.student_facing_feedback)) {
            ActiveExperience.setStudentFacingFeedback($scope.experience.id, enabled)
              .then(function (result) {
                $scope.studentFacingFeedback = result.student_facing_feedback;
                $log.debug("Set student facing feedback to : " + enabled);
              });
          } else {
            ModalService.show({
              feature: 'student_facing_feedback',
              template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
              backdrop: 'static'
            });
          }
        };

        $scope.toggleTeacherNotes = function (hideNotes) {
          if (ActiveExperience.currentExperience()) {
            ActiveExperience.currentExperience().hideTeacherNotes = hideNotes;
            $rootScope.$emit('teacher-notes');
          }
        };

        $scope.onShareVideo = function (shareEnabled) {
          if (shareEnabled) {
            TeacherStreamingService.startVideoSharing($scope.experience.id);
          } else {
            TeacherStreamingService.stopVideoSharing($scope.experience.id);
          }
        };

        // ============================================================================================
        // Footer navigation to Dashboard, activity and pack
        // ============================================================================================

        $scope.navigateToDashboard = function (experienceId) {
          // Don' allow navigation to the dashboard in a preview
          if (!$scope.isPreviewExperience()) {
            var path = "experience/" + experienceId + "/dashboard/";
            // If this is a teacher then get the last dashboard they were on.  default to progress dashboard
            if ($scope.userIsTeacher()) {
              path += ActiveExperienceService.getDashboard();
              $location.path(path);
            } else {
              // For a student, experiences to responses(score) dashboard
              path += "responses";
              $location.path(path);
            }
          }
        };

        $scope.navigateToActivity = function (experienceId) {
          var path = "experience/" + experienceId + "/activity/scene/0";
          $location.path(path);
        };

        $scope.navigateToPack = function (experienceId, packtype) {
          if (!tourService.isTouring()) {
            var path = "experience/" + experienceId + "/pack/" + packtype;
            $location.path(path);
          }
        };

        $scope.isPreviewExperience = function () {
          return $scope.previewEnabled;
        };

        $scope.getSettingsDropdown = function () {
          var menuOptions = [];
          if ($scope.experienceHasAssessmentItems() && !userPermissions.hasPermission(PermissionConsts.ui_curriculum_hide_student_facing_feedback)) {
            if ($scope.studentFacingFeedback) {
              menuOptions.push({
                text: '<div class="dashboard-settings">Hide Student Feedback</div>',
                click: 'toggleStudentFacingFeedback(false)'
              });
            } else {
              menuOptions.push({
                text: '<div class="dashboard-settings">Show Student Feedback</div>',
                click: 'toggleStudentFacingFeedback(true)'
              });
            }
          }

          if (userPermissions.hasPermission(PermissionConsts.ui_show_toggle_teacher_notes)) {
            if (ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideTeacherNotes) {
              menuOptions.push({
                text: '<div class="dashboard-settings">Hide Teacher Notes & Correct Answers</div>',
                click: 'toggleTeacherNotes(true)'
              });
            } else {
              menuOptions.push({
                text: '<div class="dashboard-settings">Show Teacher Notes & Correct Answers</div>',
                click: 'toggleTeacherNotes(false)'
              });
            }
          }

          return menuOptions;
        };

        $scope.experienceStatus = function () {
          var status = $scope.experience && $scope.experience.status || '';
          return status.toUpperCase();
        };

        $scope.isPastExperience = function () {
          return $scope.experience && $scope.experience.status == "INACTIVE";
        };

        // ============================================================================================
        // Leave Experience
        // ============================================================================================

        $scope.leaveActiveExperience = function (force) {
          if (!tourService.canNavigate('#leave_experience')) {
            return;
          }
          tourService.gotoNextTourStep();

          if ($scope.userIsTeacher() && $scope.hasGuidedNav() && !$scope.isPreviewExperience() &&
            $scope.experienceStatus() === 'ACTIVE' && !force) {
            confirmLeaveGuidedExperience();
            return;
          }

          if ($scope.isPreviewExperience()) {
            var experienceId = $routeParams.id;
            endExperienceNow(experienceId);
          }

          if ($scope.experience.return_url) {
            $window.location.href = $scope.experience.return_url;
            return;
          }

          var path = ExperienceNavigator.getBackDestination();
          if (path) {
            var query = ExperienceNavigator.getBackDestinationQuery();
            var origQuery = $location.search();
            ExperienceNavigator.setBackDestination(undefined);

            $location.path(path).search(angular.merge(origQuery, query));
            return;
          }

          if ($scope.experience.class_id) {
            path = '/class/' + $scope.experience.class_id;
            $location.path(path);
            return;
          }

          ActiveMode.navigateToDefaultView();
        };


        // ============================================================================================
        // Events
        // ============================================================================================

        var updateExperienceListener = $scope.$watchCollection("experience", function (newVal, oldVal) {
          if (newVal !== oldVal) {
            //Unbind after experience is retrieved
            updateExperienceListener();
          }

          // Check the experience for assessment elements
          if (newVal) {
            // See if the student facing feedback option is enabled
            $scope.studentFacingFeedback = $scope.experience.student_facing_feedback || false;
            $log.debug("Experience has student facing feedback? " + $scope.studentFacingFeedback);

            // if this is a teacher then check to see if there are any assessment elements
            if ($scope.userIsTeacher()) {
              // Does this experience have at least one assessment element
              ActiveExperience.getHasAssessedElements($scope.experience.id).then(function (hasAssesmentElements) {
                $scope.hasAssessmentElements = hasAssesmentElements;
                $log.debug("Experience has assessment elements? " + hasAssesmentElements);
              })
                .catch(function (error) {
                  $log.debug("Failed to get experience elemet assessment status: " + error);
                });
            }
          }
        });

        TeacherControlledRealtimeService.on(TeacherControlledRealtimeService.EVENTS.TeacherControlledEvent,
          updateTeacherNotificationHandler);

        $scope.$on('onBeforeUnload', function (e, args) {
          if (!$scope.hasGuidedNav() || $scope.userIsStudent()) {
            return;
          }

          onUnloadMessage = messages.guided.leaving;
          args.message = onUnloadMessage;
          e.preventDefault();
        });

        $scope.$on('onUnload', function () {
          if ($scope.userIsTeacher() && $scope.hasGuidedNav()) {
            forceCloseStudentGuidedExperiences();
          }
        });

        $scope.$on('$destroy', function () {
          TeacherControlledRealtimeService.removeListener(
            TeacherControlledRealtimeService.EVENTS.TeacherControlledEvent, updateTeacherNotificationHandler);
          if (!$routeParams.id) {
            if ($scope.userIsTeacher()) {
              TeacherStreamingService.stopVideoSharing($scope.experience.id);
            } else {
              StudentStreamingService.stopStudentSharing($scope.experience.id);
            }
          }
        });
      }]
  };
});

module.directive('scenePagination', function (uibPaginationDirective) {
  return angular.extend({}, uibPaginationDirective[0], {
    scope: {
      totalItems: '=',
      firstText: '@',
      previousText: '@',
      nextText: '@',
      lastText: '@',
      lastSceneIndex: '@',
      teacherNavEnabled: '&',
      isStudent: '&',
      isPastExperience: '&',
      canShowNextScene: '&'
    }
  });
});

module.directive('collectionListItem', function () {
  return {
    replace: true,
    restrict: 'E',
    template: require("../../views/partials/collectionListItem.jade")
  };
});

module.directive('courseListItem', function () {
  return {
    replace: true,
    restrict: 'E',
    template: require("../../views/partials/courseListItem.jade")
  };
});

module.directive('experienceSummaryHeader', function () {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      summary: '='
    },
    template: require("../../views/partials/experienceSummaryHeader.jade")
  };
});

module.directive('experienceListItemDetail', ['$location', 'ExperienceNavigator', 'ClassesService', 'ActiveMode',
  'PermissionConsts', 'ModalService',
  function ($location, ExperienceNavigator, ClassesService, ActiveMode, PermissionConsts, ModalService) {
    return {
      replace: true,
      restrict: 'E',
      scope: {
        experience: '=',
        displayExperienceSummaryInfo: '='
      },
      template: require("../../views/partials/experienceListItemDetail.jade"),
      controller: function ($scope, User, ExperienceStatusFactory) {
        $scope.isTeacher = User.getSystemRole() == User.ROLE.TEACHER;
        $scope.isAdmin = ActiveMode.isSubscriptionMode() || ActiveMode.isDistrictAdminMode();

        // Get the teacher and class name
        if (!$scope.isTeacher || $scope.isAdmin) {
          if ($scope.experience.teachers && $scope.experience.teachers.length) {
            $scope.teacher_class = $scope.experience.teachers[0].last_name +
              " " +
              $scope.experience.teachers[0].first_name +
              ", " +
              $scope.experience.class_name;
          }
        } else {
          $scope.teacher_class = $scope.experience.class_name;
        }

        $scope.showStandards = function () {
          return $scope.isTeacher;
        };

        $scope.isOwner = function (teachers) {
          var activeTeacher = teachers.find(function(teacher) {
            return teacher.id == User.getId();
          });
          return activeTeacher != null;
        };
        $scope.canViewAsAdmin = function (status, teachers) {
          var activeTeacher = teachers.find(function(teacher) {
            return teacher.id == User.getId();
          });
          if (activeTeacher) {
            return true;
          }
          return !!((ActiveMode.isSubscriptionMode() || ActiveMode.isDistrictAdminMode()) && status == 'INACTIVE');
        };
        $scope.isAvailableToView = function (status) {
          return (status !== 'PENDING');
        };

        $scope.navigateToExperience = function (path) {
          // Need to clean up the case where the path includes values that popup assign dialog for preview
          // TODO: The logic that shows the dialog automatically for preview really needs to be reworked so it doesn't conflict in other places
          var backPath = $location.path();
          if (ActiveMode.isClassMode()) {
            backPath = "/class/" + ActiveMode.currentClassId();
          }
          ExperienceNavigator.navigateToExperience(path, "", backPath);
        };

        $scope.calculateMaxStandards = function (experience) {
          var maxDisplayStandards = 13;
          if (experience.small_groups) {
            maxDisplayStandards = maxDisplayStandards - 2;
          }
          if (experience.reading_group_id && experience.reading_group_id > 0) {
            maxDisplayStandards = maxDisplayStandards - 2;
          }
          if (experience.is_guided) {
            maxDisplayStandards = maxDisplayStandards - 2;
          }
          if (experience.image_filename) {
            maxDisplayStandards = maxDisplayStandards - 2;
          }

          return maxDisplayStandards;
        };

        $scope.confirmEndExperienceNow = function (experienceId) {
          $scope.experienceModal = {
            id: experienceId,
            title: 'End this experience for you and your students?',
            buttons: [
              {
                title: 'Yes',
                type: 'button',
                role: 'endExperience',
                class: '',
                click: function () {
                  $scope.endExperienceNow(experienceId);
                  $scope.experienceModal = null;
                }
              },
              {
                title: 'No',
                type: 'button',
                role: 'cancelEndExperience',
                class: 'btn-plain',
                click: function () {
                  $scope.experienceModal = null;
                }
              }
            ]
          };
        };

        $scope.confirmReactivateExperience = function (experienceId, permissions) {
          if ((permissions || []).indexOf(PermissionConsts.ui_curriculum_reactivate_experiences) === -1) {
            ModalService.show({
              feature: 'ui_curriculum_reactivate_experiences',
              template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
              backdrop: 'static'
            });
            return;
          }

          $scope.experienceModal = {
            id: experienceId,
            title: 'Reactivate this experience to allow students to continue. It will be reactivated for 24 hours.',
            title2: 'To extend this timeframe, you can edit the End Time in the experience dashboard. Reactivate now?',
            buttons: [
              {
                title: 'Yes',
                type: 'button',
                role: 'reactivateExperience',
                class: '',
                click: function () {
                  $scope.reactivateExperience(experienceId);
                  $scope.experienceModal = null;
                }
              },
              {
                title: 'No',
                type: 'button',
                role: 'cancelReactivateExperience',
                class: 'btn-plain',
                click: function () {
                  $scope.experienceModal = null;
                }
              }
            ]
          };
        };

        $scope.reactivateExperience = function (experienceId) {
          var endDate = window.moment().add(1, 'day').toString();
          ExperienceStatusFactory.reactivate({
            'id': experienceId,
            'ends_at': endDate
          });
        };

        $scope.endExperienceNow = function (experienceId) {
          var endDate = new Date().toString();
          ExperienceStatusFactory.end({
            'id': experienceId,
            'ends_at': endDate
          });
        };
      }
    };
  }]);

module.component('experienceListItemProgress', {
  bindings: {
    progress: '='
  },
  template: require("../../views/partials/experienceListItemProgress.jade")
});

module.component('experienceListItemDate', {
  bindings: {
    startsAt: '=',
    endsAt: '=',
    progress: '='
  },
  controller: ['$rootScope', '$scope',
    function ($rootScope, $scope) {
      var $ctrl = this;

      $scope.$watch('$ctrl.progress', function () {
        $ctrl.start = $rootScope.dateFormatter($ctrl.startsAt);
        $ctrl.end = $rootScope.dateFormatter($ctrl.endsAt);
      }, true);
    }
  ],
  template: require("../../views/partials/experienceListItemDate.jade")
});

module.component('experienceListItemStudents', {
  bindings: {
    invitedStudents: '=',
    joinedStudents: '='
  },
  template: require("../../views/partials/experienceListItemStudents.jade")
});

module.directive('loading', ['$compile', function ($compile) {
  function link(scope, element) {
    var src = scope.loading;
    var template = "<ng-include ng-if='loading' src='\"partials/loading.jade\"'/>";
    var cTemplate = $compile(template)(scope);
    element.prepend(cTemplate);
  }

  return {
    restrict: 'AE',
    scope: {
      loading: '='
    },
    link: link
  };
}]);


module.directive('dotdotdot', ["$timeout", 'ModalService', function ($timeout, ModalService) {
  return {
    restrict: 'A',
    scope: {
      showReadMore: '=',
      showReadLess: '=',
      displayData: '=',
      readMoreTitle: '=',
      maxHeight: '=',
      startExpanded: '='
    },
    link: function (scope, element, attributes) {
      var minHeight = null;
      const maxDisplayHeight = 1000;

      // Initial element configuration
      scope.$evalAsync(function () {
        if (scope.startExpanded) {
          setElement(maxDisplayHeight);
        } else {
          setElement();
        }
      });

      // Update readmore status if the flag changes
      scope.$watch('showReadMore', function (value) {
        setElement();
      });

      scope.$watch('startExpanded', function (value) {
        if (value) {
          minHeight = scope.maxHeight;
        }
      });

      // Update text when the value changes
      scope.$watch('displayData', function (value) {
        if (scope.startExpanded) {
          setElement(maxDisplayHeight);
        } else {
          setElement();
        }
      });

      // Call dotdotdot logic with correct configuration paramaters
      function setElement(newHeight) {
        var options = {'watch': true};
        if (scope.showReadMore) {
          options.after = "<a class='dont-wrap primary-color-light small-font' role='button'>Read More</a>";
        }
        if (scope.showReadLess) {
          options.afterLess = "<span>&nbsp;&nbsp;</span><a class='dont-wrap primary-color-light small-font' role='button'>Read Less</a>";
        }
        if (newHeight) {
          options.height = newHeight;
        } else if (scope.maxHeight) {
          options.height = scope.maxHeight;
        }
        if (minHeight) {
          options.minHeight = minHeight;
        }
        $(element).dotdotdot(options);
      }

      // Handle the click on the anchor tag in a block of text with the dotdotdot directive.
      // There are two options within dotdotdot.  First the href is of read more which should open
      // a dialog with the full text.  Second is a link created via the ckeditor which should
      // launch a separate tab.  This can be complicated, however since any URL's that don't begin
      // with http[s] are considered by angular to be relative and automatcally are converted to
      // path names.  Its also necessary to prepend http[s] onto the beginning of any url missing
      // it in order for the window.open to work correctly.  The code below handles all situations.
      $(element).on('click', 'a', function (event) {
        event.stopImmediatePropagation();
        if (event.currentTarget.href) {
          var url = event.currentTarget.href;
          if (event.currentTarget.pathname.length > 1) {
            url = event.currentTarget.pathname.substring(1);
          }
          if (!/^http[s]?:\/\//.test(url)) {
            url = 'http://' + url;
          }
          window.open(url, '_blank');
          return false;
        } else {
          if (scope.showReadLess) {
            if (!minHeight) {
              minHeight = scope.maxHeight;
              setElement(maxDisplayHeight);
            } else {
              minHeight = null;
              setElement();
            }
          } else {
            ModalService.show({
              title: scope.readMoreTitle ? scope.readMoreTitle : "Read More...",
              message: scope.displayData
            });
          }
        }
      });
    }
  };
}]);

module.directive('reportSelector', ['$compile', function ($compile) {
  function link(scope, element, attrs) {
    scope.$watch('component', function (value) {
      if (value && value.length > 0) {
        var componentName = value ? value : null;
        componentName = componentName ? componentName : 'non-existent-report';
        element.html("<" + componentName + "/>");
        $compile(element.contents())(scope);
      }
    });
  }

  return {
    restrict: 'E',
    scope: {
      component: '='
    },
    link: link
  };
}]);

module.directive('nonExistentReport', function () {
  return {
    replace: true,
    restrict: 'E',
    template: require("../../views/partials/reports/nonExistentReport.jade")
  };
});

module.directive('xpEllipsisTooltip', function () {
  return {
    restrict: 'A',
    scope: {
      text: '=xpEllipsisTooltip'
    },
    link: function (scope, element, attributes) {
      if (scope.text) {
        element.bind('mouseenter', function () {
          var el = element[0];
          if (el.offsetWidth < el.scrollWidth && !element.attr('title')) {
            element.attr('title', scope.text);
          }
        });
      }
    }
  };
});

module.directive('selectOnClick', ['$window', function ($window) {
  return {
    link: function (scope, element) {
      element.on('click', function () {
        var selection = $window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(element[0]);
        selection.removeAllRanges();
        selection.addRange(range);
      });
    }
  };
}]);

const classicEditor = require('@ckeditor/ckeditor5-build-classic');

module.directive('ckeditor5', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      toolbar: '='
    },
    link: function (scope, element, attrs, ngModel) {
      const isTextarea = element[0].tagName.toLowerCase() === 'textarea';
      if (!isTextarea) {
        throw new Error('element is not a textarea');
      }

      var config = {toolbar: scope.toolbar, height: '200'};
      classicEditor.create(
        element[0],
        config
      ).then(function (instance) {
        const setData = () => {
          const data = instance.getData();
          const value = ngModel.$viewValue;

          if (data !== value) {
            instance.setData(value);
          }
        };

        const setModel = () => {
          const data = instance.getData();
          ngModel.$setViewValue(data);
          ngModel.$render();
        };

        scope.$watch(() => ngModel.$viewValue, setData);
        instance.model.document.on('change', setModel);
      });
    }
  };
});

module.directive('autogrow', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      // get possible minimum height style
      var minHeight = parseInt(window.getComputedStyle(element[0]).getPropertyValue("min-height")) || 0;

      element.on("input", function (evt) {
        element.css({
          height: 0
        });

        var contentHeight = this.scrollHeight;
        var borderHeight = this.offsetHeight;

        element.css({
          height: contentHeight + "px" // because we're using border-box
        });
      });

      // watch model changes from the outside to adjust height
      scope.$watch(attrs.ngModel, trigger);

      // set initial size
      trigger();

      function trigger() {
        setTimeout(element.triggerHandler.bind(element, "input"), 100);
      }
    }
  };
});
