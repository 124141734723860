module.exports = {
  port : 7777,
  newrelic: false,
  realtime: {
//    url: 'wss://realtime-stage.exploros.com/realtime'
      url: 'wss://realtime-stage.exploros.com/realtime'
  },
  cookies : {
    token_cookie_name : 'stage_exploros_token',
    session_details_cookie_name : 'stage_session_details',
    domain : '.exploros.com',
    old_cookie_name : 'exploros_token'
  }
};

