'use strict';

angular.module('client.services').factory('ReportingSchoolYearsService', function () {
  return {
    get: function () {
      return [
        {name: '2023 - 2024 current', value: 2023},
        {name: '2023 - 2024 with archived', value: 2023, archived: true},
        {name: '2022 - 2023', value: 2022, archived: true},
        {name: '2021 - 2022', value: 2021, archived: true},
        {name: '2020 - 2021', value: 2020, archived: true},
        {name: '2019 - 2020', value: 2019, archived: true},
        {name: '2018 - 2019', value: 2018, archived: true},
        {name: '2017 - 2018', value: 2017, archived: true}
      ];
    }
  }
});
