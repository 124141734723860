'use strict';

angular.module('client.services').factory('QuizStateService', ['$resource',
                            function ($resource) {
  return {
    getUserQuizElementState: function(experienceId, userId) {
      return $resource('experiences/:experience_id/users/:user_id/state/quiz', {}, {
        getState: {method: 'POST', isArray: true, params: {experience_id: experienceId, user_id: userId}}
      });
    },
    getQuizElementState: function(experienceId) {
      return $resource('experiences/:experience_id/state/quiz', {}, {
        getState: {method: 'POST', isArray: true, params: {experience_id: experienceId}}
      });
    },
    quizUserState: function(experienceId, quizId, userId) {
      return $resource('experiences/:id/quizzes/:qid/users/:uid/state', {}, {
        saveState: {method: 'POST', params: {id: experienceId, qid: quizId, uid: userId}},
        getState: {method: 'GET', params: {id: experienceId, qid: quizId, uid: userId}},
        retake: {method: 'POST', params: {id: experienceId, qid: quizId, uid: userId}},
      });
    },
    quizState: function(experienceId, quizId) {
      return $resource('experiences/:id/quizzes/:qid/state', {}, {
        getState: {method: 'GET', isArray: true, params: {id: experienceId, qid: quizId}}
      });
    },
    quizActivate: function(experienceId, quizId) {
      return $resource('experiences/:id/quizzes/:qid/reactivate', {}, {
        reactivate: {method: 'POST', params: {id: experienceId, qid: quizId}}
      });
    }
  }
}]);

