'use strict';

angular.module('client.services').factory('OrgCollectionService', ['$resource',
                            function ($resource) {
  return {
    getCollections: function() {
      return $resource('users/available_collections', {}, {
        getAvailableList: {method: 'GET', isArray: true}
      });
    },
    addCollection: function(uuid) {
      return $resource('users/enable-collection/:uuid', {}, {
        add: {method: 'POST', params: {uuid: uuid}}
      });
    },
    removeCollection: function(uuid) {
      return $resource('users/disable-collection/:uuid', {}, {
        remove: {method: 'POST', params: {uuid: uuid}}
      });
    }
  };
}]);

