'use strict';

angular.module('client.services').factory('googleService', ['$resource', '$q', '$location', 'CourseFactory', '$log', 'User',
  function ($resource, $q, $location, CourseFactory, $log, User) {

    var imsResource = $resource('/users/:id/ims', {id: '@id'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getIms(id) {
      return imsResource.get({id: id}).$promise;
    }

    var coursesResource = $resource('/ims/courses/list', {ims_id: '@ims_id'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getCourses(ims_id) {
      return coursesResource.get({ims_id: ims_id}).$promise;
    }

    var importResource = $resource('/ims/courses/import/:id', {id: '@id'},
      {
        'post': {method: 'POST'}
      });

    function importCourse(id, params) {
      return importResource.post({id: id}, params).$promise;
    }

    var syncResource = $resource('/ims/courses/sync', {ims_id: '@ims_id'},
      {
        'post': {method: 'POST'}
      });

    function syncCourse(ims_id, id) {
      return syncResource.post({ims_id: ims_id}, {cid: id}).$promise;
    }

    var assignmentResource = $resource('/ims/assignment/:experience_id', {
        experience_id: '@experience_id',
        ims_id: '@ims_id'
      },
      {
        'post': {method: 'POST'}
      });

    function postAssignment(ims_id, experience_id) {
      return assignmentResource.post({experience_id: experience_id, ims_id: ims_id}).$promise;
    }

    function getAvailableCourses(user) {
      var googleClasses = {
        courses: [],
        has_courses: false,
        lms_id: 0
      };

      // if this user is NOT a google user then immediately return an empty list
      if (!user.google_user) {
        return $q.when(googleClasses);
      }

      return getIms(user.id).then(function (response) {
        // If the current user is not google user then return empty list
        if (!response || !response.length) {
          return googleClasses;
        }

        var imsUser = response.find(function (res) {
          return res.int_id === -1;
        });
        if (!imsUser) {
          return googleClasses;
        }

        googleClasses.lms_id = imsUser.int_id;

        return getCourses(imsUser.int_id).then(function (googleCourses) {
          // If google does not return a valid list of classes then just resolve to an empty list
          if (!googleCourses || !googleCourses.length) {
            return googleClasses;
          }

          googleClasses.has_courses = true;

          // Now lookup this teachers existing classes so we can remove duplicates
          var params = {teacher_user_id: User.getId()};
          return CourseFactory.list(params).then(function (courses) {
            // Remove any google classes that have already been added
            googleClasses.courses = googleCourses.filter(function (googleCourse) {
              var imported = false;
              courses.forEach(function (course) {
                // Get the current classroom ID from the data google returned to us
                var id = 0;
                try {
                  var settings = angular.fromJson(course.settings) || {};
                  if (settings && settings.ims && settings.ims.ims_id === -1 && settings.ims.classroom) {
                    id = settings.ims.classroom;
                  }
                  // settings  "{\"ims\":{\"ims_id\":-1,\"classroom\":\"******61455\",\"googlePostAssignments\":true}}"
                  // if (settings && settings.google && settings.google.classroom && settings.google.classroom.id) {
                  //   id = settings.google.classroom.id;
                  // }
                }
                catch (e) {
                  $log.error(e.message);
                }
                // if either the name or ID match then consider it a matching class
                if (googleCourse.id === id || googleCourse.name === course.class_name) {
                  imported = true;
                }
              });
              return !imported;
            });
            return googleClasses;
          }, function (error) {
            $log.error("classroom API error:", error);
            $q.reject(error);
          });
        });
      })
        .catch(function (error) {
          $log.error("get classroom API error:", error);
          $q.resolve(googleClasses);
        });
    }

    return {
      importCourse: importCourse,
      syncCourse: syncCourse,
      postAssignment: postAssignment,
      getAvailableCourses: getAvailableCourses
    };
  }]);
