'use strict';

(function () {
  function abbreviateNumber(num) {
    if (num < 1000) {
      return '' + num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function getFieldName(masterIndex, masterUUID, baseField) {
    return 'ex_' + masterIndex + '_' + masterUUID + '_' + baseField;
  }

  function renderPercComplete(val) {
    return val + '%';
  }

  function renderAvgResp(val) {
    return val ? val : '-';
  }

  function renderQuizScore(val) {
    return val === false ? '-' : val + '%';
  }

  function getCustomElementBackgroundColor(columnName, value) {
    if (!columnName) {
      return;
    }
    var numValue = parseFloat(value);
    var percentage = 0;
    if (columnName.indexOf('number_of_students') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(160,223,253,' + (numValue / 25) + ')';
    }
    if (columnName.indexOf('average_responses') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(253, 180, 147,' + (numValue / 18) + ')';

    }
    if (columnName.indexOf('quiz') > -1) {
      if (isNaN(numValue)) {
        return '#ffffff';
      } else if (numValue > 49.5 && numValue < 50.5) {
        return '#ffffff';
      } else if (numValue < 50) {
        return 'rgba(230, 129, 114, ' + (1 - numValue * 0.02) + ')';
      } else if (numValue > 50) {
        return 'rgba(160, 223, 253, ' + (numValue * 0.02 - 1) + ')';
      }
    }
    if (columnName.indexOf('percentage_completed') > -1) {
      if (!(numValue)) {
        return '#ffffff';
      } else {
        return 'rgba(169, 169, 169, ' + numValue * 0.01 + ')';
      }
    }
    if (columnName.indexOf('time_on_task') > -1) {
      if (!numValue) {
        return '#ffffff';
      } else {
        // max value here is 60 minutes
        percentage = (numValue / (60/100));
        if (percentage > 99) {
          percentage = 100;
        }
        return 'rgba(245, 211, 40, ' + percentage * 0.01 + ')';
      }
    }
    return '';
  }

  function getTimeOnTask(timeInSeconds) {
    var value = Math.round((timeInSeconds || 0) / 60);
    return value ? abbreviateNumber(value) : '-';
  }

  function formatExperienceSummaryDataRow(row, masters) {
    var rec = {
      prettyName: row.first_name + ' ' + row.last_name,
      teacher_id: row.teacher_user_id,
      schoolName: row.school_name,
      class_id: row.class_id,
      className: row.class_name,
      archivedInfo: row.archived_info || false
    };
    masters.forEach(function (master, index) {
      if (row.experiences[master.master_uuid]) {
        rec[getFieldName(index, master.master_uuid, 'number_of_students')] = row.experiences[master.master_uuid].number_of_students;
        rec[getFieldName(index, master.master_uuid, 'number_of_students_sort')] = (parseFloat(row.experiences[master.master_uuid].number_of_students) || 0);
        rec[getFieldName(index, master.master_uuid, 'number_of_students') + '_bg'] = getCustomElementBackgroundColor('number_of_students', row.experiences[master.master_uuid].number_of_students);

        rec[getFieldName(index, master.master_uuid, 'quiz')] = renderQuizScore(row.experiences[master.master_uuid].quiz);
        rec[getFieldName(index, master.master_uuid, 'quiz_sort')] = (parseFloat(row.experiences[master.master_uuid].quiz) || 0);
        rec[getFieldName(index, master.master_uuid, 'quiz') + '_bg'] = getCustomElementBackgroundColor('quiz', row.experiences[master.master_uuid].quiz);

        rec[getFieldName(index, master.master_uuid, 'average_responses')] = renderAvgResp(row.experiences[master.master_uuid].average_responses);
        rec[getFieldName(index, master.master_uuid, 'average_responses_sort')] = (parseFloat(row.experiences[master.master_uuid].average_responses) || 0);
        rec[getFieldName(index, master.master_uuid, 'average_responses') + '_bg'] = getCustomElementBackgroundColor('average_responses', row.experiences[master.master_uuid].average_responses);

        rec[getFieldName(index, master.master_uuid, 'percentage_completed')] = renderPercComplete(row.experiences[master.master_uuid].percentage_completed);
        rec[getFieldName(index, master.master_uuid, 'percentage_completed_sort')] = (parseFloat(row.experiences[master.master_uuid].percentage_completed) || 0);
        rec[getFieldName(index, master.master_uuid, 'percentage_completed') + '_bg'] = getCustomElementBackgroundColor('percentage_completed', row.experiences[master.master_uuid].percentage_completed);
        rec[getFieldName(index, master.master_uuid, 'time_on_task')] = getTimeOnTask(row.experiences[master.master_uuid].time_on_task);
        rec[getFieldName(index, master.master_uuid, 'time_on_task_sort')] = row.experiences[master.master_uuid].time_on_task || 0;
        rec[getFieldName(index, master.master_uuid, 'time_on_task') + '_bg'] = getCustomElementBackgroundColor('time_on_task', getTimeOnTask(row.experiences[master.master_uuid].time_on_task));
      } else {
        rec[getFieldName(index, master.master_uuid, 'number_of_students')] = '';
        rec[getFieldName(index, master.master_uuid, 'number_of_students_sort')] = 0;
        rec[getFieldName(index, master.master_uuid, 'quiz')] = '';
        rec[getFieldName(index, master.master_uuid, 'quiz_sort')] = 0;
        rec[getFieldName(index, master.master_uuid, 'average_responses')] = '';
        rec[getFieldName(index, master.master_uuid, 'average_responses_sort')] = 0;
        rec[getFieldName(index, master.master_uuid, 'percentage_completed')] = '';
        rec[getFieldName(index, master.master_uuid, 'percentage_completed_sort')] = 0;
        rec[getFieldName(index, master.master_uuid, 'time_on_task')] = '';
        rec[getFieldName(index, master.master_uuid, 'time_on_task_sort')] = 0;
      }

    });
    return rec;
  }

  function formatCSVExperienceSummaryDataRow(row, masters, filter) {
    var rec = {
      prettyName: row.first_name + ' ' + row.last_name,
      schoolName: row.school_name,
      className: row.class_name,
    };
    masters.forEach(function (master, index) {
      if (row.experiences[master.master_uuid]) {
        if (filter === 'number_of_students') {
          rec['ex_' + master.master_uuid] = row.experiences[master.master_uuid].number_of_students;
        }
        if (filter === 'percentage_completed') {
          rec['ex_' + master.master_uuid] = renderPercComplete(row.experiences[master.master_uuid].percentage_completed);
        }
        if (filter === 'average_responses') {
          rec['ex_' + master.master_uuid] = renderAvgResp(row.experiences[master.master_uuid].average_responses);
        }
        if (filter === 'quiz') {
          rec['ex_' + master.master_uuid] = renderQuizScore(row.experiences[master.master_uuid].quiz);
        }
        if (filter === 'time_on_task') {
          rec['ex_' + master.master_uuid] = getTimeOnTask(row.experiences[master.master_uuid].time_on_task);
        }
      } else {
        rec['ex_' + master.master_uuid] = '';
      }
    });
    return rec;
  }

  var districtLevelReports = [
    'district-curriculum-progress'
  ];

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'ExperienceNavigator', 'NgTableParams', '$log', 'ReportingSchoolYearsService', 'ReportNavigation', 'reportToCSV'];

  function controller($location, ActiveMode, ExperienceNavigator, NgTableParams, $log, ReportingSchoolYearsService, ReportNavigation, reportToCSV) {

    var ctrl = this;

    var staticColumns = ['schoolName', 'prettyName', 'className'];

    function getFixedColumnStyle(colIndex) {
      if (ctrl.isDistrictReport()) {
        if (colIndex === 0) {
          return ' xp-fixed-report-column';
        }
        else if (colIndex === 1) {
          return ' xp-fixed-report-column-2';
        }
        else if (colIndex === 2) {
          return ' xp-fixed-report-column-3';
        }
      }
      else {
        if (colIndex === 1) {
          return ' xp-fixed-report-column';
        }
        else if (colIndex === 2) {
          return ' xp-fixed-report-column-2';
        }
      }
    }

    ctrl.getStyleClass = function (columnName, data) {
      if (!staticColumns || !columnName) {
        return;
      }
      var colIndex = staticColumns.indexOf(columnName);
      if (colIndex > -1) {
        return 'xp-report-medium-cell' + getFixedColumnStyle(colIndex);
      } else {
        return 'xp-report-small-box';
      }
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return columnTitle + ': ' + data;
      } else {
        return columnTitle;
      }
    };

    function getFilters() {
      var filter = {};
      var query = $location.search();
      filter.collection = query.collection;
      filter.metric = query.metric;
      filter.year = parseInt(query.year, 10);
      return filter;
    }

    ctrl.drillDownFilters = getFilters();

    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.currentYear = ctrl.filterYears[0].value;

    if (ctrl.drillDownFilters.year) {
      var year = ctrl.filterYears.find(function(yr) { return yr.value === ctrl.drillDownFilters.year; });
      if (year) {
        ctrl.year = year.value;
        ctrl.filterYear = year;
        ctrl.includeArchived = year.archived;
      }
    } else {
      ctrl.year = ctrl.filterYears[0].value;
      ctrl.filterYear = ctrl.filterYears[0];
      ctrl.includeArchived = false;
    }

    ctrl.reportData = [];
    ctrl.columns = [];
    ctrl.masterTemplates = [];
    ctrl.metrics = [
      {name: 'Average Quiz Score', id: 'quiz'},
      {name: 'Number of Participating Students', id: 'number_of_students'},
      {name: 'Average Responses Per Student', id: 'average_responses'},
      {name: 'Average Percent Complete', id: 'percentage_completed'},
      {name: 'Average Time on Task (min.)', id: 'time_on_task'},
    ];

    if (ctrl.drillDownFilters.metric) {
      var metric = ctrl.metrics.find(function(mt) { return mt.id === ctrl.drillDownFilters.metric; });
      if (metric) {
        ctrl.filterMetricName = metric.name;
        ctrl.filterMetric = metric.id;
      }
    } else {
      ctrl.filterMetricName = ctrl.metrics[0].name;
      ctrl.filterMetric = ctrl.metrics[0].id;
    }

    ctrl.setMetric = function setMetric(selection) {
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      setColumns(ctrl.masterTemplates, selection.id);
    };

    ctrl.subcollections = [];
    ctrl.filterSubcollectionName = '';

    if (ctrl.drillDownFilters.collection) {
      ctrl.nextFilteredSubCollection = ctrl.drillDownFilters.collection;
    } else {
      ctrl.nextFilteredSubCollection = false;
    }

    ctrl.setSubcollection = function setSubcollection(selection) {
      if (!selection || !selection.uuid) {
        ctrl.filterSubcollectionName = '';
        ctrl.nextFilteredSubCollection = false;
        return false;
      }
      ctrl.nextFilteredSubCollection = selection.uuid;
      return fetchAndUpdateReport();
    };

    ctrl.data = [];
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: {filterDate: "desc"}
      },
      {
        dataset: [],
        // options for page size
        counts: [],
      }
    );

    var allLevelsColumns = [
      {
        field: 'prettyName',
        title: 'Teacher',
        titleName: 'Teacher',
        filter: {prettyName: 'text'},
        sortable: 'prettyName',
        class: 'xp-report-medium-cell'
      },
      {
        field: 'className',
        title: 'Class',
        titleName: 'Class',
        filter: {className: 'text'},
        sortable: 'className',
        class: 'xp-report-medium-cell'
      },
    ];
    var districtLevelColumns = [
      {
        field: 'schoolName',
        title: "School",
        titleName: "School",
        filter: {schoolName: 'text'},
        sortable: 'schoolName',
        class: 'xp-report-medium-cell'
      },
    ];
    var defaultColumns = [];

    function setColumns(masterTemplates, filterColumn) {
      var columns = [];
      masterTemplates.forEach(function (master, index) {
        if (filterColumn === 'number_of_students') {
          columns.push({
            field: getFieldName(index, master.master_uuid, 'number_of_students'),
            titleName: master.name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-progress-experience-header',
            title: master.name,
            headerTitle: master.name,
            sortable: getFieldName(index, master.master_uuid, 'number_of_students_sort')
          });
        }
        if (filterColumn === 'percentage_completed') {
          columns.push({
            field: getFieldName(index, master.master_uuid, 'percentage_completed'),
            titleName: master.name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-progress-experience-header',
            title: master.name,
            headerTitle: master.name,
            sortable: getFieldName(index, master.master_uuid, 'percentage_completed_sort')
          });
        }
        if (filterColumn === 'average_responses') {
          columns.push({
            field: getFieldName(index, master.master_uuid, 'average_responses'),
            titleName: master.name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-progress-experience-header',
            title: master.name,
            headerTitle: master.name,
            sortable: getFieldName(index, master.master_uuid, 'average_responses_sort')
          });
        }
        if (filterColumn === 'quiz') {
          columns.push({
            field: getFieldName(index, master.master_uuid, 'quiz'),
            titleName: master.name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-progress-experience-header',
            title: master.name,
            headerTitle: master.name,
            sortable: getFieldName(index, master.master_uuid, 'quiz_sort')
          });
        }
        if (filterColumn === 'time_on_task') {
          columns.push({
            field: getFieldName(index, master.master_uuid, 'time_on_task'),
            titleName: master.name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-progress-experience-header',
            title: master.name,
            headerTitle: master.name,
            sortable: getFieldName(index, master.master_uuid, 'time_on_task_sort')
          });
        }
      });

      ctrl.columns = defaultColumns.concat(columns);
    }

    ctrl.isDistrictReport = function () {
      return ctrl.report_kind && districtLevelReports.indexOf(ctrl.report_kind) > -1;
    };

    ctrl.downloadCSV = reportToCSV(
        function () {
          var csvColumns = [];
          if (ctrl.isDistrictReport()) {
            csvColumns.push(
              {
                name: 'School',
                field: 'schoolName',
                wrap: true
              }
            );
          }
          csvColumns.push(
            {
              name: 'Teacher',
              field: 'prettyName',
              wrap: true
            }
          );
          csvColumns.push(
            {
              name: 'Class',
              field: 'className',
              wrap: true
            }
          );
          ctrl.masterTemplates.forEach(function(master) {
            csvColumns.push({
              name: master.name.replace(',', ''),
              field: 'ex_' + master.master_uuid,
              wrap: true
            });
          });

          return csvColumns;
        },
        function () {
          var csvRows = [];
          for (var i = 0; i < ctrl.reportData.records.length; i++) {
            csvRows.push(formatCSVExperienceSummaryDataRow(ctrl.reportData.records[i], ctrl.reportData.master_templates, ctrl.filterMetric));
          }
          return csvRows;
        }, function () {
          return (ctrl.parent.title || 'Class Progress') + ' ' + ctrl.filterMetricName + '.csv';
        },
        ','
      );

    function assignColumnStyles(defaultColumns) {
      defaultColumns[0].class += " xp-fixed-report-column";
      defaultColumns[1].class += " xp-fixed-report-column-2";
      if (defaultColumns.length >= 3) {
        defaultColumns[2].class += " xp-fixed-report-column-3";
      }
    }

    function renderReport(masterTemplates, filterColumn, data, reportKind) {
      ctrl.report_kind = reportKind;
      if (ctrl.isDistrictReport()) {
        defaultColumns = districtLevelColumns.concat(allLevelsColumns);
      } else {
        defaultColumns = allLevelsColumns;
      }
      assignColumnStyles(defaultColumns);
      setColumns(masterTemplates, filterColumn);
      ctrl.tableParams.settings({
        dataset: data
      });
    }

    function fetchAndUpdateReport() {
      var filter = {
        include_archived: ctrl.includeArchived,
        year: ctrl.year
      };
      if (ctrl.nextFilteredSubCollection) {
        filter.subcollection = ctrl.nextFilteredSubCollection;
      }
      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.subcollections = report.data.subcollections;
        ctrl.filterSubcollectionName = report.data.subcollection.name;
        ctrl.masterTemplates = report.data.master_templates;
        var newRecs = [];
        for (var i = 0; i < report.data.records.length; i++) {
          newRecs.push(formatExperienceSummaryDataRow(report.data.records[i], report.data.master_templates));
        }
        ctrl.data = newRecs;
        ctrl.reportData = report.data;
        renderReport(ctrl.masterTemplates, ctrl.filterMetric, ctrl.data, report.report_kind);
        return report;
      });
    }

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path());
    };

    ctrl.goToClassReport = function goToClassReport(teacherId, classId) {
      ReportNavigation.navigateToReport('curriculum-student-progress-all',
                                        '/experience/report/curriculum-student-progress-all',
        {
          teacher_id: teacherId,
          class_id: classId,
          metric: ctrl.filterMetric
        },
        $location.path(),
        {
          collection: ctrl.nextFilteredSubCollection,
          metric: ctrl.filterMetric,
          year: ctrl.year
        });
    };

    ctrl.inited = false;

    fetchAndUpdateReport()
      .then(function () {
        ctrl.inited = true;
      })
      .catch(function (error) {
        $log.error("error in updating report:", error);
        ctrl.inited = true;
      });
  }

  module.component('districtCurriculumProgress', {
    require: {parent: '^^xpReport'},
    template: require('./curriculumProgressReport.jade'),
    controller: controller,
  });

})();
