'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['ActiveMode', 'spireReportingService', 'spireReportingModels', 'reportToPDF', 'ClassesService', '$routeParams'];

function controller (ActiveMode, spireReportingService, spireReportingModels, reportToPDF, ClassesService, $routeParams) {
  var ctrl = this;

  ctrl.inited = false;
  ctrl.isPrinting = false;
  ctrl.data = {};

  ctrl.viewValues = spireReportingModels.viewValues;

  // See if there is a class specified on the URL as a parameter.  This is used for drill down to class data
  var filter = {};
  if ($routeParams.class) {
    filter.class_id = $routeParams.class;
  }

  ActiveMode.getReportData(filter).then(function(data) {
    parseReportData(data);
    ctrl.inited = true;
  });

  ctrl.onRefresh = function(student, level) {
    // Just set the current student in the controller
    if (student) {
      ctrl.data.student = student;
    }

    // Filter data by specified level
    if (level) {
      ctrl.data.level = level.id;
    }
  };

  ctrl.downloadPDF = function (){
    reportToPDF.createReportToPDF(ctrl)('printable', 'Student Summary Report', 'scrollingdiv', [1250, 1000], 'portrait');
  };


  ctrl.lastUpdate = function() {
    return window.moment().format('M/DD/YYYY');
  };

  function studentHasData() {
    return ctrl.models[ctrl.viewValues.placement].getStudentHasData(ctrl.data.student) ||
            ctrl.models[ctrl.viewValues.ca].getStudentHasData(ctrl.data.student, ctrl.data.level) ||
            ctrl.models[ctrl.viewValues.cmfd].getStudentHasData(ctrl.data.student, ctrl.data.level) ||
            ctrl.models[ctrl.viewValues.prepost].getStudentHasData(ctrl.data.student, ctrl.data.level) ||
            ctrl.models[ctrl.viewValues.mid].getStudentHasData(ctrl.data.student, ctrl.data.level);
  }

  function parseReportData(data) {
    var classes = spireReportingService.parseClassNames(data.students);
    var students = spireReportingService.parseStudents(data.students);
    var lessons = spireReportingService.parseToLevels(data.lessons, 'lesson');
    var concepts = spireReportingService.parseToLevels(data.concepts, 'concept');
    var levels = spireReportingService.getLevels(lessons);

    ctrl.models = {};
    ctrl.models[ctrl.viewValues.placement] = spireReportingModels.getPlacementTableModel(students, data.levels, data.placements);
    ctrl.models[ctrl.viewValues.ca] = spireReportingModels.getCAStudentDataTableModel(students, lessons, data.ca);
    ctrl.models[ctrl.viewValues.cmfd] = spireReportingModels.getCMFDStudentDataTableModel(students, concepts, data.cmfd);
    ctrl.models[ctrl.viewValues.prepost] = spireReportingModels.getPrePostTableModel(students, data.pre);
    ctrl.models[ctrl.viewValues.mid] = spireReportingModels.getMidTableModel(students, lessons, data.mid);

    ctrl.students = spireReportingModels.sortStudents(students);

    // Default to the first student in the list
    if (ctrl.students && ctrl.students.length) {
      if ($routeParams.student) {
        ctrl.data.student = ctrl.students.find(function(student) { return student.id === parseInt($routeParams.student, 10); })
      }
      else {
        ctrl.data.student = ctrl.students[0];
      }
    }

    // Initialize local levels reference.  Set default level to last (latest)
    ctrl.levels = levels;
    if (levels.length > 0 && !ctrl.data.level) {
      ctrl.data.level = ctrl.levels[ctrl.levels.length - 1];
    }

    // Create a list for filter display
    ctrl.filterLevels = [];
    levels.forEach(function(level) {
      var name = "Level " + level;
      ctrl.filterLevels.push({id: level, name: name });
    });

    // Set classes for use in filter
    if (classes && classes.length) {
      if (!ctrl.data.classId) {
        ctrl.data.classId = classes[0].id;
      }
    }
    else if (data.class_id) {
      ctrl.data.classId = data.class_id;
      ClassesService.getClassName(ctrl.data.classId).then(function(className) {
        ctrl.data.clasName = className;
      });
    }
  }

  ctrl.reportHasData = function() {
    return ctrl.students && ctrl.students.length;
  };

  ctrl.reportHasStudentData = function() {
    return ctrl.reportHasData() && studentHasData();
  };

}

module.component('xpSpireStudentSummary', {
  template: require('./spireStudentSummary.jade'),
  controller: controller,
});

})();
