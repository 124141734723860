'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$resource', '$log', '$location', '$scope', 'licensePackService', '$routeParams', 'User', 'PermissionConsts', 'CourseFactory'];

function controller($resource, $log, $location, $scope, licensePackService, $routeParams, User, PermissionConsts, CourseFactory) {
  var ctrl = this;

  ctrl.classId = $routeParams.classId;
  ctrl.activeSelection = 'library';

  var all = {id: -1, name: 'All'};
  ctrl.availableCourses = [all];
  ctrl.course = all;
  ctrl.availableUnits = [all];
  ctrl.filteredUnits = [all];
  ctrl.unit = all;

  ctrl.availableExperiences = [all];
  ctrl.filteredExperiences = [all];
  ctrl.experience = all;

  ctrl.searchText = "";
  ctrl.allLinks = [];
  ctrl.filteredLinks = [];
  ctrl.searchList = [];
  ctrl.licenseSubscriptionId = 0;
  ctrl.isTeacher = User.isTeacher();

  function filterCoursesBySummaries() {
    ctrl.availableCourses = ctrl.availableCourses.filter(function(course) {
      var courseSummary = ctrl.allLinks.find(function(summary) {
        return summary.courseId === course.id || course.id === all.id;
      });
      return courseSummary;
    });
  }

  function filterUnitsBySummaries() {
    ctrl.availableUnits = ctrl.availableUnits.filter(function(unit) {
      var courseSummary = ctrl.allLinks.find(function(summary) {
        return summary.unitId === unit.id || unit.id === all.id;
      });
      return courseSummary;
    });
  }

  licensePackService.getSummaries(ctrl.classId).then(function(collections) {
    collections.forEach(function(course) {
      ctrl.availableCourses.push({id: course.id, name: course.name});
      course.members.forEach(function(unit) {
        ctrl.availableUnits.push({id: unit.id, courseId: course.id, name: unit.name});
        if (unit.members && unit.members.length) {
          unit.members.forEach(function(template) {
            if (template.summaries && template.summaries.length) {
              ctrl.availableExperiences.push({id: template.id, unitId: unit.id, name: template.name});
              template.summaries.forEach(function(summary) {
                if (ctrl.isTeacher || summary.shared) {
                  var exists = ctrl.allLinks.find(function(saved) { return saved.id === summary.sid && saved.type === "summary"; });
                  if (!exists) {
                    ctrl.allLinks.push({
                      id: summary.sid,
                      type: "summary",
                      lsmid: summary.lsmid,
                      name: summary.title_link,
                      url: "https://exploros.com/summary/" + summary.page_token + "?license_pack=1",
                      courseId: course.id,
                      unitId: unit.id,
                      uuid: summary.uuid,
                      shared: summary.shared ? true : false,
                      favorite: summary.favorite ? true : false});
                  }
                }
              });
              if (template.videos && template.videos.length) {
                template.videos.forEach(function(video) {
                  if (ctrl.isTeacher || video.shared) {
                    var exists = ctrl.allLinks.find(function(saved) { return saved.id === video.sid && saved.type === "video"; });
                    if (!exists) {
                      ctrl.allLinks.push({
                        id: video.sid,
                        type: "video",
                        lsmid: video.lsmid,
                        name: video.title_link,
                        url: "https://exploros.com/video/" + video.page_token,
                        courseId: course.id,
                        unitId: unit.id,
                        uuid: video.uuid,
                        shared: video.shared ? true : false,
                        favorite: video.favorite ? true : false});
                    }
                  }
                });
              }
            }
          });
        }
      });
    });
    ctrl.filteredLinks = ctrl.allLinks;
    filterCoursesBySummaries();
    filterUnitsBySummaries();
  });

  CourseFactory.get(ctrl.classId).then(function (course) {
    if (course && course.licenses && course.licenses.length) {
      course.licenses.forEach(function(license) {
        if (license.permissions && license.permissions.length) {
          if (license.permissions.includes(PermissionConsts.ui_license_pack)) {
            ctrl.licenseSubscriptionId = license.subscription_id;
          }
        }
      });
    }
  });

  function validInView(summary) {
    if (ctrl.activeSelection == 'library') {
      return true;
    } else if (ctrl.activeSelection == 'favorites' && summary.favorite) {
      return true;
    } else if (ctrl.activeSelection == 'student' && summary.shared) {
      return true;
    }
    return false;
  }

  function filterSummaries(course, unit, experience) {
    if (course) {
      ctrl.filteredLinks = ctrl.allLinks.filter(function(summary) {
        return course.id === summary.courseId;
      });
    } else if (unit) {
      ctrl.filteredLinks = ctrl.allLinks.filter(function(summary) {
        return unit.id === summary.unitId;
      });
    } else if (experience) {
      ctrl.filteredLinks = ctrl.allLinks.filter(function(summary) {
        return experience.id === summary.uuid && validInView(summary);
      });
    } else {
      ctrl.filteredLinks = ctrl.allLinks.filter(function(summary) {
        return validInView(summary);
      });
    }
  }

  ctrl.onSelectCourse = function() {
    ctrl.unit = all;
    ctrl.filteredExperiences = [all];
    ctrl.experience = all;
    if (ctrl.course.id === -1) {
      ctrl.filteredUnits = [all];
      filterSummaries(null, null, null);
    } else {
      ctrl.filteredUnits = ctrl.availableUnits.filter(function(unit) {
        return ctrl.course.id === unit.courseId || unit.id === -1;
      });
      filterSummaries(ctrl.course, null, null);
    }
  };

  ctrl.onSelectUnit = function() {
    if (ctrl.unit.id === -1) {
      ctrl.filteredExperiences = [all];
      ctrl.experience = all;
      filterSummaries(null, null, null);
    } else {
      ctrl.experience = all;
      ctrl.filteredExperiences = ctrl.availableExperiences.filter(function(exp) {
        return ctrl.unit.id === exp.unitId || exp.id === -1;
      });
      filterSummaries(null, ctrl.unit, null);
    }
  };

  ctrl.onSelectExperience = function() {
    if (ctrl.experience.id != -1) {
      filterSummaries(null, null, ctrl.experience);
    } else if (ctrl.unit.id != -1) {
      filterSummaries(null, ctrl.unit, null);
    } else {
      filterSummaries(null, null, null);
    }
  };

  function saveSummaryState(summary) {
    var summaryState = {
        id: summary.lsmid,
        shared: summary.shared,
        favorite: summary.favorite,
        type: summary.type
    };
    licensePackService.saveSummaryState(ctrl.classId, summaryState, ctrl.licenseSubscriptionId);
  }

  ctrl.onShare = function(summary) {
    summary.shared = !summary.shared;
    if (summary.shared) {
      summary.favorite = true;
    }
    saveSummaryState(summary);
  };

  ctrl.onFavorite = function(summary) {
    summary.favorite = !summary.favorite;
    if (!summary.favorite) {
      summary.shared = false;
    }
    saveSummaryState(summary);
  };

  function reFilter() {
    if (ctrl.experience.id !== -1) {
      filterSummaries(null, null, ctrl.experience);
    } else if (ctrl.unit.id !== -1) {
      filterSummaries(null, ctrl.unit, null);
    } else if (ctrl.course.id !== -1) {
      filterSummaries(ctrl.course, null, null);
    } else {
      filterSummaries(null, null, null);
    }
  }

  ctrl.changeView = function(newView) {
    if (newView === 'library') {
      reFilter();
    } else {
      filterSummaries(null, null, null);
    }
    ctrl.searchText = "";
  };

  function findMatchingTemplates(searchText) {
    ctrl.filteredLinks = ctrl.allLinks.filter(function(summary) {
      return summary.name.toLowerCase().search(searchText) !== -1 && validInView(summary);
    });
  }

  ctrl.onSearchChange = function() {
    if (ctrl.searchText && ctrl.searchText.length > 2) {
      var searchText = ctrl.searchText.toLowerCase();
      findMatchingTemplates(searchText);
    } else {
      reFilter();
    }
  };

  function calculateTextWidth(text) {
    var f = '16px helvetica',
    o = $('<div></div>')
          .text(text)
          .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f})
          .appendTo($('body')),
    w = o.width();
    o.remove();
    return w;
  }

  ctrl.leftDropdownPosition = function() {
    var longestText = '';
    ctrl.filteredExperiences.forEach(function(exp) {
      if (exp.name.length > longestText.length) {
        longestText = exp.name;
      }
    });
    var textWidth = calculateTextWidth(longestText);
    if (textWidth < 450) {
      return "0px";
    } else {
      return 450 - textWidth + "px";
    }
  };

}

module.component('xpLicensePack', {
  template: require('./licensePack.jade'),
  controller: controller,
});

})();