'use strict';

(function () {
  var module = angular.module('client.components');

  quizController.$inject = ['$location', 'SHARE_MODE', 'ElementRealtimeService', '$scope', '$log', 'ActivityStateFactory',
    'RespondentType', 'JSONStringUtility', '$q', 'ActiveExperience', 'ModalService', 'QuizStateService',
    'PermissionConsts', 'ScoreService', '$timeout', '$window', 'ElementsRestService'];

  function quizController($location, SHARE_MODE, ElementRealtimeService, $scope, $log, ActivityStateFactory,
                          RespondentType, JSONStringUtility, $q, ActiveExperience, ModalService, QuizStateService,
                          PermissionConsts, ScoreService, $timeout, $window, ElementsRestService) {
    var ctrl = this;
    ctrl.loaded = false;
    ctrl.isTesting = false;
    ctrl.isTestComplete = false;
    ctrl.isRetake = false;
    ctrl.isStudent = true;
    ctrl.isVisible = false;
    ctrl.isPastExperience = false;
    ctrl.isTeacherPreview = false;
    ctrl.isPreviewExperience = false;
    ctrl.isTestInitialized = false;
    ctrl.share = SHARE_MODE.TEACHER;
    ctrl.countCompleted = 0;
    ctrl.students = [];
    ctrl.respondents = [];
    ctrl.studentResponses = [];
    ctrl.studentScores = {};
    ctrl.elementIds = [];
    ctrl.questionIndex = 0;
    ctrl.elementRealtimeService = ElementRealtimeService;
    ctrl.selectedRespondent = 0;
    ctrl.savingElementId = {elementId: 0, finished: false};
    ctrl.hasDashboardPermission = false;
    ctrl.isSaving = false;
    ctrl.lastQuestionChoiceSelected = false;
    ctrl.allowStudentToRetake = false;
    ctrl.retakeButtonText = "Show Retake";
    ctrl.studentsInRetake = [];
    ctrl.studentClickedQuiz = false;

    ctrl.$onInit = function () {
      initializeSelecteditem();
      parseCluster();
    }

    function parseElementConfig(element) {
      var attributes = {};
      var configuration = JSONStringUtility.parse(element.config);
      configuration.attributes.forEach(function (attribute) {
        attributes[attribute.name] = attribute.value;
      });
      return attributes;
    }

    function parseCluster() {
      // Initialize some state information
      ctrl.isPastExperience = ctrl.context.getViewingInactiveExperience();
      ctrl.userId = ctrl.context.userId;
      ctrl.isStudent = ctrl.context.userIsStudent(ctrl.userId);
      ctrl.quizId = ctrl.cluster.id;

      // Get the list of all students and sort them by lastname, firstname
      ctrl.students = ctrl.context.getSelectedStudents().filter(function (student) {
        return !ctrl.isStudent || ctrl.userId === student.uid;
      });

      // Parse the config sections of each element so they are usable as JSON
      ctrl.cluster.elements.forEach(function (element) {
        element.attributes = parseElementConfig(element);
      });

      // Keep track of all element ids in the cluster
      ctrl.elementIds = ctrl.cluster.elements.map(function (element) {
        return element.id;
      });

      // Need to keep track of the last element in the quiz
      ctrl.lastQuizElementId = ctrl.cluster.elements[ctrl.cluster.elements.length - 1].id;

      // Calculate the last page by dividing number of questions by the size of a page
      ctrl.lastPage = Math.ceil(ctrl.cluster.elements.length / ctrl.pageSize) - 1;

      // See if this experience contains display dashboard permissions
      ctrl.hasDashboardPermission = ActiveExperience.hasPermission(ctrl.context.experienceId,
        PermissionConsts.ui_curriculum_show_dashboard_responses);

      // Load individual student data or class data if this is a teacher
      if (ctrl.isStudent) {
        initStudentData();

        ctrl.elementRealtimeService.on(ctrl.elementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        $scope.$on('$destroy', function () {
          ctrl.elementRealtimeService.removeListener(ctrl.elementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        });
      } else {
        initTeacherData();

        ActiveExperience.getExperience(ctrl.context.experienceId).then(function(experience) {
          ctrl.isPreviewExperience = experience.preview;
        });

        // If this is a teacher then we need to capture element state changes
        ctrl.elementRealtimeService.on(ctrl.elementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
        ctrl.elementRealtimeService.on(ctrl.elementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        $scope.$on('$destroy', function () {
          ctrl.elementRealtimeService.removeListener(ctrl.elementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
          ctrl.elementRealtimeService.removeListener(ctrl.elementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        });
      }
    }

    function stateChangedNotificationHandler(e) {
      var state = e.detail;
      if (!state || !state.record) {
        return;
      }

      // Is the element in the notification an element in this quiz
      var curElemet = ctrl.cluster.elements.find(function (element) {
        return element.id === state.record.element_id;
      });
      if (!curElemet) {
        return;
      }

      var student = ctrl.studentResponses.find(function (student) {
        return student.studentId === state.record.user_id;
      });
      if (!student) {
        return;
      }

      var response = JSONStringUtility.parse(state.record.user_data);
      if (response && ((response.selection && response.selection.length) || (response.length))) {
        var studentElement = student.elements.find(function (stElement) {
          return stElement.elementId === state.record.element_id;
        });
        if (!studentElement) {
          student.elements.push({elementId: state.record.element_id});
        }
      } else {
        student.elements = student.elements.filter(function(stElement) {
          return stElement.elementId !== state.record.element_id;
        })
      }

      // First, Get the new score for this element/user if it is assessed
      ScoreService.get({
        element_id: state.record.element_id,
        experience_id: state.record.experience_id,
        user_id: state.record.user_id
      }).$promise.then(function (responses) {
        responses.forEach(function(response) {
          // Find the corresponding element and assign the score
          student.elements.forEach(function (studentResponse) {
            if (studentResponse.elementId === response.element_id) {
              studentResponse.score = response.score;
            }
          });
        });
      });

      $timeout(function() { updateQuizScores(state.record.experience_id, ctrl.quizId, state.record.user_id); }, 500);

      $log.info("Student %s progress in quiz %s of %s", student.studentId, ctrl.quizId, ctrl.context.experienceId);
    }

    function updateQuizScores(experienceId, quizId, userId) {
      // get the quiz state for all users in this quiz
      QuizStateService.quizUserState(experienceId, quizId, userId)
        .getState()
        .$promise.then(function (state) {
          if (state.status === 'finished') {
            ctrl.studentScores[state.user_id] = state.score;
          }
      })
      .catch(function (err) {
        $log.error("error in get quizState:", err);
      });
    }

    function quizStateChangedNotificationHandler(e) {
      var state = e.detail;
      if (!state || !state.record) {
        return;
      }

      if (state.record.quiz_id !== ctrl.quizId) {
        return;
      }

      if (state.record.experience_id !== ctrl.context.experienceId) {
        return;
      }

      var student = ctrl.studentResponses.find(function (student) {
        return student.studentId === state.record.user_id;
      });

      if (!student) {
        return;
      }

      $log.info("Student %s status in quiz %s of %s: ",
        student.studentId, ctrl.quizId, ctrl.context.experienceId, state.record.status);

      var arr = ctrl.respondents;
      if (state.record.status === 'finished') {
        arr.push(student.studentId);
      }

      var unique = arr.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });

      if (state.record.status === 'retake') {
        ctrl.isTestComplete = false;
        ctrl.isRetake = true;
        if (ctrl.isStudent) {
          $window.location.reload();
        } else {
          ctrl.studentResponses.forEach(function (student) {
            if (student.studentId === state.record.user_id) {
              student.elements = [];
            }
          });

          if (ctrl.studentScores.hasOwnProperty(state.record.user_id)) {
            delete ctrl.studentScores[state.record.user_id];
          }

          ctrl.studentsInRetake.push(state.record.user_id);
        }
      } else {
        ctrl.studentsInRetake = ctrl.studentsInRetake.filter(function(student) {
          return student !== state.record.user_id;
        });
      }

      ctrl.respondents = unique;
      ctrl.countCompleted = ctrl.respondents.length;
    }

    function initResponses() {
      ActiveExperience.getResponses(ctrl.context.experienceId).then(function (results) {
        // Loop over the results and add a new assessment for each student
        results.forEach(function (response) {
          // Look for the matching student response
          var studentResponse = ctrl.studentResponses.find(function (studentResponse) {
            return studentResponse.studentId === response.user_id;
          });

          // if valid then assign the score
          if (studentResponse) {
            // Find the corresponding element and assign the score
            studentResponse.elements.forEach(function (studentResponse) {
              if (studentResponse.elementId === response.element_id) {
                studentResponse.score = response.score;
              }
            });
          }
        });
      })
      .catch(function (err) {
        $log.error("error in get skill:", err);
      });
    }

    ctrl.getStudentScore = function () {
      var correct = 0;

      // Must be a student
      if (ctrl.isStudent) {
        // Should only really be one student here
        ctrl.studentResponses.forEach(function (response) {
          // Loop over all the elements getting score of each
          response.elements.forEach(function (elementResponse) {
            if (elementResponse && elementResponse.score && elementResponse.score.total === elementResponse.score.correct) {
              correct = correct + 1;
            }
          });
        });
      }

      return correct;
    }

    function initTeacherData() {
      // Clear an existing response data
      ctrl.studentResponses = [];
      ctrl.studentScores = [];

      ctrl.students.forEach(function (student) {
        ctrl.studentResponses.push({studentId: student.uid, elements: []});
      });

      // Get all the shared data for all the users and elements
      QuizStateService.getQuizElementState(ctrl.context.experienceId)
        .getState({elements: ctrl.elementIds, isPastExperience: ctrl.isPastExperience})
        .$promise.then(function (results) {
        // Filter out any records that exist but don't contain actual data
        var answers = results.filter(function (answer) {
          return answer.user_data;
        });

        // Keep track of how many answers each student submitted
        answers.forEach(function (answer) {
          ctrl.studentResponses.forEach(function (student) {
            var response = JSONStringUtility.parse(answer.user_data);
            if (student.studentId === answer.user_id && response &&
                ((response.selection && response.selection.length) || (response.length))) {
              student.elements.push({elementId: answer.element_id});
            }
          });
        });

        // get the quiz state for all users in this quiz
        QuizStateService.quizState(ctrl.context.experienceId, ctrl.quizId)
          .getState()
          .$promise.then(function (states) {
          states.forEach(function (state) {
            if (state.status === 'finished' || (state.status === 'retake' && ctrl.isPastExperience)) {
              if (!ctrl.respondents.includes(state.user_id)) {
                ctrl.respondents.push(state.user_id);
                ctrl.countCompleted = ctrl.countCompleted + 1;
              }
              ctrl.studentScores[state.user_id] = state.score;
            }
            if (state.status === 'retake') {
              if (!ctrl.studentsInRetake.includes(state.user_id)) {
                ctrl.studentsInRetake.push(state.user_id);
              }
            } else if (state.status === 'finished') {
              ctrl.studentsInRetake = ctrl.studentsInRetake.filter(function(student) {
                return student !== state.user_id;
              });
            }
          });
        })
        .catch(function (err) {
          $log.error("error in get quizState:", err);
        });

        // Finally, fill in the response data for past experiences
        if (ctrl.isPastExperience || (!ctrl.isStudent && !ctrl.isPreviewExperience)) {
          initResponses();
        }
      })
      .catch(function (err) {
        $log.error("error in get getQuizElementState:", err);
      });
    }

    function initStudentData() {
      // Get the quiz scores for this student
      QuizStateService.getUserQuizElementState(ctrl.context.experienceId, ctrl.userId)
        .getState({elements: ctrl.elementIds, isPastExperience: ctrl.isPastExperience})
        .$promise.then(function (results) {

        // Clear an existing response data
        ctrl.studentResponses = [];

        // Filter out any records that exist but don't contain actual data
        var answers = results.filter(function (answer) {
          return answer.user_data;
        });

        // Add this student so we save just responses for current user
        ctrl.studentResponses.push({studentId: ctrl.userId, elements: []});

        // Keep track of how many answers each student submitted
        answers.forEach(function (answer) {
          ctrl.studentResponses.forEach(function (student) {
            if (student.studentId === answer.user_id) {
              student.elements.push({elementId: answer.element_id});
            }
          });
        });

        // Check to see if the student finished the test
        checkTestComplete();

        // Finally, fill in the response data for scoring
        initResponses();
      })
        .catch(function (err) {
          $log.error("error in get initStudentData:", err);
        });
    }

    function checkTestComplete() {
      // Get the status for this quiz and user
      QuizStateService.quizUserState(ctrl.context.experienceId, ctrl.quizId, ctrl.userId)
        .getState()
        .$promise.then(function (results) {
        ctrl.isTestComplete = (results.status === 'finished');
        ctrl.isRetake = (results.status === 'retake');
        if (ctrl.isTestComplete || ctrl.isRetake) {
          ctrl.studentScore = Math.round(results.score);
        }
      })
        .catch(function (err) {
          $log.error("error in get checkTestComplete:", err);
        });
    }

    ctrl.toggleVisible = function () {
      if (!ctrl.isStudent && ctrl.isPreviewExperience) {
        ctrl.isTeacherPreview = true;
      }

      // A student cannot retake a test
      if ((ctrl.isStudent && (!ctrl.isTestComplete || ctrl.isRetake)) || (!ctrl.isStudent && ctrl.isPreviewExperience) || ctrl.isPastExperience) {
        ctrl.isVisible = !ctrl.isVisible;

        if (!ctrl.isPastExperience) {
          if (ctrl.isStudent || (!ctrl.isStudent && ctrl.isTeacherPreview)) {
            if (ctrl.isVisible) {
              $('.experience-navbar').addClass('hide');
              $('.content-with-nav-bar').addClass('expand-beyond-screen');
            } else {
              $('.experience-navbar').removeClass('hide');
              $('.content-with-nav-bar').removeClass('expand-beyond-screen');
            }
          }

          if (ctrl.isTestInitialized) {
            ctrl.showTest();
          }
        }
      }
    };

    function getCurrentQuestionIndex() {
      // Get all the shared data for the current student and elements
      return QuizStateService.quizUserState(ctrl.context.experienceId, ctrl.quizId, ctrl.userId)
        .getState()
        .$promise.then(function (result) {
          if (result.status !== 'retake') {
            return result.last_question_index || 0;
          } else {
            return 0;
          }
        })
        .catch(function (err) {
          $log.error("error in get getCurrentQuestionIndex:", err);
        });
    }

    ctrl.showTest = function () {
      // For a student we need to start at the question index they left off at or at the first unanswered
      if (ctrl.isStudent) {
        if (ctrl.studentClickedQuiz) {
          return;
        }
        ctrl.studentClickedQuiz = true;
        saveUserQuizState('started', -1).then(function(res) {
          ctrl.studentClickedQuiz = false;
          ctrl.isTesting = true;
          ctrl.isVisible = true;
          ctrl.questionIndex = 0;
          getCurrentQuestionIndex().then(function (currentQuestionIndex) {
            ctrl.questionIndex = currentQuestionIndex;
          });
        })
        .catch(function (err) {
            $log.error("error in showTest:", err);
            ModalService.show({
            message: "There was a problem starting this quiz. Please check your internet connection then click Ok and try again.",
            backdrop: 'static',
            buttons: [
              {
                title: 'Ok',
                click: '$hide();'
              }
            ]
          });
        });
      } else {
        ctrl.isTesting = true;
        ctrl.isVisible = true;
        ctrl.questionIndex = 0;
      }
    };

    function promptCloseQuiz() {
      ModalService.show({
        message: 'You are about to leave this test. If you leave, your score will not be recorded. You can return to complete this test before it expires. ' +
        'To submit the completed test, click the End Test button below the last test question. Do you still want to leave?',
        buttons: [
          {
            title: 'No',
            click: '$hide()'
          },
          {
            title: 'Yes',
            click: 'leave(); $hide()'
          }
        ],
        leave: function () {
          // Hide the test
          ctrl.hideTest(false);
        }
      });
    }

    ctrl.closeQuiz = function () {
      // Prompt the student if they really want to close the quiz
      if (ctrl.isStudent && !ctrl.cluster.linear) {
        promptCloseQuiz();
      }
      else {
        ctrl.hideTest(false);
      }
    }

    ctrl.hideTest = function () {
      $('#activity-content-container').removeClass('force-overlay');
      $('.experience-navbar').removeClass('hide');
      $('.content-with-nav-bar').removeClass('expand-beyond-screen');
      ctrl.isVisible = false;
      ctrl.isTeacherPreview = false;
      ctrl.isTesting = false;
    };

    function saveUserQuizState(status, lastIndex) {
      if (ctrl.isStudent) {
        return QuizStateService.quizUserState(ctrl.context.experienceId, ctrl.quizId, ctrl.userId)
          .saveState({state: {status: status, last_question: lastIndex}})
          .$promise;
      }
      else {
        return $q.when({});
      }
    }

    ctrl.onScoreSaved = function (isFinished) {
      if (ctrl.isStudent && isFinished) {
        // If this student has now completed all elements, add them to the respondents list and increment completed count
        return QuizStateService.quizUserState(ctrl.context.experienceId, ctrl.quizId, ctrl.userId)
          .getState()
          .$promise.then(function (results) {
            return saveUserQuizState("finished", results.last_question_index).then(function (saved) {
              ctrl.isTestComplete = true;
              ctrl.isRetake = false;
              ctrl.studentScore = Math.round(saved.score);
              return saved.score;
            });
          })
          .catch(function (err) {
            $log.error("error in scoreSaved:", err);
            ModalService.show({
              message: "There was a problem sending your response to the Exploros server. Please check your internet connection then click Ok and try again.",
              backdrop: 'static',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide();'
                }
              ]
            });
          });
      }
    }

    ctrl.onSelectionMade = function (selected) {
      if (ctrl.questionIndex === ctrl.cluster.elements.length - 1) {
        ctrl.lastQuestionChoiceSelected = selected;
      }
    }

    ctrl.onNextQuestion = function () {
      if (ctrl.isStudent) {
        if (ctrl.isSaving) {
          return;
        }
        if (ctrl.questionIndex + 1 < ctrl.cluster.elements.length) {
          ctrl.isSaving = true;
          saveUserQuizState('started', ctrl.questionIndex + 1).then(function (results) {
            ctrl.savingElementId.elementId = ctrl.cluster.elements[ctrl.questionIndex].id;
            ctrl.questionIndex = ctrl.questionIndex + 1;
            $timeout(function() { ctrl.isSaving = false; }, 1000);
          })
          .catch(function (err) {
            $log.error("error in onNextQuestion:", err);
            ctrl.isSaving = false;
            ModalService.show({
              message: "There was a problem sending your response to the Exploros server. Please check your internet connection then click Ok and try again.",
              backdrop: 'static',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide();'
                }
              ]
            });
          });
        }
      }
      else {
        ctrl.questionIndex = ctrl.questionIndex + 1;
      }
    };

    ctrl.onPreviousQuestion = function () {
      if (ctrl.isStudent) {
        if (ctrl.isSaving) {
          return;
        }
        if (ctrl.questionIndex - 1 >= 0) {
          ctrl.isSaving = true;
          saveUserQuizState('started', ctrl.questionIndex - 1).then(function (results) {
            ctrl.savingElementId.elementId = -1;
            ctrl.questionIndex = ctrl.questionIndex - 1;
            $timeout(function() { ctrl.isSaving = false; }, 1000);
          })
          .catch(function (err) {
            $log.error("error in onPreviousQuestion:", err);
            ctrl.isSaving = false;
            ModalService.show({
              message: "There was a problem sending your response to the Exploros server. Please check your internet connection then click Ok and try again.",
              backdrop: 'static',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide();'
                }
              ]
            });
          });
        }
      }
      else {
        ctrl.questionIndex = ctrl.questionIndex - 1;
      }
    };

    function allItemsHaveResponses() {
      // Get all the shared data for the current student and elements
      return QuizStateService.getUserQuizElementState(ctrl.context.experienceId, ctrl.userId)
        .getState({elements: ctrl.elementIds})
        .$promise.then(function (results) {

          var answerCount = 0;

          // Filter out any records that exist but don't contain actual data and belong to the current user
          results.forEach(function (answer) {
            // parse the responses and see if any selection was made
            var response = JSONStringUtility.parse(answer.user_data);
            if (response && response.selection && response.selection.length) {
              answerCount = answerCount + 1;
            } else if (Array.isArray(response) && response.length) {
              var missingValue = false;
              response.forEach(function(res) {
                if (res && Array.isArray(res) && !res.length) {
                  missingValue = true;
                }
              });
              if (!missingValue) {
                answerCount = answerCount + 1;
              }
            }
          });

          var badElements = ctrl.cluster.elements.filter(function(element, index) {
            var elementResult = results.find(function(res) {
              return res.element_id === element.id || index === ctrl.cluster.elements.length - 1;
            });
            return !elementResult;
          });

          var responses = {
            hasAllResponses: (answerCount === ctrl.cluster.elements.length ||
                (answerCount === ctrl.cluster.elements.length - 1 &&
                    ctrl.questionIndex === ctrl.cluster.elements.length - 1 && ctrl.lastQuestionChoiceSelected)),
            isMissingStates: results.length < ctrl.cluster.elements.length - 1,
            missingElements: badElements
          };

          // if the number of answers matches the number of questions then the student answered all
          return responses
        })
        .catch(function (err) {
          $log.error("error in get getUserQuizElementState:", err);
          ModalService.show({
            message: "There was a problem sending your response to the Exploros server. Please check your internet connection then click Ok and try again.",
            backdrop: 'static',
            buttons: [
              {
                title: 'Ok',
                click: '$hide();'
              }
            ]
          });
          return $q.reject();
        });
    }

    function promptFinishTest() {
      var d = $q.defer();
      ModalService.show({
        message: 'You have not submitted responses for one or more questions. If you end the test now you will not be able to answer these questions and they will be marked as incorrect. Do you want to end this test?',
        buttons: [
          {
            title: 'No',
            click: 'cancel(); $hide()'
          },
          {
            title: 'Yes',
            click: 'save(); $hide()'
          }
        ],
        save: function () {
          return d.resolve(true);
        },
        cancel: function () {
          return d.resolve(false);
        }
      });
      return d.promise;
    }

    function saveSkippedState(element) {
      var d = $q.defer();
      var userData = {selection:[]};
      if (element.type == "drag_drop_text" || element.type == "drag_drop_image" || element.type == "fill_in_the_blank") {
        userData = [""];
      }
      ElementsRestService.saveUserState(ctrl.context.experienceId, element.id, ctrl.context.userId, 0,
          JSON.stringify(userData), function() {
        return d.resolve();
      },function(error) {
        return d.resolve();
      });

      return d.promise;
    }

    function saveMissingStates(missingElements) {
      var elementPromises = [];
      missingElements.forEach(function (element) {
        elementPromises.push(saveSkippedState(element));
      });
      return $q.all(elementPromises).then(function (totalElements) {
        return;
      });
    }

    function checkForMissingResponses() {
      // if student and the test is NOT linear then the student might have skipped a question
      if (ctrl.isStudent && !ctrl.cluster.linear) {
        return allItemsHaveResponses().then(function (responses) {
          if (!responses.hasAllResponses) {
            return promptFinishTest().then(function(saveAll) {
              if (saveAll) {
                if (responses.isMissingStates) {
                  return saveMissingStates(responses.missingElements).then(function() {
                    return true;
                  });
                } else {
                  return true;
                }
              } else {
                return false;
              }
            });
          } else {
            return true;
          }
        });
      }
      else {
        return $q.when(true);
      }
    }

    ctrl.onFinishQuiz = function () {
      if (ctrl.isStudent) {
        if (ctrl.isSaving) {
          return;
        }
        ctrl.isSaving = true;
        // Check to see if the student skipped any questions.  Prompt them if they really want to end
        checkForMissingResponses().then(function (endTest) {
          if (endTest) {
            // This saves the value for the last element in the quiz and calls back after it is complete to save the score
            ctrl.savingElementId.elementId = ctrl.cluster.elements[ctrl.questionIndex].id;
            ctrl.savingElementId.finished = true;
            ctrl.hideTest();
          }
          ctrl.isSaving = false;
        })
        .catch(function (err) {
          $log.error("error in onFinishQuiz:", err);
          ctrl.isSaving = false;
        });
      }
      else {
        ctrl.hideTest();
      }
    };

    ctrl.hideTeacherNotes = function() {
      if (ctrl.isStudent) {
        return false;
      }

      return ActiveExperience.currentExperience() && ActiveExperience.currentExperience().hideTeacherNotes;
    };
/////////////////////////////////////////////////////////////////////////////////////
// Functions for displaying past experience data
////////////////////////////////////////////////////////////////////////////////////

    ctrl.isReversed = false;
    ctrl.predicate = 'lastName';
    ctrl.currentPage = 0;
    ctrl.pageSize = 8;
    ctrl.lastPage = 0;

    function initializeSelecteditem() {
      // if there are any elements in the quiz (should really always be) then show the first by default when opening for past experience
      if (ctrl.cluster && ctrl.cluster.elements && ctrl.cluster.elements.length > 0) {
        ctrl.selectedItem = ctrl.cluster.elements[0];
      }
    }

    ctrl.order = function (predicate) {
      if (typeof(predicate) === 'object') {
        if (ctrl.selectedItem != predicate) {
          ctrl.selectedItem = predicate;
        }
      } else {
        ctrl.isReversed = (ctrl.predicate === predicate) ? !ctrl.isReversed : false;
        ctrl.predicate = predicate;
      }
    };

    ctrl.prevPage = function () {
      ctrl.currentPage = Math.max(0, ctrl.currentPage - 1);
    };

    ctrl.nextPage = function () {
      ctrl.currentPage = Math.min(ctrl.lastPage, ctrl.currentPage + 1);
    };

    ctrl.isStudentRetake = function(studentId) {
      var studentRetake = ctrl.studentsInRetake.find(function(student) {
        return student === studentId;
      });
      return studentRetake;
    }

    ctrl.studentScore = function(studentId) {
      if (ctrl.studentScores[studentId] >= 0) {
        return ctrl.studentScores[studentId] + "%";
      } else {
        return "";
      }
    }

    ctrl.allRetake = function() {
      var now = new Date();
      var newEndDate = new Date();
      newEndDate.setHours(now.getHours() + 2);

      ModalService.show({
        title: 'Reactivate Experience',
        backdrop: 'static',
        template: require('./reactivate.jade'),
        endDate: newEndDate,
        save: function (endDate) {
          return QuizStateService.quizActivate(ctrl.context.experienceId, ctrl.quizId)
          .reactivate({endDate: endDate})
          .$promise.then(function (results) {
            $window.location.reload();
          })
          .catch(function (err) {
            $log.error("error in reactivate:", err);
          });
        }
      });
    }

    ctrl.toggleRetake = function() {
      ctrl.allowStudentToRetake = !ctrl.allowStudentToRetake;
      if (ctrl.allowStudentToRetake) {
        ctrl.retakeButtonText = "Hide Retake";
        ctrl.currentPage = ctrl.lastPage;
      } else {
        ctrl.retakeButtonText = "Show Retake";
      }
    }

    ctrl.showRetake = function(studentId) {
      return ctrl.studentScores[studentId] >= 0 && ctrl.allowStudentToRetake;
    }

    ctrl.studentCanRetake = function(studentId, studentName) {
      ModalService.show(
        {
          title: 'Allow Retake',
          message: studentName + ' will be able to retake the quiz and their current score will be overridden',
          buttons: [
            {
              title: 'Retake',
              click: 'Retake(); $hide()'
            },
            {
              title: 'Cancel',
              click: '$hide()',
              class: 'btn btn-default'
            }
          ],
          Retake: function () {
            return QuizStateService.quizUserState(ctrl.context.experienceId, ctrl.quizId, studentId)
              .retake({state: {status: 'retake', elements: ctrl.elementIds}})
              .$promise.then(function (results) {
                initTeacherData();
              })
              .catch(function (err) {
                $log.error("error in studentCanRetake:", err);
                ctrl.isSaving = false;
              });
          }
        }
      );
    }

    function findStudentElementResponse(studentId, elementId) {
      // Find the student specified
      var studentResponse = ctrl.studentResponses.find(function (studentResponse) {
        return studentResponse.studentId === studentId;
      });

      // See if there is any response for this student and element
      if (studentResponse) {
        var elementResponse = studentResponse.elements.find(function (response) {
          return response.elementId === elementId;
        });

        // return the response
        return elementResponse;
      }
      else {
        return null;
      }
    }

    ctrl.isItemResultCorrect = function (studentId, elementId) {
      var elementResponse = findStudentElementResponse(studentId, elementId);
      if (elementResponse && elementResponse.score) {
        return elementResponse.score.total === elementResponse.score.correct;
      }
      else {
        return false;
      }
    };

    ctrl.isItemResultIncorrect = function (studentId, elementId) {
      var elementResponse = findStudentElementResponse(studentId, elementId);
      if (elementResponse && elementResponse.score) {
        return elementResponse.score.correct === 0;
      }
      else {
        return false;
      }
    };

    ctrl.isItemResultPartial = function (studentId, elementId) {
      var elementResponse = findStudentElementResponse(studentId, elementId);
      if (elementResponse && elementResponse.score) {
        return elementResponse.score.correct > 0 && elementResponse.score.total !== elementResponse.score.correct;
      }
      else {
        return false;
      }
    };

    ctrl.isItemResultNone = function (studentId, elementId) {
      // if the student does NOT have a response then return true.
      var elementResponse = findStudentElementResponse(studentId, elementId);
      if (!elementResponse || (elementResponse && !elementResponse.score)) {
        return true;
      }
      else {
        return false;
      }
    };

    ctrl.QuizRespondent = function (respondent) {
      var _this = this;

      this.getType = function getType() {
        return RespondentType.USER;
      };

      this.getDisplayId = function getDisplayId() {
        return respondent;
      };

      this.isSelected = function isSelected() {
        return respondent == ctrl.selectedRespondent;
      };

      this.getSize = function getSize() {
        return 40;
      };

      this.select = function select() {
        ctrl.selectedRespondent = respondent;
      };

      this.getDisplayName = function getDisplayName() {
        return ctrl.context.getUserDisplayName(_this.getDisplayId(respondent));
      };

      return this;
    };

    $scope.wrapRespondent = function (respondent) {
      var wrappedRespondent = new ctrl.QuizRespondent(respondent);
      return wrappedRespondent;
    };

  }

  module.component('xpQuiz', {
    template: require('./quiz.jade'),
    controller: quizController,
    bindings: {
      cluster: '<',
      context: '<'
    }
  });

})();
