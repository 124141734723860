'use strict';
(function(){
/* globals elementModules */
var appModules = [
  'xx-http-error-handling',
  'client.controllers',
  'client.services',
  'client.directives',
  'client.filters',
  'client.components',
  'ngAnimate',
  'ngRoute',
  'rt.resize',
  'debounce',
  'angularWidget',
  'angular-ladda',
  'toggle-switch',
  'ngCookies',
  'DWand.nw-fileDialog',
  'ngSanitize',
  'angularFileUpload',
  'daterangepicker',
  'ngTable',
  'isteven-multi-select',
  'lk-google-picker',
  "xp-html-common",
  "xp-element-HTMLteacher_gate",
  "xp-element-HTMLteacher_note",
  "xp-element-HTMLtext",
  "xp-element-audio",
  "xp-element-common",
  "xp-element-drag_drop_image",
  "xp-element-drag_drop_text",
  "xp-element-draw",
  "xp-element-fill_in_the_blank",
  "xp-element-graphic-organizer",
  "xp-element-header",
  "xp-element-image",
  "xp-element-inline-choice",
  "xp-element-multi-part",
  "xp-element-hot-spot",
  "xp-element-hot-text",
  "xp-element-link",
  "xp-element-poll",
  "xp-element-spire-common",
  "xp-element-spire-concept-assessment",
  "xp-element-spire-concept-mastery-fluency-drill",
  "xp-element-spire-mid-level",
  "xp-element-spire-pre-post-level-test",
  "xp-element-spire-placement",
  "xp-element-table",
  "xp-element-tao-assessment",
  "xp-element-teacher_gate",
  "xp-element-teacher_note",
  "xp-element-text",
  "xp-element-video",
  "xp-element-wall",
  "xp-element-web",
  "xp-element-word_cloud",
  'd3',
  'client-templates',
  'element-templates',
  'configuration',
  'tao_configuration',
  'monospaced.elastic',
  'ngSegment',
  'ngStorage',
  'permission',
  'permission.ng',
  'client.permissions',
  'opentok',
  'ngOnboarding'
];

/*
 Main module for client app
 configuration for the app resides in ./configuration.js
 */
angular.module('client', appModules);
angular.module('client.components', ['component-templates']);

})();
