'use strict';

angular.module('client.services').factory('schoologyService', ['$resource', '$q', 'CourseFactory', '$log', 'User',
  function ($resource, $q, CourseFactory, $log, User) {

  var imsResource = $resource('/users/:id/ims', {id: '@id'},
  {
    'get': {method: 'GET', isArray: true}
  });

  function getIms(id) {
    return imsResource.get({id: id}).$promise;
  }

  var coursesResource = $resource('/ims/courses/list', {ims_id: '@ims_id'},
  {
    'get': {method: 'GET', isArray: true}
  });

  function getCourses(ims_id) {
    return coursesResource.get({ims_id: ims_id}).$promise;
  }

  var importResource = $resource('/ims/courses/import/:id', {id: '@id'},
  {
    'post': {method: 'POST'}
  });

  function importCourse(id, params) {
    return importResource.post({id: id}, params).$promise;
  }

  var studentsResource = $resource('/ims/courses/:id/students', {id: '@id', ims_id: '@ims_id', section_id: '@section_id'},
  {
    'get': {method: 'GET', isArray: true}
  });

  function getStudents(ims_id, section_id, id) {
    return studentsResource.get({id: id, ims_id: ims_id, section_id: section_id}).$promise;
  }

  var syncResource = $resource('/ims/courses/sync', {ims_id: '@ims_id'},
  {
    'post': {method: 'POST'}
  });

  function syncCourse(ims_id, id) {
    return syncResource.post({ims_id: ims_id}, {cid: id}).$promise;
  }

  function getAvailableCourses(user) {
    var schoologyClasses = {
      courses: [],
      isSchoologyClassroomUser: false,
      lms_id: 0
    };

    // if this user is NOT a schoology user then immediately return an empty list
    if (!user.schoology_user) {
      return $q.when(schoologyClasses);
    }

    return getIms(user.id).then(function (response) {
      // If the current user is not schoology user then return empty list
      if (!response || !response.length) {
        return schoologyClasses;
      }

      var imsUser = response.find(function (res) {
        return res.int_id === -4;
      });
      if (!imsUser) {
        return schoologyClasses;
      }

      schoologyClasses.lms_id = imsUser.int_id;

      return getCourses(imsUser.int_id).then(function (schoologyCourses) {
        if (!schoologyCourses || schoologyCourses.length === 0) {
          return schoologyClasses;
        }

        // This user is a schoology classroom user since they returned a valid list of at least one classroom
        schoologyClasses.isSchoologyClassroomUser = true;

        // Now lookup this teachers existing classes so we can remove duplicates
        var params = {teacher_user_id: User.getId()};
        return CourseFactory.list(params).then(function (courses) {
          // Remove any schoology classes that have already been added
          schoologyClasses.courses = schoologyCourses.filter(function (schoologyCourse) {
            var imported = false;
            courses.forEach(function (course) {
              // Get the current classroom ID from the data returned to us
              var course_id = 0;
              var section_id = 0;
              try {
                var settings = angular.fromJson(course.settings) || {};
                if (settings && settings.ims && settings.ims.classroom) {
                  course_id = settings.ims.classroom;
                  section_id = settings.ims.section_id;
                }
              }
              catch (e) {
                $log.error(e.message);
              }
              // if either the name or ID match then consider it a matching class
              const schoologySectionId = parseInt(schoologyCourse.section_id, 10);
              if ((schoologyCourse.course_id === course_id && schoologySectionId === section_id) ||
                  schoologyCourse.name === course.class_name) {
                imported = true;
              }
            });
            return !imported;
          });
          return schoologyClasses;
        }, function (error) {
          $log.error("schoology API error:", error);
          $q.reject(error);
        });
      });
    })
    .catch(function (error) {
      $log.error("get classroom API error:", error);
      $q.resolve(schoologyClasses);
    });
  }

  var linkResource = $resource('/ims/courses/link', {ims_id: '@ims_id'},
  {
    'post': {method: 'POST'}
  });

  function linkCourse(user, cid, className, courseId, sectionId, settings) {
    return getIms(user.id).then(function (response) {
      if (!response || !response.length) {
        return null;
      }
      var imsUser = response.find(function (res) {
        return res.int_id === -4;
      });
      if (!imsUser) {
        return null;
      }
      return linkResource.post({ims_id: imsUser.int_id}, {cid: cid, className: className, courseId: courseId, sectionId: sectionId, settings: settings}).$promise;
    });
  }

  return {
    getAvailableCourses: getAvailableCourses,
    importCourse: importCourse,
    getStudents: getStudents,
    syncCourse: syncCourse,
    linkCourse: linkCourse
  };
}]);