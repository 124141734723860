'use strict';

//----------------------------------------------------------
//
// Global Module Configurations
//
//----------------------------------------------------------
// Configure header to disable CORS.  This can be removed when in production and all code is in the same domain
angular.module('client').config(['$httpProvider',
  function ($httpProvider) {
    $httpProvider.defaults.useXDomain = true;

    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    // Eliminate extraneous $digest cycles by grouping the rendering of multiple XHR responses into a single $digest
    $httpProvider.useApplyAsync(true);

    $httpProvider.defaults.headers.common.application_id = "com.exploros.Exploros";
  }]);

// Configure date picker
angular.module('client').config(function ($datepickerProvider) {
  angular.extend($datepickerProvider.defaults, {
    dateFormat: 'MM/dd/yyyy',
    startWeek: 1
  });
});

// Configure onboarding tour
angular.module('client').config(function (ngOnboardingDefaultsProvider) {
  ngOnboardingDefaultsProvider.set({showButtons: false, showDoneButton: false, showStepInfo: false});
});

// Configure ngStorage localStorage
angular.module('client').config(['$localStorageProvider',
  function ($localStorageProvider) {
    $localStorageProvider.setKeyPrefix('com.exploros.');
  }]);

// Configure ngStorage sessionStorage
angular.module('client').config(['$sessionStorageProvider',
  function ($sessionStorageProvider) {
    $sessionStorageProvider.setKeyPrefix('com.exploros.');
  }]);


//Configure google document picker
angular.module('client').config(['lkGoogleSettingsProvider',
  function (lkGoogleSettingsProvider) {
    lkGoogleSettingsProvider.configure({
      scopes: ['https://www.googleapis.com/auth/drive'],
      locale: 'en',
      features: ['MULTISELECT_ENABLED'],
      views: ['DocsView(google.picker.ViewId.DOCUMENTS)', 'DocsView(google.picker.ViewId.SPREADSHEETS)',
        'DocsView(google.picker.ViewId.PRESENTATIONS)', 'DocsView(google.picker.ViewId.DRAWINGS)',
        'DocsView(google.picker.ViewId.DOCS_VIDEOS)', 'DocsView(google.picker.ViewId.DOCS_IMAGES)',
        'DocsView(google.picker.ViewId.PDFS)']
    });
  }]);

angular.module('client').config(['$routeProvider', '$locationProvider',
  function ($routeProvider, $locationProvider) {
    var isLoggedIn = ['$q', 'User', 'loginService', '$location', function ($q, User, loginService, $location) {
      return $q(function (resolve, reject) {
        let urlToken = $location.search().token;
        if (!User.hasToken() && !urlToken) {
          return reject({needsAuthentication: true});
        }

        if (User.getId()) {
          return resolve();
        }

        return User.hasLoggedIn().then(resolve).catch(reject);
      });
    }];

    //----------------------------------------------------------
    //
    // Define all routes.  This handles navigation within the application
    //
    //----------------------------------------------------------

    window.routes =
      {
        "/login": {
          template: '<xp-login/>',
          requireLogin: false
        },
        "/forgotPassword": {
          template: '<xp-reset-password/>',
          requireLogin: false
        },
        "/connectToTeacher": {
          title: 'Connect to a Teacher',
          template: require('../../views/partials/connectToTeacherForm.jade'),
          controller: 'ConnectToTeacherCtrl',
          bodyClass: 'page-connect-to-teacher',
          resolve: {
            isStudentUser: function ($q) {
              return $q.when(false);
            }
          },
          requireLogin: false
        },
        "/connectToTeacher/student": {
          title: 'Connect to a Teacher',
          template: require('../../views/partials/connectToTeacherForm.jade'),
          controller: 'ConnectToTeacherCtrl',
          bodyClass: 'page-connect-to-teacher',
          resolve: {
            isStudentUser: function ($q) {
              return $q.when(true);
            }
          },
          requireLogin: false
        },
        "/joinAClass": {
          template: '<xp-connect-to-class/>',
          requireLogin: false
        },
        "/joinAClass/student": {
          template: '<xp-connect-to-class/>',
          requireLogin: false
        },
        "/joinAClass/teacher": {
          template: '<xp-connect-to-class/>',
          requireLogin: true
        },
        "/terms": {
          template: require('../../views/partials/terms.jade'),
          controller: 'ConnectToTeacherCtrl',
          bodyClass: 'page-terms',
          requireLogin: false
        },
        "/userInfo": {
          title: 'Profile Settings',
          template: require('../../views/partials/userInfo.jade'),
          controller: 'UserInfoCtrl',
          bodyClass: 'page-user-info',
          requireLogin: true
        },
        "/welcome": {
          template: require('../../views/partials/welcome.jade'),
          controller: 'WelcomeCtrl',
          bodyClass: 'page-welcome',
          requireLogin: true
        },
        "/helpVideos": {
          template: '<xp-help-videos/>',
          requireLogin: true
        },
        "/userInfo/userInfoDetail": {
          title: 'Profile Settings',
          template: require('../../views/partials/userInfoDetail.jade'),
          controller: 'UserInfoDetailCtrl',
          bodyClass: 'page-user-info',
          requireLogin: true
        },
        "/userSettings": {
          title: 'Account Settings',
          template: require('../../views/partials/userSettings.jade'),
          controller: 'UserSettingsCtrl',
          bodyClass: 'page-user-settings',
          requireLogin: true
        },
        "/userSettings/changePassword": {
          title: 'Change Password',
          template: require('../../views/partials/changePasswordForm.jade'),
          controller: 'ChangePasswordFormCtrl',
          bodyClass: 'page-change-password',
          requireLogin: true
        },
        "/userInfo/camera": {
          template: require('../../views/partials/camera.jade'),
          controller: 'CameraCtrl',
          bodyClass: 'page-user-info-camera',
          requireLogin: true
        },
        "/userInfo/upload": {
          template: require('../../views/partials/upload.jade'),
          controller: 'UploadCtrl',
          bodyClass: 'page-user-info-upload',
          requireLogin: true
        },
        "/printstudent": {
          template: require('../../views/partials/experiencePrintStudents.jade'),
          controller: 'ActiveExperienceCtrl',
          bodyClass: 'page-experience-print',
          requireLogin: true
        },
        "/themes": {
          template: require('../../views/partials/experiencePrintStudents.jade'),
          controller: 'ActiveExperienceCtrl',
          bodyClass: 'page-experience-print',
          requireLogin: true
        },


        // Curriculum (All experiences) views
        "/experience": {
          template: require('../../views/partials/experienceList.jade'),
          controller: 'ExperienceListLayoutCtrl',
          bodyClass: 'page-experience-list',
          navView: 'experiences',
          requireLogin: true
        },
        "/experience/reports": {
          template: '<xp-report-picker/>',
          requireLogin: true
        },
        "/experience/report/:reportId": {
          template: '<xp-report/>',
          requireLogin: true
        },
        "/experience/class-pack": {
          template: require('../../views/partials/classPack.jade'),
          controller: 'ClassPackCtrl',
          bodyClass: 'page-class-pack',
          navView: 'class-pack',
          requireLogin: true
        },


        // Class based routes
        "/class/:classId": {
          template: require('../../views/partials/experienceList.jade'),
          controller: 'CourseViewCtrl',
          bodyClass: 'page-class-experiences',
          navView: 'experiences',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/report/:reportId": {
          template: '<xp-report/>',
          requireLogin: true
        },
        "/class/:classId/reports": {
          template: '<xp-report-picker/>',
          requireLogin: true
        },
        "/class/:classId/class-pack": {
          template: require('../../views/partials/classPack.jade'),
          controller: 'ClassPackCtrl',
          bodyClass: 'page-class-pack',
          navView: 'class-pack',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/license-pack": {
          template: '<xp-license-pack/>',
          navView: 'license-pack',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/roster": {
          template: '<xp-class-roster/>',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/students": {
          template: '<xp-class-all-students/>',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/campus": {
          template: '<xp-campus-roster/>',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/import": {
          template: '<xp-class-import>',
          requireLogin: true,
          resolve: {
            currentCourse: function (CourseFactory, $route) {
              return CourseFactory.setCurrentCourse($route.current.params.classId);
            }
          }
        },
        "/class/:classId/assign": {
          template: '<xp-assign-experience/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/users": {
          template: '<xp-district-users-nav/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/manage": {
          template: '<xp-district-users-modal/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/seats": {
          template: '<xp-district-users-and-seats/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/users/teachers": {
          template: '<xp-admin-invite-teachers/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/users/roster": {
          template: '<xp-admin-import-rosters/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/experiences": {
          template: require('../../views/partials/experienceList.jade'),
          controller: 'SubscriptionExperiencesCtrl',
          bodyClass: 'page-class-experiences',
          navView: 'experiences',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/reports": {
          template: '<xp-admin-report-picker/>',
          requireLogin: true
        },
        "/district/:districtId/subscription/:subscriptionId/report/:reportId": {
          template: '<xp-report/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/users": {
          template: '<xp-district-users-nav/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/manage": {
          template: '<xp-district-users-modal/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/seats": {
          template: '<xp-district-users-and-seats/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/users/teachers": {
          template: '<xp-admin-invite-teachers/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/users/roster": {
          template: '<xp-admin-import-rosters/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/experiences": {
          template: require('../../views/partials/experienceList.jade'),
          controller: 'SubscriptionExperiencesCtrl',
          bodyClass: 'page-class-experiences',
          navView: 'experiences',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/reports": {
          template: '<xp-admin-report-picker/>',
          requireLogin: true
        },
        "/school/:schoolId/subscription/:subscriptionId/report/:reportId": {
          template: '<xp-report/>',
          requireLogin: true
        },
        "/experience/assign": {
          template: '<xp-assign-experience/>',
          requireLogin: true
        },
        // Subscription based routes
        "/subscription/:subscriptionId": {
          template: require('../../views/partials/experienceList.jade'),
          controller: 'SubscriptionExperiencesCtrl',
          bodyClass: 'page-class-experiences',
          navView: 'experiences',
          requireLogin: true
        },
        "/subscription/:subscriptionId/license": {
          template: '<xp-subscription-license/>',
          requireLogin: true
        },
        "/subscription/:subscriptionId/license/schools": {
          template: '<xp-subscription-license-schools/>',
          requireLogin: true
        },
        "/subscription/:subscriptionId/import": {
          template: '<xp-subscription-import/>',
          requireLogin: true
        },
        "/subscription/:subscriptionId/class-pack": {
          template: require('../../views/partials/classPack.jade'),
          controller: 'ClassPackCtrl',
          bodyClass: 'page-class-pack',
          navView: 'class-pack',
          requireLogin: true
        },
        "/subscription/:subscriptionId/reports": {
          template: '<xp-report-picker/>',
          requireLogin: true
        },
        "/subscription/:subscriptionId/report/:reportId": {
          template: '<xp-report/>',
          requireLogin: true
        },


        // Need to modify these to work with class
        "/experience/:id/dashboard/students": {
          template: require('../../views/partials/dashboardStudents.jade'),
          controller: 'StudentDashboardCtrl',
          bodyClass: 'page-player page-player-activity',
          requireLogin: true
        },
        "/experience/:id/dashboard/responses": {
          template: require('../../views/partials/dashboardResponses.jade'),
          controller: 'ResponsesDashboardCtrl',
          bodyClass: 'page-player page-player-posts',
          requireLogin: true
        },
        "/experience/:id/activity/scene/:sceneid": {
          template: require('../../views/partials/activity.jade'),
          controller: 'ActiveExperienceCtrl',
          bodyClass: 'page-player page-player-active',
          requireLogin: true
        },
        "/experience/:id/pack/:type": {
          template: require('../../views/partials/pack.jade'),
          controller: 'PackCtrl',
          bodyClass: 'page-player page-player-pack',
          requireLogin: true
        },
        "/experience/assignment/:id/": {
          template: '<classroom-assignment>',
          requireLogin: true
        },
        "/district/:districtId/license": {
          template: '<xp-district-license/>',
          requireLogin: true
        }
      };

    $routeProvider.whenAuthenticated = function (path, route) {
      route.resolve = route.resolve || {};

      angular.extend(route.resolve, {isLoggedIn: isLoggedIn});

      return $routeProvider.when(path, route);
    };

    // Loads the routes dynamically based on the windows.routes object
    for (var path in window.routes) {
      if (window.routes[path].requireLogin) {
        $routeProvider.whenAuthenticated(path, window.routes[path]);
        continue;
      }

      $routeProvider.when(path, window.routes[path]);
    }

    $routeProvider.otherwise({
      redirectTo: '/login'
    });

    $locationProvider.html5Mode(true);

  }]);

//------------------
// cache templates
//------------------

angular.module('client').run(['$templateCache', function ($templateCache) {
  $templateCache.put('partials/dashboardHeader.jade', require('../../views/partials/dashboardHeader.jade'));
  $templateCache.put('partials/experienceHeader.jade', require('../../views/partials/experienceHeader.jade'));
  $templateCache.put('partials/experienceFooter.jade', require('../../views/partials/experienceFooter.jade'));
  $templateCache.put('partials/loading.jade', require('../../views/partials/loading.jade'));
  $templateCache.put('partials/dashboardStudents.jade', require('../../views/partials/dashboardStudents.jade'));
  $templateCache.put('partials/dashboardResponses.jade', require('../../views/partials/dashboardResponses.jade'));
  $templateCache.put('partials/modals/experienceListModal.jade', require('../../views/partials/modals/experienceListModal.jade'));

  $templateCache.put('elements/HTML_common/template.jade', require('../../elements/HTML_common/template.jade'));
  $templateCache.put('elements/HTMLteacher_gate/template.jade', require('../../elements/HTMLteacher_gate/template.jade'));
  $templateCache.put('elements/HTMLteacher_note/template.jade', require('../../elements/HTMLteacher_note/template.jade'));
  $templateCache.put('elements/HTMLtext/template.jade', require('../../elements/HTMLtext/template.jade'));
  $templateCache.put('elements/audio/template.jade', require('../../elements/audio/template.jade'));
  $templateCache.put('elements/choice/template.jade', require('../../elements/choice/template.jade'));
  $templateCache.put('elements/common/template.jade', require('../../elements/common/template.jade'));
  $templateCache.put('elements/common/instructions.jade', require('../../elements/common/instructions.jade'));
  $templateCache.put('elements/common/instructions_inverted.jade', require('../../elements/common/instructions_inverted.jade'));
  $templateCache.put('elements/drag_drop_image/template.jade', require('../../elements/drag_drop_image/template.jade'));
  $templateCache.put('elements/drag_drop_text/template.jade', require('../../elements/drag_drop_text/template.jade'));
  $templateCache.put('elements/draw/template.jade', require('../../elements/draw/template.jade'));
  $templateCache.put('elements/fill_in_the_blank/template.jade', require('../../elements/fill_in_the_blank/template.jade'));
  $templateCache.put('elements/graphic_organizer/template.jade', require('../../elements/graphic_organizer/template.jade'));
  $templateCache.put('elements/header/template.jade', require('../../elements/header/template.jade'));
  $templateCache.put('elements/hot_spot/template.jade', require('../../elements/hot_spot/template.jade'));
  $templateCache.put('elements/hot_text/template.jade', require('../../elements/hot_text/template.jade'));
  $templateCache.put('elements/image/template.jade', require('../../elements/image/template.jade'));
  $templateCache.put('elements/inline_choice/template.jade', require('../../elements/inline_choice/template.jade'));
  $templateCache.put('elements/link/template.jade', require('../../elements/link/template.jade'));
  $templateCache.put('elements/multi_part/template.jade', require('../../elements/multi_part/template.jade'));
  $templateCache.put('elements/poll/template.jade', require('../../elements/poll/template.jade'));
  $templateCache.put('elements/quiz_choice/template.jade', require('../../elements/quiz_choice/template.jade'));
  $templateCache.put('elements/spire_common/template.jade', require('../../elements/spire_common/template.jade'));
  $templateCache.put('elements/spire_concept_assessment/template.jade', require('../../elements/spire_concept_assessment/template.jade'));
  $templateCache.put('elements/spire_concept_mastery_fluency_drill/template.jade', require('../../elements/spire_concept_mastery_fluency_drill/template.jade'));
  $templateCache.put('elements/spire_mid_level_test/template.jade', require('../../elements/spire_mid_level_test/template.jade'));
  $templateCache.put('elements/spire_pre_post_level_test/template.jade', require('../../elements/spire_pre_post_level_test/template.jade'));
  $templateCache.put('elements/spire_placement_test/template.jade', require('../../elements/spire_placement_test/template.jade'));
  $templateCache.put('elements/table/template.jade', require('../../elements/table/template.jade'));
  $templateCache.put('elements/tao_assessment/template.jade', require('../../elements/tao_assessment/template.jade'));
  $templateCache.put('elements/teacher_gate/template.jade', require('../../elements/teacher_gate/template.jade'));
  $templateCache.put('elements/teacher_note/template.jade', require('../../elements/teacher_note/template.jade'));
  $templateCache.put('elements/text/template.jade', require('../../elements/text/template.jade'));
  $templateCache.put('elements/undefined/template.jade', require('../../elements/undefined/template.jade'));
  $templateCache.put('elements/video/template.jade', require('../../elements/video/template.jade'));
  $templateCache.put('elements/wall/template.jade', require('../../elements/wall/template.jade'));
  $templateCache.put('elements/web/template.jade', require('../../elements/web/template.jade'));
  $templateCache.put('elements/word_cloud/template.jade', require('../../elements/word_cloud/template.jade'));

}]);

//----------------------------------------------------------
//
// Configure permissions
//
//----------------------------------------------------------
angular.module('client').run(['PermPermissionStore', 'PermissionConsts', 'userPermissions',
  'userClassPermissions', 'userSubscriptionPermissions',
  function (PermPermissionStore, PermissionConsts, userPermissions, userClassPermissions, userSubscriptionPermissions) {
    var allPermissions = [];
    Object.keys(PermissionConsts).forEach(function (key) {
      allPermissions.push(PermissionConsts[key]);
    });
    PermPermissionStore.defineManyPermissions(allPermissions, function (permissionName, transitionProperties) {
      return userPermissions.hasPermission(permissionName) || userClassPermissions.hasPermission(permissionName) ||
        userSubscriptionPermissions.hasPermission(permissionName);
    });
  }]);

//----------------------------------------------------------
//
// Add global utility functions.
//
//----------------------------------------------------------
angular.module('client').run(['$rootScope', '$location', '$route', '$q', '$sce', 'CourseFactory', 'ThemeService',
  'GoogleAnalytics', '$http', '$log', 'xpMetrics',
  function ($rootScope, $location, $route, $q, $sce, CourseFactory, ThemeService, GoogleAnalytics, $http,
            $log, xpMetrics) {

    $rootScope.routes = window.routes;

    // See if there is a provided
    var theme = $location.search().theme;
    if (theme) {
      ThemeService.setTheme(theme);
    }

    // Utility function for Birth Date
    $rootScope.dateFormatterExtract = function (systemDate) {
      // Show the Date in YYYY-MM-DD, just extract
      var dateTimeSplit = systemDate.split("T");
      // get date part, ignore the time part
      return dateTimeSplit[0];
    };

    // Utility function which formats all dates based on the iPad app format
    $rootScope.dateFormatter = function (systemDate) {
      // Show the time part
      return $rootScope.dateTimeFormatter(systemDate, true);
    };

    // Utility function which formats all dates based on the iPad app format
    $rootScope.dateTimeFormatter = function (systemDate, bShowTimePart) {
      // if the provided values is missing then simply return an empty string
      if (systemDate === null) {
        return "";
      }

      // Parse the string date into a real date
      var parsedDate = Date.parse(systemDate);
      var inputDate = new Date(parsedDate);

      // See if the date matches todays date.  If so uses default string "Today".  Otherwise Format to standard local date format
      var todaysDate = new Date();
      var sourceDate = new Date(inputDate);
      var datePart = "Today";
      if (sourceDate.setHours(0, 0, 0, 0) != todaysDate.setHours(0, 0, 0, 0)) {
        // datePart = inputDate.toLocaleDateString(); // Using this method is very slow if you have many dates to format.
        datePart = '' + (inputDate.getMonth() + 1) + '/' + inputDate.getDate() + '/' + inputDate.getFullYear();
      }

      // Concatenate the two together for the full date/time value otherwise dont show time part
      if (bShowTimePart) {
        // Format to standard local time format
        // var timePart = inputDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit', hour12: true}); // Using this method is very slow if you have many dates to format.
        var timePart;
        var hour = inputDate.getHours();
        if (hour === 0) {
          timePart = '12';
        } else if (hour > 12) {
          timePart = '' + (hour - 12);
        } else {
          timePart = '' + hour;
        }

        var minutes = inputDate.getMinutes();

        if (minutes < 10) {
          timePart += ':0' + minutes;
        } else {
          timePart += ':' + minutes;
        }

        if (hour < 12) {
          timePart += ' AM';
        } else {
          timePart += ' PM';
        }

        return datePart + " " + timePart;
      } else {
        return datePart;
      }
    };

    $rootScope.getTeacherHelp = function () {
      var link = document.querySelector('link[rel="importTeacher"]');
      // Clone the <template> in the import.
      var template = link.import.querySelector('template');
      var clone = document.importNode(template.content, true);

      document.querySelector('#teacherHelp').appendChild(clone);

      return clone;
    };
    $rootScope.getStudentHelp = function () {

    };

    $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
      if (rejection && rejection.needsAuthentication === true) {
        //var returnUrl = $location.url();

        $log.debug("$routeChangeError: ", rejection, current);

        // User needs authentication, redirect to /login and pass along the return URL
        //$location.path('/login').search({ returnUrl: returnUrl });
        $location.path('/login');
      }
    });

    GoogleAnalytics.init();
    xpMetrics.startMeteringCycle();
  }]);


