'use strict';

angular.module('client.services').factory('AdminConsoleService', function ($resource) {

  var usersService = $resource('/districts/:subscriptionId/users', {districtId: '@districtId'}, {
    'get': {method: 'GET', params: {subscriptionId: '@subscriptionId'}},
  });

  var schoolsService = $resource('/districts/:subscriptionId/schools', {districtId: '@districtId'}, {
    'get': {method: 'GET', isArray: true, params: {subscriptionId: '@subscriptionId'}}
  });

  function users(subscriptionId) {
    return usersService.get({}, {'subscriptionId': subscriptionId}).$promise;
  }

  function schools(subscriptionId) {
    return schoolsService.get({}, {'subscriptionId': subscriptionId}).$promise.then(function(schools) {
      if (schools) {
        schools.forEach(function(school){
          if (school.subscriptions) {
            school.subscriptions = school.subscriptions.filter(function(sub) {
              return sub !== 'Summary';
            });
          }
        });
      }
      return schools;
    });
  }

  return {
    users: users,
    schools: schools
  };
});
