'use strict';

(function() {
var module = angular.module('client.components');

var backgroundColors = [
  '#7D86BD',
  '#1FA649',
  '#BF762B',
  '#B4D435',
  '#54BAE9',
  '#F8CC06',
  '#7AB360',
  '#11AEBC',
  '#B21D92',
  '#F12CA2',
  '#E6251E',
  '#F19500',
  '#8E652D',
  '#F35240',
  '#3E5EA4',
  '#408ACD'
];

var defaultUserImageParts =
  ['<svg version="1.1" ' +
  'baseProfile="full" ' +
  'width="100" height="100" ' +
  'viewBox="0 0 100 100" ' +
  'xmlns="http://www.w3.org/2000/svg"> ' +
  '<rect x="0" y="0" width="100" height="100" fill="',
    '"/>' +
    '<text x="50" y="52" font-size="44" text-anchor="middle" dominant-baseline="middle" ' +
    'font-family="sans-serif" font-weight="bold" fill="white">',
    '</text> ' +
    '</svg>'
  ];

module.directive('xpUserIcon', ['UserIconService', '$log', '$resource', '$sessionStorage',
function (UserIconService, $log, $resource, $sessionStorage) {
  var IconService = $resource('/users/:userId/photo/exists', {userId: '@userId'}, {
    'get': {method: 'GET'}
  });

  return {
    restrict: 'E',
    scope: {
      userId: '=',
      highlight: '=',
      checkMark: '='
    },
    replace: true,
    template: require('./userIcon.jade'),
    link: function (scope, element) {
      scope.loading = true;
      scope.randomImageReloadingString = '';

      function getDefaultBackgroundColor(userId) {
        return backgroundColors[userId % 15];
      }

      function getDefaultImage(userId, initials) {
        var backgroundColor = getDefaultBackgroundColor(userId);
        return defaultUserImageParts[0] + backgroundColor + defaultUserImageParts[1] +
              initials + defaultUserImageParts[2];
      }

      var cachedAvatar = $sessionStorage['gravatar_' + scope.userId];
      if (!cachedAvatar) {
        IconService.get({}, {'userId': scope.userId}, function (response) {
          $sessionStorage['gravatar_' + scope.userId] = JSON.stringify(response);
        });
      }

      scope.getImage = function() {
        if (scope.checkMark) {
          return 'resources/Correct-Answer.png';
        } else {
          var cachedAvatar = $sessionStorage['gravatar_' + scope.userId];
          if (cachedAvatar) {
            var sessionAvatar = JSON.parse(cachedAvatar);
            if (sessionAvatar.cached_avatar) {
              return 'users/' + scope.userId + '/photo/download?' + scope.randomImageReloadingString;
            }
            else {
              return 'data:image/svg+xml;base64,' + window.btoa(getDefaultImage(scope.userId, sessionAvatar.initials));
            }
          }
        }
      };

      function onLoad() {
        element.off("load");
        element.removeClass('loading');
      }

      element.on("load", onLoad);

      function pictureUpdateNotificationHandler(userId) {
        element.on("load", onLoad);
        $log.debug("Received picture update for: ",userId);

        if (userId === scope.userId) {
          $log.debug("Updating picture for ",scope.userId);
          delete $sessionStorage['gravatar_' + scope.userId];
          IconService.get({}, {'userId': scope.userId}, function (response) {
            $sessionStorage['gravatar_' + scope.userId] = JSON.stringify(response);
          });
          scope.randomImageReloadingString = 'random-image-reload-queue'+Date.now();
        }
      }

      UserIconService.addCallback(pictureUpdateNotificationHandler);

      element.on('$destroy', function () {
        UserIconService.removeCallbacks();
      });
    }
  };
}]);

})();

