'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpShareModeIndicator', ['SHARE_MODE', function (SHARE_MODE) {
    function getPiePath(portion) {
      var startPointX = 100;
      var startPointY = 50;
      var radius = 50;

      if (!portion || portion < 0) {
        portion = 0;
      }
      if (portion >= 1) {
        portion = 0.9999;
      }

      var angle = (Math.PI / 2) - ((2 * Math.PI) * (portion));
      var x = startPointX + radius * Math.cos(angle);
      var y = startPointY - (radius * Math.sin(angle));
      var largeArc = 0;
      var sweep = 1;

      if (portion > 0.5) {
        largeArc = 1;
      }

      return "M" + startPointX + "," + startPointY + " L" + startPointX + "," + (startPointY - radius) + " A" + radius + "," + radius + " 0 " + largeArc + "," + sweep + " " + x + "," + y + " z";
    }

    function getSVGTranslateX(mode, isTeacher) {
      switch (mode) {
        case SHARE_MODE.TEACHER:
        case SHARE_MODE.SMALL_GROUP_TEACHER:
        case SHARE_MODE.GROUP_TEACHER:
          return isTeacher ? 72 : 60;
        case SHARE_MODE.GROUP:
        case SHARE_MODE.STUDENT_SMALL_GROUP:
        case SHARE_MODE.SMALL_GROUP_GROUP:
        case SHARE_MODE.GROUP_GROUP:
          return isTeacher ? 72 : 64;
      }

      return 23;
    }

    function getSVGTranslateY(mode, isTeacher) {
      switch (mode) {
        case SHARE_MODE.TEACHER:
        case SHARE_MODE.SMALL_GROUP_TEACHER:
        case SHARE_MODE.GROUP_TEACHER:
          return isTeacher ? 20 : 20;
        case SHARE_MODE.GROUP:
        case SHARE_MODE.STUDENT_SMALL_GROUP:
        case SHARE_MODE.SMALL_GROUP_GROUP:
        case SHARE_MODE.GROUP_GROUP:
          return isTeacher ? 23 : 21;
      }

      return 68;
    }

    return {
      restrict: 'AE',
      scope: {
        xpMode: '=',
        xpPortion: '=',
        xpIsTeacher: '=',
        xpStudentResponded: '=',
        xpUnseenValues: '&'
      },
      template: '<span class="xp-share-mode-indicator"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="60" height="40" viewBox="0 0 175 100">' +
                '<g ng-if="isStudent"><rect fill="{{respondedFill}}" width="100" height="100"/>' +
                '<circle fill={{respondedFill}} cx="100" cy="50" r="50"/>' +
                '<g fill="white" ng-attr-transform="translate({{translateX}},{{translateY}}) scale(1,1)">' +
                '<path ng-attr-d="{{graphic}}"/></g></g><g ng-if="isTeacher"><rect fill="#eee" width="100" height="100"/>' +
                '<circle fill="#eee" cx="100" cy="50" r="50"/><path fill="#ccc" ng-attr-d="{{piePath}}"/><circle cx="100" cy="50" r="33"/>' +
                '<g fill="white" ng-attr-transform="translate({{translateX}},{{translateY}}) scale(1,1)">' +
                '<path ng-attr-d="{{graphic}}"/></g></g></svg></span>' +
                '<span ng-if="showUnseen()" class="xp-share-mode-indicator-unseen"><i class="fa fa-circle"></i></span>',
      link: function (scope, element, attrs) {
        var mode;
        scope.$watch('xpMode', function (value) {
          mode = value;
          updateValues();
        });
        var portion = 0;
        scope.piePath = getPiePath(portion);
        scope.$watch('xpPortion', function (value) {
          if (value) {
            portion = value;
            scope.piePath = getPiePath(portion);
          } else {
            portion = 0;
            scope.piePath = '';
          }
        });
        var isTeacher = false;
        scope.isTeacher = false;
        scope.$watch('xpIsTeacher', function (value) {
          isTeacher = value ? true : false;
          scope.isTeacher = isTeacher;
          scope.isStudent = !scope.isTeacher;
          updateValues();
        });
        scope.respondedFill = "#cccccc";
        scope.$watch('xpStudentResponded', function (value) {
          if (value) {
            scope.respondedFill = "#0090FA";
          } else {
            scope.respondedFill = "#cccccc";
          }
        });

        scope.showUnseen = function() {
          if (scope.xpUnseenValues) {
            return scope.xpUnseenValues();
          }
        };

        function updateValues() {
          scope.graphic = SHARE_MODE.getSVGPath(mode, isTeacher);
          scope.translateX = getSVGTranslateX(mode, isTeacher);
          scope.translateY = getSVGTranslateY(mode, isTeacher);
        }

      }
    };
  }]);

  module.directive('xpSubmitButtonWithGroup', ['SHARE_MODE', function (SHARE_MODE) {
    return {
      restrict: 'AE',
      scope: {
        mode: '=',
        respondent: '=',
        isGroup: '=',
        xpDisabled: '=',
        xpClick: '&'
      },
      template: "<div ng-show='!xpDisabled' class='submit-button-with-group'><div ng-if='isGroup' class='xp-user-group-indicator-container'>" +
        "<xp-group class='xp-user-group-indicator' size='25' group-id='respondent'></xp-group>" +
        "</div>" +
        "<div class='submit-button-container dark large-font primary-background-color xp-cf' ng-click='onClickHandler($event)'>" +
        "<img class='xp-submit-image' src='resources/share.svg', alt='' />" +
        "</div></div>",
      compile: function (element, attrs) {
        if (!attrs.xpDisabled) {
          attrs.xpDisabled = false;
        }
        if (!attrs.mode) {
          attrs.mode = SHARE_MODE.TEACHER;
        }
        if (!attrs.isGroup) {
          attrs.mode = false;
        }
        return link;
      }
    };

    function link(scope, element, attrs) {
      var mode;
      scope.$watch('mode', function (value) {
        scope.graphic = 'fa-paper-plane'; // SHARE_MODE.getGraphic(value);
        mode = value;
      });
      scope.onClickHandler = function (event) {
        if (scope.xpClick !== undefined && !scope.xpDisabled) {
          scope.xpClick();
          event.stopPropagation();
        }
      };
    }
  }]);

  module.directive('xpReplyButton', ['SHARE_MODE', function (SHARE_MODE) {
    return {
      restrict: 'AE',
      scope: {
        xpClick: '&'
      },
      template: "<i class='fa fa-reply fa-sm xp-element-reply-button-icon'/>",
      link: link
    };

    function link(scope, element, attrs) {
      scope.onClickHandler = function (event) {
        if (scope.xpClick) {
          scope.xpClick();
        }
      };
    }
  }]);


  module.directive('xpElementEditMenu', ['SHARE_MODE', function (SHARE_MODE) {
    return {
      restrict: 'E',
      replace: true,
  //    template: "<button class='btn xp-element-btn-edit-menu xp-cf' data-placement='bottom-right' data-html='true' data-container='#activity-content-container'><i class='fa fa-sort-desc fa-lg xp-element-btn-edit-menu-icon'/></button>",

      template: function (element, attrs) {
        var dropDownLocation = attrs.xpDataPlacement ? attrs.xpDataPlacement : 'bottom-right';
        var htmlTemplate = "<button class='btn xp-element-btn-edit-menu xp-cf' data-placement='" +
          dropDownLocation + "' data-html='true'><i class='fa fa-sort-down fa-md xp-element-btn-edit-menu-icon'/></button>";
        return htmlTemplate;
      },

      link: function (scope, element, attrs) {
      }
    };
  }]);

})();