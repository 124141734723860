'use strict';

angular.module('client.services').factory('notificationService', ['$resource',
  function ($resource) {

  var notificationsResource = $resource('/notifications/users/:userid/type/:typeid', {userid: '@userid', typeid: '@typeid'},
      {
        'get': {method: 'GET', isArray: true},
        'remove': {method: 'DELETE'}
      });

  function getEndedExperiences(userid) {
    return notificationsResource.get({userid: userid, typeid: 'experience_ended'}).$promise;
  }

  function removeEndedExperiences(userid) {
    return notificationsResource.remove({userid: userid, typeid: 'experience_ended'}).$promise;
  }

  return {
    getEndedExperiences: getEndedExperiences,
    removeEndedExperiences: removeEndedExperiences
  };
}]);