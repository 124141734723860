'use strict';

angular.module('client.services').factory('ReadingGroupService', function ($resource) {
  return $resource('/reading_groups/group/:group_id', {group_id: '@group_id'}, {
    get: {method: 'GET', params: {group_id: '@group_id'}},
  });
});

angular.module('client.services').factory('ReadingGroupDefaultsService', function ($resource) {
  return $resource('/reading_groups/class/:class_id/reading_group/:group_id', {
    class_id: '@class_id',
    group_id: '@group_id'
  }, {
    get: {method: 'GET', isArray: true, params: {class_id: '@class_id', group_id: '@group_id'}},
  });
});
