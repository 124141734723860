'use strict';

angular.module('client.services').factory('licensePackService', ['$q', '$resource',
  function ($q, $resource) {

  var summaryResource = $resource('classes/:cid/summaries', {}, {
        getSummaries: {method: 'GET', isArray: true}
  });

  function getSummaries(cid) {
    if (cid) {
      return summaryResource.getSummaries({cid: cid}).$promise;
    } else {
      return $q.when([]);
    }
  }

  var saveSummaryResource = $resource('classes/:cid/summary_state', {}, {
        postSummaryState: {method: 'POST'}
  });

  function saveSummaryState(cid, summary, subscriptionId) {
    return saveSummaryResource.postSummaryState({cid: cid}, {subscriptionId: subscriptionId, summary: summary}).$promise;
  }

  var saveSummarySynchResource = $resource('subscriptions/:subscriptionId/summaries/synch', {}, {
        postSummarySynch: {method: 'POST'}
  });

  function setSynch(subscriptionId, synch) {
    return saveSummarySynchResource.postSummarySynch({subscriptionId: subscriptionId}, {synchronize: synch}).$promise;
  }

  return {
    getSummaries: getSummaries,
    saveSummaryState: saveSummaryState,
    setSynch: setSynch
  };
}]);