'use strict';

angular.module('client.services').factory('spireReportingService', ['$q', '$resource',
  function ($q, $resource) {

    // default is "last first" if no first or last then username.  if no username then email
    function getUserDisplayName(user) {
      let name = "";

      if (user.last_name) {
        name = user.last_name;
      }

      if (user.first_name) {
        name = user.first_name + " " + name;
      }

      if (!name && user.username) {
        name = user.username;
      }

      if (!name && user.email) {
        name = user.email;
      }

      return name;
    }

    function parseStudents(students) {
      let retval = [];
      if (students instanceof Array) {
        students.forEach(function (student) {
          student.name = getUserDisplayName(student);
          student.districtId = student.lms_source;
          student.status = student.learning_status_name;
          retval.push(student);
        });

        retval.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
      return retval;
    }

    function parseClasses(classes) {
      let retval = [];
      if (classes instanceof Array) {
        classes.forEach(function (cl) {
          cl.name = cl.class_name;
          cl.teacher = getUserDisplayName(cl);
          retval.push(cl);
        });

        retval.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
      return retval;
    }

    function parseClassNames(classes) {
      let retval = [];
      if (classes instanceof Array) {
        classes.forEach(function (cl) {
          if (cl.cid && !classExists(retval, cl)) {
            let clazz = {id: cl.cid, name: cl.class_name};
            retval.push(clazz);
          }
        });

        retval.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
      return retval;
    }

    // See if this school is already in the array
    function schoolExists(source, value) {
      for (let index = 0; index < source.length; ++index) {
        if (source[index].id === value.school_id) {
          return true;
        }
      }
      return false;
    }

    // See if this class is already in the array
    function classExists(source, value) {
      for (let index = 0; index < source.length; ++index) {
        if (source[index].id === value.cid) {
          return true;
        }
      }
      return false;
    }

    function parseSchools(classes) {
      let retval = [];
      if (classes instanceof Array) {
        classes.forEach(function (cl) {
          if (!schoolExists(retval, cl)) {
            let school = {};
            school.id = cl.school_id;
            school.name = cl.school_name;
            retval.push(school);
          }
        });

        retval.sort(function (a, b) {
          if (!a.name || !b.name) {
            return -1;
          } else {
            return a.name.localeCompare(b.name);
          }
        });
      }
      return retval;
    }

    // See if this teacher is already in the array
    function teacherExists(source, value) {
      for (let index = 0; index < source.length; ++index) {
        if (source[index].teacher_user_id === value.teacher_user_id) {
          return true;
        }
      }
      return false;
    }

    function parseTeachers(classes) {
      let retval = [];
      if (classes instanceof Array) {
        classes.forEach(function (cl) {
          if (!teacherExists(retval, cl)) {
            let teacher = {};
            teacher.teacher_user_id = cl.teacher_user_id;
            teacher.name = getUserDisplayName(cl);
            teacher.first_name = cl.first_name;
            teacher.last_name = cl.last_name;
            retval.push(teacher);
          }
        });

        retval.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
      return retval;
    }

    function parseToLevels(data, key) {
      let retval = {};
      if (data instanceof Array) {
        data.forEach(function (row) {
          let level = row.level;
          if (!retval[level]) {
            retval[level] = [];
          }
          retval[level][row[key] - 1] = row;
        });
      }
      return retval;
    }

    function getLevels(lessons) {
      let retval = Object.keys(lessons);
      retval.sort(function (a, b) {
        return a - b;
      });
      return retval;
    }

    function sortScoresByDate(scores) {
      if (scores) {
        scores.sort(function (a, b) {
          a = new Date(a.date);
          b = new Date(b.date);
          return a.getTime() - b.getTime();
        });
      }
    }

    function compareByAttempt(row1, row2) {
      if (row1.attempt && row2.attempt) {
        return row1.attempt - row2.attempt;
      } else {
        return 0;
      }
    }

    function compareByStudentName(row1, row2) {
      if (row1.studentName && row2.studentName) {
        if (row1.studentName.toLowerCase() < row2.studentName.toLowerCase()) {
          return -1;
        }
        if (row1.studentName.toLowerCase() > row2.studentName.toLowerCase()) {
          return 1;
        }
        return compareByAttempt(row1, row2);
      } else if (!row1.studentName && !row2.studentName) {
        return compareByAttempt(row1, row2);
      } else if (!row1.studentName) {
        return 1;
      } else if (!row2.studentName) {
        return -1;
      }
    }

    function compareByClassName(row1, row2) {
      if (row1.className && row2.className) {
        if (row1.className.toLowerCase() < row2.className.toLowerCase()) {
          return -1;
        }
        if (row1.className.toLowerCase() > row2.className.toLowerCase()) {
          return 1;
        }
        return compareByStudentName(row1, row2);
      } else {
        return compareByStudentName(row1, row2);
      }
    }

    function compareByLevel(row1, row2) {
      if (row1.levelName === row2.levelName) {
        return compareByClassName(row1, row2);
      } else {
        return row1.levelName - row2.levelName;
      }
    }

    function sortRowsByClassAndStudent(rows) {
      if (rows) {
        rows.sort(compareByLevel);
      }
    }

    function getOrCreateHive(obj, key) {
      if (obj[key] === null || angular.isUndefined(obj[key])) {
        obj[key] = {};
      }

      return obj[key];
    }

    function calculateOverallScores(data) {
      let retval = {};
      Object.keys(data).forEach(function (level) {
        let levelScores = data[level];
        if (!retval[level]) {
          retval[level] = {};
        }
        Object.keys(levelScores).forEach(function (id) {
          let lessonScores = levelScores[id];
          if (!retval[level][id]) {
            retval[level][id] = {};
          }
          Object.keys(lessonScores).forEach(function (classId) {
            let classScores = lessonScores[classId];
            let total = 0;
            let count = 0;
            Object.keys(classScores).forEach(function (studentId) {
              let studentScore = classScores[studentId];
              total += studentScore.displayScore;
              ++count;
            });
            retval[level][id][classId] = Math.round(total / count);
          });
        });
      });

      return retval;
    }

    return {
      parseStudents: parseStudents,
      parseSchools: parseSchools,
      parseClasses: parseClasses,
      parseClassNames: parseClassNames,
      parseTeachers: parseTeachers,
      parseToLevels: parseToLevels,
      getLevels: getLevels,
      sortScoresByDate: sortScoresByDate,
      getOrCreateHive: getOrCreateHive,
      calculateOverallScores: calculateOverallScores,
      sortRowsByClassAndStudent: sortRowsByClassAndStudent
    };
  }]);
