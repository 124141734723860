'use strict';

angular.module('client.services').factory('UserTrials', function ($resource, xpAlert, userPermissions) {
  var trialsService = $resource('/subscriptions/trials', {}, {
    get: {method: 'GET', isArray: true}
  });

  var validateService = $resource('/subscriptions/trials/validate', {}, {
    put: {method: 'PUT'}
  });

  var trials = [];

  function getUserTrials() {
    return trialsService.get({}).$promise
    .then(function(currentTrials){
      trials = currentTrials;
      return trials;
    });
  }

  function hasTrials() {
    return trials.length > 0;
  }

  function checkTrials()
  {
    var today = new Date();
    var oneDay = 1000 * 60 * 60 * 24;
    var startsAt, endsAt, remainingTrialDays, trialDays;
    var msg = "";

    if (!xpAlert.hasAlerts(xpAlert.alertKind.GENERAL)) {
      xpAlert.clearAlerts(xpAlert.alertKind.TRIAL);
      trials.forEach(function(trial){
        startsAt = new Date(trial.starts_at);
        endsAt = new Date(trial.ends_at);
        remainingTrialDays = (endsAt.getTime() - today.getTime()) / oneDay;
        remainingTrialDays = remainingTrialDays.toFixed(0);

        if (remainingTrialDays <= 0) {
          trialDays = (endsAt.getTime() - startsAt.getTime()) / oneDay;
          trialDays = trialDays.toFixed(0);
          xpAlert.error("Your " + trialDays + "-day trial has expired. You can <a href=\"https://www.exploros.com/subscription\" target=_blank> learn more about subscriptions here</a>.", true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (remainingTrialDays <= 7 && trial.experienceCount < trial.trial_experiences) {
          trialDays = (endsAt.getTime() - startsAt.getTime()) / oneDay;
          trialDays = trialDays.toFixed(0);
          msg = "You have " + remainingTrialDays + " day";
          if (remainingTrialDays > 1) {
            msg = msg + "s";
          }
          msg = msg + " and " + (trial.trial_experiences - trial.experienceCount) + " learning experience";
          if (trial.trial_experiences - trial.experienceCount > 1) {
            msg = msg + "s";
          }
          msg = msg + " left on your " + trialDays + "-day " + trial.name + " trial.";
          xpAlert.info(msg, true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (trial.experienceCount == 0) {
          msg = "You have " + trial.trial_experiences + " free " + trial.name + " learning experiences in your trial. " +
                      "Need help getting started? <a href=\"http://help.exploros.com/en/articles/1509081-getting-started-videos\" target=_blank> Watch these videos</a>.";
          xpAlert.info(msg, true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (trial.experienceCount === 1) {
          xpAlert.info("You have " + (trial.trial_experiences - trial.experienceCount) + " more free " + trial.name + " learning experiences in your trial.", true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (trial.trial_experiences - trial.experienceCount === 2) {
          msg = "You have 2 " + trial.name + " learning experiences left.  You can <a href=\"https://www.exploros.com/subscription\" target=_blank> learn more about subscriptions here</a>.";
          xpAlert.info(msg, true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (trial.trial_experiences - trial.experienceCount === 1) {
          xpAlert.error("You have 1 " + trial.name + " learning experience left.", true, xpAlert.alertKind.TRIAL);
          return;
        }

        if (trial.trial_experiences - trial.experienceCount <= 0) {
          xpAlert.error("Your trial is now over. You can <a href=\"https://www.exploros.com/subscription\" target=_blank> learn more about subscriptions here</a>.", true, xpAlert.alertKind.TRIAL);
        }
      });
    }
  }

  function updateTrials() {
    return validateService.put({}).$promise
    .then(function(status){
      if (status) {
        trials.forEach(function(trial){
          trial.experienceCount = trial.experienceCount + 1;
        });
        if (status.disable) {
          userPermissions.resetPermissions();
        }
        checkTrials();
        return status;
      }
    });
  }

  return {
    getUserTrials: getUserTrials,
    hasTrials: hasTrials,
    checkTrials: checkTrials,
    updateTrials: updateTrials
  };
});
